import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ContentLayout, Spinner } from '@amzn/awsui-components-react';
import { Prompt, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { useEditEventTemplate } from '@/src/store/edit-event-template.context';

import Challenges from '@/src/components/event-templates/EventTemplateDetails/Sections/Challenges';
import General from '@/src/components/event-templates/EventTemplateDetails/Sections/General';
import Feedback from '@/src/components/event-templates/EventTemplateDetails/Sections/Feedback';
import Reporting from '@/src/components/event-templates/EventTemplateDetails/Sections/Reporting';
import EventTemplateCreate from '@/src/components/event-templates/EventTemplateCreate/EventTemplateCreate';

import './EventTemplateDetails.scss';

import { EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES } from '@/src/routes';
import { LoggingService } from '@/src/utils/logging-service.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';
import { useToolPanel } from '@/src/store/tool-panel.context';
import EventTemplateDetailHeader from '@/src/components/ui/molecules/EventTemplate/EventTemplateDetailHeader';
import EventDetailGeneralEdit from '../../ui/organisms/EventTemplate/EventDetailGeneralEdit';
import EventDetailChallengeEdit from '../../ui/organisms/EventTemplate/EventDetailChallengeEdit';
import { preProdLogger } from '@/src/utils/log.utils';
import { QuitConfirmModal, SuccessModal } from '../../ui/molecules/EventTemplate';
import { RoutePath } from '@/src/RoutePath';
import JamSpinner from '@/src/components/common/JamSpinner';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';
import { getBackupChallengesListItems, getPrimaryChallengeListItems } from '@/src/utils/event-template.utils';
import { useChallenges } from '@/src/store/challenge.context';
import History  from 'history'

const { unsavedChangesMessage, confirmQuitMessage } = i18nKeys.eventTemplates.quitModal;

const EventDetail = () => {
  const {
    eventTemplate,
    updateEventTemplateByStatus,
    getEventDurationInfo,
    eventTemplateChallengePreferences,
    reviews,
    handleSeeMoreClick,
    filteringText,
    setFilteringText,
    eventTemplateRating,
    fetchEventTemplateById,
    selectedEventTemplateFeedbackOption,
    setSelectedEventTemplateFeedbackOption,
    toggleEventTemplateFeedbackReviewLikeStatus,
    eventTemplateReport,
    onSummaryChange,
    onTeamSizeChange,
    onMinParticipantsChange,
    onMaxParticipantsChange,
    onTopicsChange,
    onLearningTypeChange,
    onDurationChange,
    onTagsChange,
    setEventTemplateEditMode,
    eventTemplateEditMode,
    editedEventTemplate,
    updateEventTemplate,
    onImageChange,
    selectedChallenges,
    rationale,
    onRationaleChange,
    onSelectedChallengesChange,
    onPrimaryBackupChallengesChange,
    deleteEventTemplate,
    resetEditEventTemplateContext,
    hasUnsavedChanges,
  } = useEditEventTemplate();

  const { t } = useTranslation();
  const navigate = useHistory();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [savingEventTemplate, setSavingEventTemplate] = useState(false);
  const [showQuitConfirmModal, setShowQuitConfirmModal] = useState(false);
  const { challengeTitles, challengeSets, fetchChallengeSets } = useEventTemplateChallenges();
  const { getChallengesByIds } = useChallenges();
  const { selectedChallengesById } = useChallenges();
  const { offers, fetchEventTemplateOffers } = useEventTemplateOffers();

  const { toggleChallengeDetailsInfo } = useToolPanel();
  const { eventTemplateId }: { eventTemplateId: string } = useParams();
  const [redirectLocation, setRedirectLocation] = useState<History.Location>()
  const editMatch = useRouteMatch(EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Edit.wildcard());
  const isCurrentSectionValid = useRef<() => Promise<boolean>>();
  const ChallengesMatch = useRouteMatch(EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Challenges.wildcard());

  useEffect(() => {
    return () => {
      resetEditEventTemplateContext();
    };
  }, []);

  useEffect(() => {
    void fetchEventTemplateOffers();
  }, []);

  useEffect(() => {
    void fetchEventTemplateById(eventTemplateId);
  }, [eventTemplateId]);

  useEffect(() => {
    if (!challengeSets || challengeSets.length === 0) {
      void fetchChallengeSets();
    }
  }, [challengeSets, fetchChallengeSets]);

  useEffect(() => {
    if (eventTemplate?.challenges && eventTemplate?.challenges.length > 0) {
      const selectedChallengeIds = eventTemplate?.challenges.map((challenge) => challenge.id);
      void getChallengesByIds(false, true, selectedChallengeIds);
    }
  }, [eventTemplate?.challenges]);

  const isSectionValid = useCallback(async () => {
    if (!isCurrentSectionValid.current) return false;
    try {
      const res = await isCurrentSectionValid.current();
      return res;
    } catch (error) {
      preProdLogger('Could not complete validation.', error);
      throw error;
    }
  }, [isCurrentSectionValid.current]);

  const validationHandler = (validateSection: () => Promise<boolean>) => {
    isCurrentSectionValid.current = validateSection;
  };

  const navigateWithValidation = useCallback(() => {
    isSectionValid()
      .then(async () => {
        setSavingEventTemplate(true);
        await updateEventTemplate();
        setSavingEventTemplate(false);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        LoggingService.error('Validation failed', err);
      });
  }, [isSectionValid, updateEventTemplate]);

  const handleSuccessDismiss = () => {
    setShowSuccessModal(false);
    setTimeout(() => {
      setShowQuitConfirmModal(false);
    }, 0);
    setTimeout(navigateback, 0);
  };

  const handleQuitDismiss = () => {
    setShowQuitConfirmModal(false);
  };

  const handleQuitOkClick = () => {
    setEventTemplateEditMode(false);
    setShowQuitConfirmModal(false);
    setTimeout(navigateback, 0);
  };

  const navigateback = useCallback(() => {
    if (redirectLocation) {
      navigate.replace(redirectLocation.pathname)
      setRedirectLocation(undefined)
      return
    }
    navigate.replace(
      `${RoutePath.EVENT_CATALOG_TEMPLATES}/${eventTemplateId}${
        ChallengesMatch && ChallengesMatch.isExact ? '/challenges' : ''
      }`
    );
  }, [eventTemplateId, ChallengesMatch, redirectLocation]);


  const handleCancel = useCallback(() => {
    if (eventTemplateEditMode && editedEventTemplate && window.location.pathname.indexOf('/edit') !== -1) {
      setShowQuitConfirmModal(true);
    }
  }, [editedEventTemplate, eventTemplateEditMode]);

  const handleCancelWithLocation = useCallback((location: History.Location) => {
    setRedirectLocation(location)
    handleCancel()
  }, [handleCancel])

  const handleImageChange = useCallback(
    (file?: File) => {
      void onImageChange(file);
    },
    [onImageChange]
  );

  const primaryChallenges = useMemo(
    () => getPrimaryChallengeListItems(eventTemplate?.challenges ?? [], selectedChallengesById),
    [eventTemplate?.challenges, selectedChallengesById]
  );

  const backupChallenges = useMemo(
    () => getBackupChallengesListItems(eventTemplate?.challenges ?? [], selectedChallengesById),
    [eventTemplate?.challenges, selectedChallengesById]
  );

  if (!eventTemplate) {
    return <JamSpinner />;
  }

  const unsaved = hasUnsavedChanges();
  return (
    <div className="event-detail-container">
      {savingEventTemplate && (
        <span>
          <Spinner /> {t(i18nKeys.general.saving)}{' '}
        </span>
      )}
      <ContentLayout
        header={
          !editMatch?.isExact ? (
            <EventTemplateDetailHeader
              status={eventTemplate?.status}
              onUpdateEventTemplateStatus={updateEventTemplateByStatus}
              heading={eventTemplate.name}
              eventTemplateEditMode={eventTemplateEditMode}
              setEventTemplateEditMode={setEventTemplateEditMode}
              onSaveEventTemplate={navigateWithValidation}
              deleteEventTemplate={deleteEventTemplate}
              handleCancel={handleCancel}
            />
          ) : undefined
        }>
        <Switch>
          <Route path={EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.GeneralEdit.wildcard()}>
            <EventDetailGeneralEdit
              offers={offers}
              eventTemplate={editedEventTemplate}
              selectedChallenges={selectedChallenges}
              rationale={rationale}
              onSummaryChange={onSummaryChange}
              onTeamSizeChange={onTeamSizeChange}
              onMinParticipantsChange={onMinParticipantsChange}
              onMaxParticipantsChange={onMaxParticipantsChange}
              onTopicsChange={onTopicsChange}
              onLearningTypeChange={onLearningTypeChange}
              onDurationChange={onDurationChange}
              onTagsChange={onTagsChange}
              onImageChange={handleImageChange}
              onRationaleChange={onRationaleChange}
              validationHandler={validationHandler}
              handleCancel={handleCancel}
              eventTemplateEditMode={eventTemplateEditMode}
            />
          </Route>
          <Route path={EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.ChallengeEdit.wildcard()}>
            <EventDetailChallengeEdit
              eventTemplate={eventTemplate}
              selectedChallenges={selectedChallenges}
              getEventDurationInfo={getEventDurationInfo}
              validationHandler={validationHandler}
              onSelectedChallengesChange={onSelectedChallengesChange}
              onPrimaryBackupChallengesChange={onPrimaryBackupChallengesChange}
              handleCancel={handleCancel}
              eventTemplateEditMode={eventTemplateEditMode}
            />
          </Route>
          <Route path={EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Edit.wildcard()}>
            <EventTemplateCreate event={eventTemplate} />
          </Route>
          <Route path={EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Challenges.wildcard()}>
            <Challenges
              primaryChallenges={primaryChallenges}
              backupChallenges={backupChallenges}
              eventTemplateChallengePreferences={eventTemplateChallengePreferences}
              getEventDurationInfo={getEventDurationInfo}
              toggleChallengeDetailsInfo={toggleChallengeDetailsInfo}
            />
          </Route>
          <Route path={EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Feedback.wildcard()}>
            <Feedback
              reviews={reviews}
              handleSeeMoreClick={handleSeeMoreClick}
              filteringText={filteringText}
              setFilteringText={setFilteringText}
              eventTemplateRating={eventTemplateRating}
              selectedEventTemplateFeedbackOption={selectedEventTemplateFeedbackOption}
              setSelectedEventTemplateFeedbackOption={setSelectedEventTemplateFeedbackOption}
              toggleEventTemplateFeedbackReviewLikeStatus={toggleEventTemplateFeedbackReviewLikeStatus}
            />
          </Route>
          <Route path={EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Reporting.wildcard()}>
            <Reporting eventTemplateReport={eventTemplateReport} />
          </Route>
          <Route path={EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Details.wildcard()}>
            <General
              eventTemplate={eventTemplate}
              challengeTitles={challengeTitles}
              getEventDurationInfo={getEventDurationInfo}
            />
          </Route>
        </Switch>
      </ContentLayout>
      <SuccessModal
        name={eventTemplate.name}
        status={'updated'}
        visible={showSuccessModal}
        onDismiss={handleSuccessDismiss}
      />
      <QuitConfirmModal
        unsaved={unsaved}
        visible={showQuitConfirmModal}
        onOkClick={handleQuitOkClick}
        onDismiss={handleQuitDismiss}
        message={unsaved ? unsavedChangesMessage : confirmQuitMessage}
      />
      <Prompt
        when={eventTemplateEditMode}
        message={(location: History.Location) => {
          handleCancelWithLocation(location)
          return false
        }}
      />
    </div>
  );
};

export default EventDetail;
