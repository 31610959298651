import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import AwsCliModal from './Modals/AwsCLI/AwsCLIModal';
import AlertModal from './Modals/AlertModal';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { ChallengeLearningType } from '@/src/types/Challenge';
import { SolveChallengeButtonKey } from '@/src/types/JamChallengeDetails';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import JamSpinner from '@/src/components/common/JamSpinner';
import { JamEventStatus } from '@/src/types/JamCommon';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getTimelineFormattedDate } from '@/src/utils/time.utils';
import { disableRestartChallengeRegex } from '@/src/utils/myjam.utils';
import { AccountCredentials } from '@/src/types/LabModels';
import RestartChallengeModal from './Modals/RestartChallengeModal';

const JamChallengeSolve: React.FC = () => {
  const { t } = useTranslation();
  const { eventName, event } = useJamEventDetails();
  const {
    startJamChallenge,
    loadJamChallengeProgressData,
    challengeProgress,
    trackSolveWithButtonClick,
    loadTeamKeyPair,
    codeWhispererUrl,
    awsAccount,
    jamChallengeId,
    isStartingChallange,
    selectedJamChallenge,
  } = useJamChallengeDetails();
  const { clearFlashbars } = useFlashbars();
  const [enableModal, setModal] = useState(false);
  const [enableAlert, setAlertModal] = useState(false);
  const [enableChallengeRestartModal, setEnableChallengeRestartModal] = useState(false);
  const { amazonCodeWhisperer, awsCLI, openAWSConsole, restartChallenge, sshKeyPair, startChallenge } =
    i18nKeys.myJams.challenges.details.solveUsing.buttons;

  const isLocked = !!selectedJamChallenge?.locked;

  useEffect(() => {
    clearFlashbars();
    if (!!jamChallengeId && (event?.eventStatus === JamEventStatus.ONGOING || event?.ended)) {
      void loadJamChallengeProgressData({ id: eventName, challengeId: jamChallengeId });
    }
  }, [jamChallengeId]);

  const handleProgressChallenge = () => {
    if (challengeProgress && challengeProgress.started) {
      if (challengeProgress.remainingRestarts < 1) {
        setAlertModal(!enableAlert);
        return;
      }
      setEnableChallengeRestartModal(!enableChallengeRestartModal);
    } else {
      void startJamChallenge({ id: eventName, challengeId: jamChallengeId });
    }
  };

  const handleAmazonCodeWhisperer = () => {
    void trackSolveWithButtonClick(eventName, jamChallengeId, SolveChallengeButtonKey.CODE_WHISPERER);
  };

  const handleOpenAWSConsole = () => {
    void trackSolveWithButtonClick(eventName, jamChallengeId, SolveChallengeButtonKey.AWS_CONSOLE);
  };

  const handleSSHKeyPair = () => {
    void loadTeamKeyPair(eventName, jamChallengeId);
  };

  const handleAwsCLI = () => {
    setModal(!enableModal);
    void trackSolveWithButtonClick(eventName, jamChallengeId, SolveChallengeButtonKey.AWS_CLI);
  };

  const credentials = useMemo<AccountCredentials | undefined>(() => {
    if (!awsAccount) return;
    const creds = new AccountCredentials();
    creds.accessKey = awsAccount.accessKey;
    creds.secretKey = awsAccount.secretKey;
    creds.sessionToken = awsAccount.sessionToken;
    return creds;
  }, [awsAccount]);

  if (isStartingChallange) {
    return <JamSpinner />;
  }

  const renderActionButton = () => {
    if (disableRestartChallengeRegex.test(location.pathname) || isLocked) {
      return;
    }
    if (!challengeProgress?.started) {
      return (
        <div id="startChallengeButton" data-testid="startChallengeButton" onClick={handleProgressChallenge}>
          <Button>
            <Icon name="caret-right-filled" />
            <Box margin={{ left: 'xs' }} variant="span" color="text-status-info">
              {t(startChallenge.title)}
            </Box>
          </Button>
        </div>
      );
    }
    if (!isLocked || challengeProgress?.hasAwsResources || challengeProgress?.hasExternalResources) {
      return (
        <div id="restartChallengeButton" data-testid="restartChallengeButton" onClick={handleProgressChallenge}>
          <Button>
            <Icon name="refresh" />
            <Box margin={{ left: 'xs' }} variant="span" color="text-status-info">
              {t(restartChallenge.title)}
            </Box>
          </Button>
        </div>
      );
    }
  };

  return (
    <Container variant="default" className="dark-bg">
      <Grid
        gridDefinition={
          !isLocked && !challengeProgress?.completed ? [{ colspan: 8 }, { colspan: 4 }] : [{ colspan: 12 }]
        }>
        {/* {!isLocked && !challengeProgress?.completed && ( */}
        <SpaceBetween size="m" direction="horizontal">
          {awsAccount && (
            <Box variant="p">
              <Box variant="span" color="text-status-info">
                <Icon name="status-info" />
              </Box>
              <Box variant="span" fontWeight="bold" margin={{ left: 'xs' }}>
                {t(i18nKeys.myJams.challenges.details.solveUsing.title)}:
              </Box>
            </Box>
          )}
          {selectedJamChallenge?.learningType !== ChallengeLearningType.INDIVIDUAL && codeWhispererUrl && (
            <Button className="coloured" onClick={handleAmazonCodeWhisperer} href={codeWhispererUrl} target="_blank">
              <Box className="flex-center">
                <img src="/assets/Union.png" alt="..." width={15} />
                <Box margin={{ left: 'xs' }} variant="div" className="button-text">
                  {t(amazonCodeWhisperer)}
                </Box>
              </Box>
            </Button>
          )}
          {awsAccount && awsAccount?.url && (
            <Button variant="primary" onClick={handleOpenAWSConsole} href={awsAccount?.url} target="_blank">
              <Icon name="external" /> {t(openAWSConsole)}
            </Button>
          )}
          {selectedJamChallenge?.learningType !== ChallengeLearningType.INDIVIDUAL && awsAccount && (
            <Button onClick={handleAwsCLI}>
              <Box variant="span" color="text-status-info">
                {t(awsCLI)}
              </Box>
            </Button>
          )}
        </SpaceBetween>
        {/* )} */}
        <Box float="right">
          <SpaceBetween size="m" direction="horizontal">
            {selectedJamChallenge?.learningType !== ChallengeLearningType.INDIVIDUAL &&
              !isLocked &&
              !challengeProgress?.completed &&
              challengeProgress?.started &&
              selectedJamChallenge?.sshKeyPairRequired && (
                <React.Fragment>
                  <Box className="text-grid" />
                  <Button onClick={handleSSHKeyPair}>
                    <Icon name="download" />
                    <Box margin={{ left: 'xs' }} variant="span" color="text-status-info">
                      {t(sshKeyPair)}
                    </Box>
                  </Button>
                </React.Fragment>
              )}
            {event?.eventStatus === JamEventStatus.NOT_STARTED && (
              <Box className="light-violet">
                {t(i18nKeys.events.eventDetails.timeline.scheduledFor)}
                {`${getTimelineFormattedDate(new Date(event?.eventStartDate || ''))}`}
              </Box>
            )}
            {renderActionButton()}
            {event?.eventStatus === JamEventStatus.ENDED && isLocked && (
              <Box className="light-violet">{t(i18nKeys.myJams.challenges.header.timeExpired)}</Box>
            )}
          </SpaceBetween>
        </Box>
      </Grid>
      {enableModal && credentials && <AwsCliModal value={enableModal} setValue={setModal} awsAccount={credentials} />}
      {enableAlert && <AlertModal value={enableAlert} setValue={setAlertModal} />}
      {enableChallengeRestartModal && (
        <RestartChallengeModal value={enableChallengeRestartModal} setValue={setEnableChallengeRestartModal} />
      )}
    </Container>
  );
};

export default JamChallengeSolve;
