import {
  Container,
  FormField,
  Header,
  TextContent,
  Button,
  Box,
  SpaceBetween,
  Grid,
} from '@amzn/awsui-components-react';
import * as React from 'react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection, ChallengeUtils } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import './NextSteps.scss';
import { useState } from 'react';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { BORKMarkdownEditor, BORKMarkdownRenderer } from '@/src/components/ui/molecules/Challenge';

interface ChallengeNextStepsDetailProps {
  challenge: Challenge;
}

const iconImg = (): JSX.Element => (
  <svg
    style={{ display: 'flex' }}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 16 16"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.41421 8.00008L5.20711 5.20718L3.79289 3.79297L0.292893 7.29297C-0.0976311 7.68349 -0.0976311 8.31666 0.292893 8.70718L3.79289 12.2072L5.20711 10.793L2.41421 8.00008ZM5.54611 11.3179L8.60611 3.91787L10.4543 4.68213L7.39432 12.0821L5.54611 11.3179ZM15.7071 7.29297L12.2071 3.79297L10.7929 5.20718L13.5858 8.00008L10.7929 10.793L12.2071 12.2072L15.7071 8.70718C16.0976 8.31666 16.0976 7.68349 15.7071 7.29297Z"
      fill="#0972D3"
    />
  </svg>
);

const ChallengeNextStepsDetail: React.FC<ChallengeNextStepsDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [activeTab, setActiveTab] = useState(1);
  const [, setOverwriteTemplate] = useState(false);
  const updateTemplate = () => {
    const template: string =
      '- [Link to some documentation](https://docs.aws.amazon.com/)\n' +
      '- [Link to some blog post](https://aws.amazon.com/blogs/aws/)\n' +
      '- [Link to some github repo](https://github.com/aws)\n';
    handleUpdateChallengeProp(ChallengePropAction.NEXT_STEPS, template);
    setOverwriteTemplate(false);
  };
  const addContentTemplate = () => {
    if (challenge.props.nextSteps) {
      setOverwriteTemplate(true);
    } else {
      updateTemplate();
    }
  };
  const items: FlashbarProps.MessageDefinition[] = [
    {
      type: 'info',
      content: (
        <>
          Preferred format is markdown. Basic HTML is allowed. Please note that adding a template will overwrite your
          work.{' '}
          <Box float="right">
            <Button  id='challenge-new-step-add-content-template' data-testid='challenge-new-step-add-content-template' onClick={() => addContentTemplate()}>Add Content template</Button>
          </Box>
        </>
      ),
      id: 'info',
    },
  ];

  const handleUpdateNextStep = (details: string) => {
    handleUpdateChallengeProp(ChallengePropAction.NEXT_STEPS, details);
  };

  if (!editMode) {
    return (
      <Container
        header={
          <Header
            description={t(i18nKeys.challenges.subSections.nextSteps.description)}
            variant="h2"
            actions={<ChallengeReviewPopover section={ChallengeReviewableSection.NEXT_STEPS} />}>
            {t(i18nKeys.challenges.challengeDetails.headings.nextSteps)}
          </Header>
        }>
        <TextContent>
          {!editMode && challenge.props.nextSteps && (
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.props.nextSteps}</ReactMarkdown>
          )}
          {!editMode &&
            !challenge.props.nextSteps &&
            t(i18nKeys.challenges.challengeDetails.field.nextSteps.emptyState)}
        </TextContent>
      </Container>
    );
  }
  return (
    <Container
      header={
        <Header
          description={t(i18nKeys.challenges.subSections.nextSteps.description)}
          variant="h2"
          actions={<ChallengeReviewPopover section={ChallengeReviewableSection.NEXT_STEPS} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.nextSteps)}
        </Header>
      }>
      {ChallengeUtils.isIndividualLearningType(challenge) ? (
        <Box>
          {!editMode ? (
            <BORKMarkdownRenderer markdown={challenge.props.nextSteps || ''} />
          ) : (
            <FormField stretch>
              <BORKMarkdownEditor
                onChange={(value) => handleUpdateChallengeProp(ChallengePropAction.NEXT_STEPS, value)}
                value={editedChallenge?.props.nextSteps || ''}
              />
            </FormField>
          )}
        </Box>
      ) : (
        <div className="challenge-next-step">
          <SpaceBetween size="s">
            <Flashbar items={items} />
            <div
              style={{
                border: '1px solid var(--grey-200, #E9EBED)',
                background: '#FFF',
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
              }}>
              <div
                style={{
                  backgroundColor: '#E9EBED',
                  padding: '20px',
                  borderTopLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                }}>
                <SpaceBetween direction="horizontal" size="xs">
                  <button
                    id='challenge-new-step-show-markdown' data-testid='challenge-new-step-show-markdown'
                    className={`custom-btn ${activeTab === 1 ? 'active' : ''}`}
                    role="button"
                    onClick={() => setActiveTab(1)}>
                    <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                      {iconImg()} Markdown
                    </SpaceBetween>
                  </button>
                  <button
                    id='challenge-new-step-show-markdonw-and-preview' data-testid='challenge-new-step-show-markdonw-and-preview'
                    className={`custom-btn ${activeTab === 2 ? 'active' : ''}`}
                    role="button"
                    onClick={() => setActiveTab(2)}>
                    <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                      {iconImg()}
                      <img src="/assets/eye-blue-icon.png" alt="" />
                      Markdown / Preview
                    </SpaceBetween>
                  </button>
                  <button
                  id='challenge-new-step-show-preview' data-testid='challenge-new-step-show-preview'
                    className={`custom-btn ${activeTab === 3 ? 'active' : ''}`}
                    role="button"
                    onClick={() => setActiveTab(3)}>
                    <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                      <img src="/assets/eye-blue-icon.png" alt="" />
                      Preview
                    </SpaceBetween>
                  </button>
                </SpaceBetween>
              </div>
              <div style={{ padding: '2rem' }}>
                {activeTab === 1 && (
                  <Grid gridDefinition={[{ colspan: 12 }]}>
                    <JamCodeEditor
                      language="markdown"
                      value={challenge.props.nextSteps || ''}
                      setValue={(e) => {
                        handleUpdateNextStep(e);
                      }}
                    />
                  </Grid>
                )}
                {activeTab === 2 && (
                  <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <JamCodeEditor
                      language="markdown"
                      value={challenge.props.nextSteps || ''}
                      setValue={(e) => {
                        handleUpdateNextStep(e);
                      }}
                    />
                    <TextContent>
                      {challenge.props.nextSteps && (
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.props.nextSteps}</ReactMarkdown>
                      )}
                      {!challenge.props.nextSteps && t(i18nKeys.challenges.challengeDetails.field.nextSteps.emptyState)}
                    </TextContent>
                  </Grid>
                )}
                {activeTab === 3 && (
                  <Grid gridDefinition={[{ colspan: 12 }]}>
                    <TextContent>
                      {challenge.props.nextSteps && (
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.props.nextSteps}</ReactMarkdown>
                      )}
                      {!challenge.props.nextSteps && t(i18nKeys.challenges.challengeDetails.field.nextSteps.emptyState)}
                    </TextContent>
                  </Grid>
                )}
              </div>
            </div>
          </SpaceBetween>
        </div>
      )}
    </Container>
  );
};

export default ChallengeNextStepsDetail;
