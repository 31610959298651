import {
  Container,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import {
  Event,
  MAX_EXPECTED_PARTICIPANTS,
  DEFAULT_MIN_EXPECTED_PARTICIPANTS,
  MIN_EXPECTED_PARTICIPANTS,
  DEFAULT_MAX_EXPECTED_PARTICIPANTS,
} from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { KeyValue } from '../../../common/KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { useUser } from '../../../../store/user.context';
import { EventFields, eventTeamSizeValidator, eventParticipantRangeValidator } from '@/src/utils/event.validation.utils';
import { Validator } from '@/src/utils/validation.utils';
import { EventType } from '@/src/constants/shared/event-type';

interface AttendanceProps {
  target: Event | undefined;
  validationHandler?: (validateSection: () => Promise<boolean>) => void;
}

const Attendance: React.FC<AttendanceProps> = ({ target, validationHandler }) => {
  const { t } = useTranslation();
  const {
    editMode,
    newEventMode,
    bulkHandleUpdateEditEvent,
    editedEvent,
    isGuestUserAndEditModeEnabled,
    handleUpdateEditEvent,
  } = useEditEvent();
  const { user } = useUser();
  const [minExpectedParticipants, setMinExpectedParticipants] = useState('');
  const [maxExpectedParticipants, setMaxExpectedParticipants] = useState('');
  const [maxTeamSize, setMaxTeamSize] = useState('');
  const [maxTeamSizeError, setMaxTeamSizeError] = useState('');
  const [participantRangeError, setParticipantRangeError] = useState('');

  const canEdit = newEventMode || editMode;

  const eventTeamSizeValidation: Validator<EventFields> = useMemo(
    () =>
      eventTeamSizeValidator(
        maxTeamSize,
        t(i18nKeys.newEvent.errors.teamSize),
        new Map<EventFields, (error: string) => void>([
          [EventFields.TEAMSIZE, (error: string) => setMaxTeamSizeError(error)],
        ])
      ),
    [maxTeamSize, eventTeamSizeValidator]
  );

  const eventParticipantRangeValidation: Validator<EventFields> = useMemo(
    () =>
      eventParticipantRangeValidator(
        minExpectedParticipants,
        maxExpectedParticipants,
        {
          minRequired: t(i18nKeys.newEvent.errors.minParticipant),
          maxRequired: t(i18nKeys.newEvent.errors.maxParticipant),
          minGreaterX: t(i18nKeys.newEvent.errors.minParticipantAtLeastX, {bound : MIN_EXPECTED_PARTICIPANTS}),
          maxLowerX: t(i18nKeys.newEvent.errors.maxParticipantAtMostX, {bound : MAX_EXPECTED_PARTICIPANTS}),
          minNoGreaterMax: t(i18nKeys.newEvent.errors.minParticipantNoGreaterThanMaxParticipant)
        },
        new Map<EventFields, (error: string) => void>([
          [EventFields.PARTICIPANT, (error: string) => setParticipantRangeError(error)],
        ])
      ),
    [minExpectedParticipants, maxExpectedParticipants, eventParticipantRangeValidator]
  );

  if (validationHandler) {
    validationHandler(() => {
      const eventTeamSizeValidationResult = eventTeamSizeValidation.isValidSection(true);
      if (eventTeamSizeValidationResult) {
        return Promise.resolve(eventTeamSizeValidationResult);
      } else {
        return Promise.reject(eventTeamSizeValidationResult);
      }
    });
  }

  if (validationHandler) {
    validationHandler(() => {
      const eventParticipantRangeValidationResult = eventParticipantRangeValidation.isValidSection(true);
      if (eventParticipantRangeValidationResult) {
        return Promise.resolve(eventParticipantRangeValidationResult);
      } else {
        return Promise.reject(eventParticipantRangeValidationResult);
      }
    });
  }

  useEffect(() => {
    if (target && (editMode || newEventMode)) {
      // Polaris Input expects a string, even for numeric types
      const minExpectedDefault =
        target?.minExpectedParticipants !== null
          ? target.minExpectedParticipants.toString()
          : DEFAULT_MIN_EXPECTED_PARTICIPANTS.toString();
      const maxExpectedDefault =
        target?.maxExpectedParticipants !== null ? target.maxExpectedParticipants.toString() : '4';
      setMinExpectedParticipants(minExpectedDefault);
      setMaxExpectedParticipants(maxExpectedDefault);
      setMaxTeamSize(target?.maxTeamSize.toString() || '');
      if (newEventMode) {
        setNewEventDefaults();
      }
    }
  }, [editMode, editedEvent, newEventMode]);

  useEffect(() => {
    if (newEventMode) {
      setNewEventDefaults();
    }
  }, []);

  const setNewEventDefaults = () => {
    let minExpectedDefault;
    if (target && target.minExpectedParticipants !== null) {
      minExpectedDefault = target.minExpectedParticipants.toString();
    } else {
      minExpectedDefault = DEFAULT_MIN_EXPECTED_PARTICIPANTS.toString();
    }
    const maxExpectedDefault = target?.maxExpectedParticipants
      ? target?.maxExpectedParticipants.toString()
      : DEFAULT_MAX_EXPECTED_PARTICIPANTS.toString();
    bulkHandleUpdateEditEvent([
      { action: EditEventActions.MIN_EXPECTED_PARTICIPANTS, payload: minExpectedDefault },
      { action: EditEventActions.MAX_EXPECTED_PARTICIPANTS, payload: maxExpectedDefault },
    ]);

    setMinExpectedParticipants(minExpectedDefault);
    setMaxExpectedParticipants(maxExpectedDefault);
  };

  const handleSetMinExpectedParticipants = (selectedMinExpectedParticipants: string) => {
    setMinExpectedParticipants(selectedMinExpectedParticipants);
    handleUpdateEditEvent(EditEventActions.MIN_EXPECTED_PARTICIPANTS, Number(selectedMinExpectedParticipants));
  };

  const handleSetMaxExpectedParticipants = (selectedMaxExpectedParticipants: string) => {
    setMaxExpectedParticipants(selectedMaxExpectedParticipants);
    handleUpdateEditEvent(EditEventActions.MAX_EXPECTED_PARTICIPANTS, Number(selectedMaxExpectedParticipants));
  };

  const handleMaxTeamSize = (selectedMaxTeamSize: string) => {
    setMaxTeamSize(selectedMaxTeamSize);
    handleUpdateEditEvent(EditEventActions.MAX_TEAM_SIZE, Number(selectedMaxTeamSize));
  };

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.fields.participants.label)}</div>
            <div>
              {t(i18nKeys.events.fields.participants.value, {
                min: target?.minExpectedParticipants,
                max: target?.maxExpectedParticipants,
              })}
            </div>
          </Grid>
        </div>
        <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.fields.teamSize.label)}</div>
            <div>{t(i18nKeys.events.fields.teamSize.value, { teamSize: target?.maxTeamSize })}</div>
          </Grid>
        </div>
      </React.Fragment>
    ) : (
      canEdit && user && (
        <React.Fragment>
          <SpaceBetween direction="vertical" size="s">
            <FormField
              label={<b>{t(i18nKeys.events.eventDetails.labels.participants.header)}</b>}
              description={t(i18nKeys.events.eventDetails.labels.participants.description)}
              info={<span style={{ color: awsui.colorTextStatusError }}> *</span>}
              errorText={participantRangeError}
            >
              <div>
                {t(i18nKeys.events.eventDetails.labels.participants.start)}{' '}
                <Input
                  inputMode="numeric"
                  type="number"
                  controlId="minExpectedParticipants"
                  value={minExpectedParticipants}
                  className="numeric-long-input"
                  ariaLabel={t(i18nKeys.events.eventDetails.labels.numericInputMinimum)}
                  onChange={evt => {
                    handleSetMinExpectedParticipants(evt.detail.value);
                  }}
                  onBlur={() => eventParticipantRangeValidation.isValidField(EventFields.PARTICIPANT)}
                  disabled={!target?.canEditAttribute('minExpectedParticipants', user) || isGuestUserAndEditModeEnabled}
                />{' '}
                {t(i18nKeys.events.eventDetails.labels.participants.middle)}{' '}
                <Input
                  inputMode="numeric"
                  type="number"
                  controlId="maxExpectedParticipants"
                  value={maxExpectedParticipants}
                  ariaLabel={t(i18nKeys.events.eventDetails.labels.numericInputMaximum)}
                  onChange={({ detail }) => handleSetMaxExpectedParticipants(detail.value)}
                  onBlur={() => eventParticipantRangeValidation.isValidField(EventFields.PARTICIPANT)}
                  className="numeric-long-input"
                  disabled={!target?.canEditAttribute('maxExpectedParticipants', user) || isGuestUserAndEditModeEnabled}
                />{' '}
                {t(i18nKeys.events.eventDetails.labels.participants.end)}
              </div>
            </FormField>
            <KeyValue
              label={<b>{t(i18nKeys.events.eventDetails.labels.participants.maxTeamSizeHeader)}</b>}
              required
              info={t(i18nKeys.events.eventDetails.labels.participants.maxTeamSizeInfo)}>
              <FormField errorText={maxTeamSizeError}>
                <Input
                  inputMode="numeric"
                  value={maxTeamSize}
                  onChange={({ detail }) => handleMaxTeamSize(detail.value)}
                  type="number"
                  className="numeric-input"
                  ariaLabel={t(i18nKeys.events.eventDetails.labels.numericInputTeamSize)}
                  step={1}
                  onBlur={() => eventTeamSizeValidation.isValidField(EventFields.TEAMSIZE)}
                  disabled={
                    !target?.canEditAttribute('maxTeamSize', user) ||
                    isGuestUserAndEditModeEnabled ||
                    target?.type === EventType.SPL
                  }
                />{' '}
                {t(i18nKeys.events.eventDetails.labels.participants.maxTeamSize)}
              </FormField>
            </KeyValue>
          </SpaceBetween>
        </React.Fragment>
      )
    );
  };

  return !newEventMode ? (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.attendance)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.attendance)}</Header>}>
      {renderContent()}
    </Container>
  );
};

export default Attendance;
