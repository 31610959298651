import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Pagination,
  CollectionPreferences,
  SpaceBetween,
  FormField,
  RadioGroup,
  CollectionPreferencesProps,
  Checkbox,
  Button,
  Link,
  Box,
  PropertyFilter,
  PropertyFilterProps,
} from '@amzn/awsui-components-react';
import { PropertyFilterProperty } from '@cloudscape-design/collection-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../common/TableEmptyState';
import { paginationLabels } from '../../utils/table.utils';
import { TableHeader } from '../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './event-list-config';
import { i18nKeys } from '../../utils/i18n.utils';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import '../../styles/base.scss';
import { useEvents } from '../../store/events.context';
import { EventFilterType, DateRangeFilter } from '../../types/common';
import './EventList.scss';
import moment from 'moment';
import { MultiSelectInput } from '../common/MultiSelectInput/MultiSelectInput';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Event, EventStatus } from '../../types/Event';
import { UserPreferenceKeys, useUser } from '@/src/store/user.context';
import { useMediaQuery } from 'react-responsive';
import { DateRangeFilterInput } from '../common/DateRangeFilterInput/DateRangeFilterInput';
import { YYYY_MM_DD } from '../../utils/event-time.utils';
import { TemplateUrlModal } from './TemplateUrlModal';
import { RoutePath } from '@/src/RoutePath';
import { config } from '@/src/config/app-config';
import { customEventTrigger } from '../analytics/createEventTrigger';
import _ from 'lodash';
import { DateTimeKeys, TimezoneFormat } from '../common/CommonModel';
import { EventAudienceType } from '@/src/constants/shared';
import JamSpinner from '../common/JamSpinner';

interface MultiSelectEvent {
  detail: {
    selectedOptions: OptionDefinition[];
  };
}

interface EventFilter {
  label: string;
  value: string;
}
const defaultPageSize = 30;

const EventList: React.FC = () => {
  const { events, pageCount, isLoadEventsByDateRangeInProgress, loadEventsByDateRange } = useEvents();
  const [eventList, setEventList] = useState<Event[]>([]);
  const history = useHistory();
  const { user } = useUser();
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeFilter>({
    start: moment().subtract(30, 'days').format(YYYY_MM_DD),
    end: null,
  });
  const [, setFilterAsString] = useState<string | undefined>();
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  const [apiParams, setApiParams] = useState<DateRangeFilter>({
    start: moment().subtract(30, 'days').format(YYYY_MM_DD),
    end: null,
    filter: null,
    limit: 30,
    page: 1,
    includeEndedEvents: false,
    title: '',
  });
  const [query, setQuery] = React.useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'and',
  });

  const filterData = (details: PropertyFilterProps.Query) => {
    setQuery(details);
    const { tokens } = details;
    const params = { ...apiParams, title: '', status: '', channel: '' };
    if (tokens && tokens.length > 0) {
      tokens.forEach((element) => {
        if (element.propertyKey === 'title') {
          params.title = element.value as string;
        }
        if (element.propertyKey === 'status') {
          params.status = element.value as string;
        }
        if (element.propertyKey === 'channel') {
          params.channel = element.value as string;
        }
      });
    }
    setApiParams({ ...params });
  };
  const filteringProperties: PropertyFilterProperty[] = [
    {
      key: 'title',
      operators: ['=', ':'],
      propertyLabel: 'Title',
      groupValuesLabel: 'title',
    },
    {
      key: 'status',
      operators: ['=', ':'],
      propertyLabel: 'Status',
      groupValuesLabel: 'status',
    },
    {
      key: 'channel',
      operators: ['=', ':'],
      propertyLabel: 'Channel',
      groupValuesLabel: 'channel',
    },
  ];

  const [selectedJamFilters, setSelectedJamFilters] = useState<OptionDefinition[]>([]);

  const [showEndedEvents, setShowEndedEvents] = useState(false);
  const [currPageIndex, setCurrPageIndex] = useState(1);
  const [numOfPages, setNumOfPages] = useState(1);

  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const dateRangeStart = queryParameters.get('dateRangeStart');
    const dateRangeEnd = queryParameters.get('dateRangeEnd');
    if (!isLoadEventsByDateRangeInProgress) {
      if (dateRangeStart && dateRangeEnd && apiParams.end === null) {
        // if we have date params in the route
        apiParams.start = dateRangeStart;
        apiParams.end = dateRangeEnd;
      }
      loadEventsByDateRange(apiParams);
    }
  }, [apiParams]);

  const liveEventsFilter = useMemo(() => {
    const liveEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.liveEvents),
      value: EventFilterType.LIVE,
    };
    return liveEventsFilterObject;
  }, [t]);

  const endedEventsFilter = useMemo(() => {
    const endedEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.endedEvents),
      value: EventFilterType.ENDED,
    };
    return endedEventsFilterObject;
  }, [t]);

  const futureEventsFilter = useMemo(() => {
    const futureEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.futureEvents),
      value: EventFilterType.FUTURE,
    };
    return futureEventsFilterObject;
  }, [t]);

  const inReviewFilter = useMemo(() => {
    const inReviewFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.inReviewEvents),
      value: EventFilterType.IN_REVIEW,
    };
    return inReviewFilterObject;
  }, [t]);

  const jamEventsFilter = useMemo(() => {
    const jamEventsFilterObject = {
      label: t(i18nKeys.events.fields.filters.eventTypes.jamEvents),
      value: EventFilterType.JAM,
    };
    return jamEventsFilterObject;
  }, [t]);

  const campaignEventsFilter = useMemo(() => {
    const campaignEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.campaignEvents),
      value: EventFilterType.CAMPAIGN,
    };
    return campaignEventsFilterObject;
  }, [t]);

  // eslint-disable-next-line @typescript-eslint/require-await
  useComponentDidMountEffect(async () => {
    // TODO: Load filtering from memory JAM-2234
    handleQueryParameters();
    handleOnLoadPreferences();
  });

  /**
   * Sets the dateRangeFilter with desired selection and then calls api for events
   *
   * @param startOrEnd Desired date range filter to set
   * @param date date selected
   */

  // when click reload data from server
  const showEndedEventFromApi = (showEndedEvent: boolean) => {
    setApiParams({ ...apiParams, includeEndedEvents: showEndedEvent });
  };

  const handleDateSelection = (startOrEnd: DateTimeKeys, date: any): void => {
    const dateRangeFilterObject: DateRangeFilter = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      start: startOrEnd === DateTimeKeys.START ? formatDate(startOrEnd, date) : dateRangeFilter.start,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      end: startOrEnd === DateTimeKeys.END ? formatDate(startOrEnd, date) : dateRangeFilter.end,
    };
    setDateRangeFilter(dateRangeFilterObject);
    handleFilterSelection(selectedJamFilters, dateRangeFilterObject);
  };

  const formatDate = (startOrEnd: DateTimeKeys, date: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return date && moment(date).format(YYYY_MM_DD) !== 'Invalid date'
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        moment(date).format(YYYY_MM_DD)
      : startOrEnd === DateTimeKeys.START
      ? moment().subtract(30, 'days').format(YYYY_MM_DD)
      : null;
  };

  const handleQueryParameters = () => {
    if (!window.location.search) {
      history.replace({ search: `dateRangeStart=${dateRangeFilter.start}` });
    }
    const params = window.location.search.split('&');
    const parameterFilters: EventFilter[] = [];
    params.forEach((param) => {
      // Handles the case of a dateRangeEnd being passed to params
      if (param.search('dateRange') > -1) {
        const dateSplit = param.split('dateRange')[1].split('=');
        const startOrEnd: DateTimeKeys = dateSplit[0].toLowerCase() as DateTimeKeys;
        handleDateSelection(startOrEnd, dateSplit[1]);
      } else {
        switch (param) {
          case liveEventsFilter.value:
            parameterFilters.push(liveEventsFilter);
            break;
          case endedEventsFilter.value:
            parameterFilters.push(endedEventsFilter);
            break;
          case futureEventsFilter.value:
            parameterFilters.push(futureEventsFilter);
            break;
          case inReviewFilter.value:
            parameterFilters.push(inReviewFilter);
            break;
          case jamEventsFilter.value:
            parameterFilters.push(jamEventsFilter);
            break;
          case campaignEventsFilter.value:
            parameterFilters.push(campaignEventsFilter);
            break;
          default:
            break;
        }
      }
    });
    handleFilterSelection(parameterFilters);
  };

  const getTypeFilterPlaceholder = (): string => {
    const placeholderText = t(i18nKeys.events.fields.filters.eventTypes.placeholder);
    if (isMobile) {
      return placeholderText;
    }

    let filters = '';
    if (selectedJamFilters) {
      const selectedLabels = selectedJamFilters.map((filter) => filter.label);
      filters = selectedLabels.join(', ');
    }
    return filters || placeholderText;
  };

  const handleFilterSelection = (selectedFilters: OptionDefinition[], newDate?: DateRangeFilter) => {
    setSelectedJamFilters(selectedFilters);
    selectedFilters.map((filter) => filter.value);
    let newParamPath = `dateRangeStart=${dateRangeFilter.start}`;
    if (dateRangeFilter.end) {
      newParamPath = newParamPath.concat(`&dateRangeEnd=${dateRangeFilter.end}`);
    }
    const searchFilter: string[] = [];
    selectedFilters.forEach((filter: OptionDefinition) => {
      if (filter.value && newParamPath.search(filter.value) < 0) {
        newParamPath = newParamPath.concat(`&${filter.value}`);
        searchFilter.push(filter.value);
      }
    });
    const filterToString = _.toString(searchFilter);
    setFilterAsString(filterToString);
    setApiParams({
      ...apiParams,
      filter: filterToString,
      page: currPageIndex,
      limit: preferences.pageSize || defaultPageSize,
      start: newDate?.start || dateRangeFilter.start,
      end: newDate?.end || dateRangeFilter.end,
    });
    history.push({ search: newParamPath });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleSetPreferences = (preferences: CollectionPreferencesProps.Preferences) => {
    if (preferences.pageSize) {
      sessionStorage.setItem(UserPreferenceKeys.PAGE_SIZE, preferences.pageSize.toString());
    }
    if (preferences.custom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      sessionStorage.setItem(UserPreferenceKeys.DISPLAY_TIME, preferences.custom);
    }
    setPreferences(preferences);
  };

  const handleOnLoadPreferences = () => {
    const pageSize = Number(sessionStorage.getItem(UserPreferenceKeys.PAGE_SIZE));
    const displayTime: string | null = sessionStorage.getItem(UserPreferenceKeys.DISPLAY_TIME);
    const userPreferences = {
      pageSize: pageSize ? pageSize : preferences.pageSize,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      custom: displayTime ? displayTime : preferences.custom,
    };
    setPreferences(userPreferences);
  };

  const navigateToCatlogPage = () => {
    customEventTrigger('click', 'Navigate To Catlog', window.location.href, 'Navigate To Catlog', {});
    history.replace('/events');
  };

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: defaultPageSize,
    custom: TimezoneFormat.LOCAL,
  });

  const onNewEventClick = () => {
    customEventTrigger('click', 'Create Event', window.location.href, 'Create Event', {});
  };

  const onTransactionHistoryClick = () => {
    customEventTrigger('click', 'Transaction History', window.location.href, 'Transaction History', {});
  };

  const { items, actions, collectionProps, propertyFilterProps } = useCollection(eventList || [], {
    filtering: {
      filteringFunction,
      empty: user?.hasBasicAccess ? (
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>{t(i18nKeys.events.list.empty.description)}</b>
            <Button onClick={navigateToCatlogPage}>{t(i18nKeys.events.list.empty.button.title)}</Button>
          </SpaceBetween>
        </Box>
      ) : (
        <TableEmptyState
          title={t(i18nKeys.events.list.empty.title)}
          subtitle={t(i18nKeys.events.list.empty.subtitle)}
        />
      ),
      noMatch: user?.hasBasicAccess ? (
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>{t(i18nKeys.events.list.empty.description)}</b>
            <Button onClick={navigateToCatlogPage}>{t(i18nKeys.events.list.empty.button.title)}</Button>
          </SpaceBetween>
        </Box>
      ) : (
        <TableEmptyState
          title={t(i18nKeys.tables.noMatch.title)}
          subtitle={t(i18nKeys.tables.noMatch.subtitle)}
          onClearFilter={() => actions.setFiltering('')}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    propertyFiltering: {
      filteringProperties,
    },
    sorting: {},
  });

  useEffect(() => {
    if (events) {
      setEventList(events);
      if (pageCount && preferences.pageSize) {
        const page = pageCount ? Math.ceil(pageCount / preferences.pageSize) : 1;
        setNumOfPages(page);
      }
    }
  }, [events, selectedJamFilters]);

  useEffect(() => {
    handleFilterSelection(selectedJamFilters);
  }, [dateRangeFilter]);

  if (isLoadEventsByDateRangeInProgress) {
    return <JamSpinner />;
  }

  // on pagination click eventList updates
  const onPageClick = (currentPageIndex: number) => {
    setCurrPageIndex(currentPageIndex);
    setApiParams({ ...apiParams, page: currentPageIndex });
  };

  return (
    <div>
      <TemplateUrlModal visible={templateModalVisible} onDismiss={() => setTemplateModalVisible(false)} />
      <Table
        {...collectionProps}
        resizableColumns
        variant="full-page"
        stickyHeader
        empty={
          user?.hasBasicAccess ? (
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>{t(i18nKeys.events.list.empty.description)}</b>
                <Button onClick={navigateToCatlogPage}>{t(i18nKeys.events.list.empty.button.title)}</Button>
              </SpaceBetween>
            </Box>
          ) : (
            <TableEmptyState
              title={t(i18nKeys.events.list.empty.title)}
              subtitle={t(i18nKeys.events.list.empty.subtitle)}
            />
          )
        }
        header={
          <TableHeader
            totalItems={pageCount}
            title={t(i18nKeys.events.title)}
            isLoadingInProgress={isLoadEventsByDateRangeInProgress}
            actionButtons={
              <SpaceBetween direction={isMobile ? 'vertical' : 'horizontal'} size="xs">
                {!isMobile && (
                  <DateRangeFilterInput
                    direction="horizontal"
                    dateRangeFilter={dateRangeFilter}
                    handleDateSelection={handleDateSelection}
                    className="date-selector-action-button"
                  />
                )}
                {(user?.isSuperAdmin || user?.isEventAdmin) && (
                  <Button variant="primary" href={RoutePath.NEW_EVENT} onClick={onNewEventClick}>
                    {t(i18nKeys.newEvent.title)}
                  </Button>
                )}
                {user?.isOnlyBasicUser && (
                  <Button onClick={onTransactionHistoryClick}>
                    <Link href={config.billing.purchaseHistory} external>
                      {t(i18nKeys.events.transactionHistory)}
                    </Link>
                  </Button>
                )}
              </SpaceBetween>
            }
          />
        }
        columnDefinitions={COLUMN_DEFINITIONS(preferences)}
        items={items}
        pagination={
          <Pagination
            currentPageIndex={currPageIndex}
            onChange={({ detail }) => {
              void onPageClick(detail.currentPageIndex);
            }}
            pagesCount={numOfPages}
            ariaLabels={paginationLabels(t)}
          />
        }
        preferences={
          <CollectionPreferences
            title={t(i18nKeys.events.fields.preferences.title)}
            onConfirm={({ detail }) => handleSetPreferences(detail)}
            confirmLabel={t(i18nKeys.general.confirm)}
            cancelLabel={t(i18nKeys.general.cancel)}
            preferences={preferences}
            customPreference={(customValue, setCustomValue) => (
              <FormField label={t(i18nKeys.events.fields.filters.timezoneOptions.label)}>
                <RadioGroup
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  value={customValue}
                  onChange={({ detail }) => setCustomValue(detail.value)}
                  items={[
                    { value: TimezoneFormat.LOCAL, label: t(i18nKeys.events.fields.filters.timezoneOptions.local) },
                    { value: TimezoneFormat.UTC, label: t(i18nKeys.events.fields.filters.timezoneOptions.utc) },
                  ]}
                />
              </FormField>
            )}
            pageSizePreference={{
              title: t(i18nKeys.events.fields.preferences.pageSize.title),
              options: [
                { value: 10, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 10 }) },
                { value: 20, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 20 }) },
                { value: 30, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 30 }) },
                { value: 50, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 50 }) },
                { value: 100, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 100 }) },
              ],
            }}
          />
        }
        filter={
          <Box margin={{ top: 'xs', bottom: 'xs' }}>
            <div className="event-list-select">
              <SpaceBetween direction={isMobile ? 'vertical' : 'horizontal'} size="m">
                {isMobile && (
                  <DateRangeFilterInput
                    direction="vertical"
                    dateRangeFilter={dateRangeFilter}
                    handleDateSelection={handleDateSelection}
                    className="date-selector-filter"
                  />
                )}
                <div style={{ width: '500px' }}>
                  <PropertyFilter
                    filteringProperties={propertyFilterProps.filteringProperties}
                    filteringOptions={[
                      ...propertyFilterProps.filteringOptions.filter(({ propertyKey }) => propertyKey === 'title'),
                      ...Object.keys(EventStatus).map((value) => ({ propertyKey: 'status', value })),
                      ...Object.keys(EventAudienceType).map((value) => ({ propertyKey: 'channel', value })),
                    ]}
                    onChange={({ detail }) => filterData(detail)}
                    query={query}
                    i18nStrings={{
                      filteringAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.filteringAriaLabel),
                      dismissAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.dismissAriaLabel),
                      filteringPlaceholder: t(i18nKeys.eventTemplates.table.eventPlaceholder),
                      groupValuesText: t(i18nKeys.eventTemplates.customTable.filter.groupValuesText),
                      groupPropertiesText: t(i18nKeys.eventTemplates.customTable.filter.groupPropertiesText),
                      operatorsText: t(i18nKeys.eventTemplates.customTable.filter.operatorsText),
                      operationAndText: t(i18nKeys.eventTemplates.customTable.filter.operationAndText),
                      operationOrText: t(i18nKeys.eventTemplates.customTable.filter.operationOrText),
                      operatorLessText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessText),
                      operatorLessOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessOrEqualText),
                      operatorGreaterText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterText),
                      operatorGreaterOrEqualText: t(
                        i18nKeys.eventTemplates.customTable.filter.operatorGreaterOrEqualText
                      ),
                      operatorContainsText: t(i18nKeys.eventTemplates.customTable.filter.operatorContainsText),
                      operatorDoesNotContainText: t(
                        i18nKeys.eventTemplates.customTable.filter.operatorDoesNotContainText
                      ),
                      operatorEqualsText: t(i18nKeys.eventTemplates.customTable.filter.operatorEqualsText),
                      operatorDoesNotEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotEqualText),
                      editTokenHeader: t(i18nKeys.eventTemplates.customTable.filter.editTokenHeader),
                      propertyText: t(i18nKeys.eventTemplates.customTable.filter.propertyText),
                      operatorText: t(i18nKeys.eventTemplates.customTable.filter.operatorText),
                      valueText: t(i18nKeys.eventTemplates.customTable.filter.valueText),
                      cancelActionText: t(i18nKeys.eventTemplates.customTable.filter.cancelActionText),
                      applyActionText: t(i18nKeys.eventTemplates.customTable.filter.applyActionText),
                      allPropertiesLabel: t(i18nKeys.eventTemplates.customTable.filter.allPropertiesLabel),
                      tokenLimitShowMore: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowMore),
                      tokenLimitShowFewer: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowFewer),
                      clearFiltersText: t(i18nKeys.eventTemplates.customTable.filter.clearFiltersText),
                    }}
                  />
                </div>
                <MultiSelectInput
                  options={[jamEventsFilter, campaignEventsFilter]}
                  label={isMobile ? '' : t(i18nKeys.events.fields.filters.eventTypes.label)}
                  selectedOptions={selectedJamFilters}
                  placeholder={getTypeFilterPlaceholder()}
                  onChange={(event: MultiSelectEvent) => {
                    handleFilterSelection(event.detail.selectedOptions);
                  }}
                />
                <span className="event-list-checkbox-filter">
                  <Checkbox
                    checked={showEndedEvents}
                    onChange={({ detail }) => {
                      setShowEndedEvents(detail.checked);
                      showEndedEventFromApi(detail.checked);
                    }}>
                    {t(i18nKeys.events.fields.filters.labels.showEndedEvents)}
                  </Checkbox>
                </span>
              </SpaceBetween>
            </div>
          </Box>
        }
        stickyColumns={{ first: 0, last: 1 }}
      />
    </div>
  );
};

export default EventList;
