import React, { useMemo, useState } from 'react';
import { Box, Button, Form, FormField, Grid, Input, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { joinSpecificTeamValidator } from '@/src/utils/join-specific-team.validation.utils';
import { JoinSpecificTeamFields } from '@/src/types/JoinSpecificTeam';
import { JoinOrCreateTeamProps } from '@/src/types/JamJoinTeam';

const JoinSpecificTeamForm: React.FC<Omit<JoinOrCreateTeamProps, 'createTeam'>> = ({ joinTeam, saving }) => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState('');
  const [teamNameError, setTeamNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validator = useMemo(
    () =>
      joinSpecificTeamValidator(
        { teamName, password },
        { teamName: t(i18nKeys.joinTeam.form.errors.teamName), password: t(i18nKeys.joinTeam.form.errors.password) },
        new Map<JoinSpecificTeamFields, (error: string) => void>([
          [JoinSpecificTeamFields.TEAM_NAME, (error: string) => setTeamNameError(error)],
          [JoinSpecificTeamFields.PASSWORD, (error: string) => setPasswordError(error)],
        ])
      ),
    [teamName, password]
  );

  const onSubmit = async () => {
    const isValid = validator.isValidSection(true);
    if (!isValid) {
      return;
    }
    await joinTeam(teamName, password);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form>
        <SpaceBetween direction="vertical" size="l">
          <FormField label={t(i18nKeys.joinTeam.form.commonFields.teamName) + '*'} errorText={teamNameError}>
            <Input value={teamName} onChange={({ detail }) => setTeamName(detail.value)} />
          </FormField>
          <FormField
            label={t(i18nKeys.joinTeam.form.commonFields.password) + '*'}
            description={t(i18nKeys.joinTeam.createTeam.fields.passwordCaption)}
            errorText={passwordError}>
            <Input type="password" value={password} onChange={({ detail }) => setPassword(detail.value)} />
          </FormField>
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 8 }]}>
            <div className="divider" />
            <Box float="right">
              <Button loading={saving} variant="primary" onClick={() => void onSubmit()}>
                {t(i18nKeys.joinTeam.joinSpecificTeam.submitBtn)}
              </Button>
            </Box>
          </Grid>
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default JoinSpecificTeamForm;
