import React, { useEffect } from 'react';
import { JamProfile } from '@/src/types/JamProfile';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '@/src/RoutePath';
import JamSpinner from '@/src/components/common/JamSpinner';
import { useUser } from '@/src/store/user.context';

export const isProfileEmpty = (profile?: JamProfile) => {
  if (!profile) return true;
  const { country, nickname, email } = profile;
  if (!country || !nickname || !email) {
    // country not added or nickname not added take user to jam profile detail page
    return true;
  }
  return false;
};

/**
 * Component to redirect the user to the JAM profile if his/her profile is incomplete
 * or hide it and show a terms and conditions modal otherwise.
 */
export const JamProfileCheckGuard: React.FC = (props) => {
  const history = useHistory();
  const { profile, loading, user } = useUser();

  useEffect(() => {
    if (!user || loading) {
      return;
    }
    if (isProfileEmpty(profile)) {
      history.replace(RoutePath.USER_PROFILE_CREATE);
    }
  }, [history, user, profile, loading]);

  if (loading) return <JamSpinner />;

  return <React.Fragment>{props.children}</React.Fragment>;
};
