/* eslint-disable @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { mqtt5 } from 'aws-iot-device-sdk-v2/dist/browser';
import { useApi } from './api.context';
import { MQTTService } from '../utils/mqtt.utils';
import {
  EventIncidentWSMessage,
  EventLogWSMessage,
  EventWSMessage,
  NotificationWSMessage,
  ParticipantMessagesWSMessage,
  SupportChatWSMessage,
  SupportChatUpdateWSMessage,
  TeamChatWSMessage,
  TeamParticipantModelWSMessage,
  WebSocketsMessage,
//  CampaignAttemptWSMessage
} from '../types/WebsocketMessages';
import { localLogger } from '../utils/log.utils';
import { useUser } from './user.context';
import { ChatMessage } from '../types/Chat';
import { EnableMessagingResponse } from '../types/EnableMessagingResponse';
import { JamEvent } from '../types/JamEvent';
import { useFlashbars } from './flashbar.context';
import { NotificationData, OfflineNotificationMessage } from '../types/JamNotifications';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { useJamChat } from './jam-chat.context';
import { useJamEventDetails } from './jam-event-details.context';
import { noop, unescape } from 'lodash';
import { useJamChallenge } from './jam-challenge.context';
import { useJamMyTeam } from './jam-myteam.context';
import { useCampaigns } from './campaigns.context';
import { JamSupportChatMessage } from '../types/JamSupportChatMessage';

export interface WebsocketContextValue {
  isLoaded: boolean;
  destroyConnection: () => void;
}

const WebsocketContext = React.createContext<WebsocketContextValue>({
  isLoaded: false,
  destroyConnection: noop,
});

const WebsocketProvider: React.FC = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { jamMessageApi } = useApi();
  const { eventName, updateParticipantEventMessages, updateEventByJamEvent, updateEventIncidents, loadEventDetails, event } =
    useJamEventDetails();
  const { updateTeamChatMessages, updateSupportChatMessages, updateSupportChatMessage } = useJamChat();
  const { loadJamChallengeData } = useJamChallenge();
  const { handleTeamRenamedWSMessage } = useJamMyTeam();
  const mqttClient = useRef<MQTTService>();
  const { user } = useUser();
  const { addFlashbar } = useFlashbars();
  const { 
   // updateCampaignAttemptFromWSMessage, 
    getCampaignEventDetails 
  } = useCampaigns();
  const userExist = !!user?.email;
  const isCampaign = event?.type === "CAMPAIGN_GROUP";

  const initialize = async () => {
    try {
      const response = await jamMessageApi.enableMessaging({ eventName, silent: true });

      mqttClient.current = new MQTTService(response);
      mqttClient.current.listen({ onReceiveMessage, onCredentialsUpdated });
    } catch (e) {
      // error
    }
    setIsLoaded(true);
  };

  const onReceiveMessage: mqtt5.MessageReceivedEventListener = (receivedEvent) => {
    const {
      message: { topicName, payload },
    } = receivedEvent;
    const message = JSON.parse(payload as string) as WebSocketsMessage<any>;
    switch (message.messageType) {
      case new TeamChatWSMessage().messageType: {
        const chatMessages = message.messageBody.map(ChatMessage.fromPlainObject) as ChatMessage[];
        updateTeamChatMessages(chatMessages);
        break;
      }
      case new SupportChatWSMessage().messageType: {
        message.messageBody.messages = (message.messageBody.messages || []).map(
          ChatMessage.fromPlainObject
        ) as ChatMessage[];
        updateSupportChatMessages(message.messageBody);
        break;
      }
      case new SupportChatUpdateWSMessage().messageType: {
        updateSupportChatMessage(JamSupportChatMessage.fromPlainObject(message.messageBody));
        break;
      }
      case new EventWSMessage().messageType: {
        const wsEvent = JamEvent.fromPlainObject(message.messageBody);
        updateEventByJamEvent(wsEvent);
        void loadJamChallengeData({ id: eventName, showLoader: false });
        break;
      }
      case "TeamChallengeAnswerWSMessage": {
        void loadEventDetails(eventName);
        break;
      }
      case new NotificationWSMessage().messageType:
        onNotificationMessage(message.messageBody);
        break;
      case new ParticipantMessagesWSMessage().messageType: {
        const messages: OfflineNotificationMessage[] = (message.messageBody || []).map(
          OfflineNotificationMessage.fromPlainObject
        ) as OfflineNotificationMessage[];
        updateParticipantEventMessages(messages);
        break;
      }
      case new EventIncidentWSMessage().messageType: {
        updateEventIncidents(message.messageBody);
        break;
      }
      case new EventLogWSMessage().messageType: {
        handleTeamRenamedWSMessage(message.messageBody);
        break;
      }
      case new TeamParticipantModelWSMessage().messageType: {
        if(isCampaign){
          void getCampaignEventDetails(eventName, false, true);
        } else {
          void loadEventDetails(eventName);
        }
        break;
      }
      // Commenting out the web socket integration for campaign attempts for now.
      // It's not clear why this is needed and causes unnecessary re-renders.
      // case new CampaignAttemptWSMessage().messageType: {
      //   updateCampaignAttemptFromWSMessage(message.messageBody)
      //   break;
      // }
    }

    localLogger('received WS message', topicName, message);
  };

  const onCredentialsUpdated = async (): Promise<EnableMessagingResponse | undefined> => {
    if (mqttClient.current) {
      const response = await jamMessageApi.enableMessaging({ eventName, silent: true });
      return response;
    }
  };

  const onNotificationMessage = (notificationData: NotificationData) => {
    const {
      notificationMessage: { message, title, type },
      options,
    } = notificationData;
    if (!(['success', 'warning', 'info', 'error'] as FlashbarProps.Type[]).includes(type as FlashbarProps.Type)) {
      return;
    }
    addFlashbar({
      content: unescape(decodeURIComponent(message)),
      header: unescape(decodeURIComponent(title)),
      type: type as FlashbarProps.Type,
      dismissible: true,
      i18nKeys: [],
      id: `${new Date().getTime()}`,
      autoDismiss: !!options.timeOut,
      dismissDuration: options.timeOut,
    });
  };

  const destroyConnection = () => {
    localLogger('connection destroying');
    void mqttClient.current?.destroy();
  };

  // initialize mqtt client to listen to websocket
  useEffect(() => {
    if (!eventName) {
      return;
    }
    if (eventName) {
      void initialize();
    }
    return () => destroyConnection();
  }, [eventName]);

  useEffect(() => {
    if (!userExist) {
      void mqttClient.current?.destroy();
    }
  }, [userExist]);

  const data: WebsocketContextValue = { isLoaded, destroyConnection };

  return <WebsocketContext.Provider value={data}>{children}</WebsocketContext.Provider>;
};

const useWebsocket = () => {
  const context = useContext(WebsocketContext);
  if (context === undefined) {
    throw new Error('useWebsocket can only be used inside WebsocketProvider');
  }
  return context;
};

export { WebsocketProvider, useWebsocket };
