import { Box, Button, Container, ContentLayout, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import JamContainer from '../../ui/molecules/JamContainer/JamContainer';
import { useApi } from '@/src/store/api.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './FacilitatorJamSettings.scss';
import moment from 'moment';
import CountDown from '../../ui/molecules/CountDown/CountDown';
import { EVENT_DETAILS_ROUTES } from '@/src/routes';
import JamSpinner from '../../common/JamSpinner';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';

const FacilitatorJamSettings = () => {
  const { t } = useTranslation();
  const { clearFlashbars } = useFlashbars();
  const { event, eventName, loadEventDetails } = useJamEventDetails();
  const { jamFacilitatorApi } = useApi();
  const [loading, setLoading] = useState(false);
  const isEnded = !!event?.ended;
  const isNotStarted = !!event?.notStarted;
  const isAllowViewChallenges = !!event?.allowViewChallenges;
  const supportChatEnabled = !!event?.supportChatEnabled;
  const eventLocked = isEnded && !isAllowViewChallenges;
  const labEndTime = event ? moment(event.eventEndDate).add(event.labExtensionHours, 'hours') : 0;

  const minutesRemaining = useMemo(() => {
    return Math.max(0, moment(event?.eventEndDate).diff(moment(), 'minutes'));
  }, [event?.eventEndDate]);

  const jamStatusMessage = useMemo(() => {
    let message = i18nKeys.facilitator.jamSettings.jamStatus.challengesUnlocked;
    if (isEnded) {
      if (isAllowViewChallenges) {
        message = i18nKeys.facilitator.jamSettings.jamStatus.butScoringFrozen;
      } else {
        message = i18nKeys.facilitator.jamSettings.jamStatus.scoringFrozenWithout;
      }
    } else if (isNotStarted) {
      message = i18nKeys.facilitator.jamSettings.jamStatus.onlyWarmup;
    }
    return message;
  }, [isEnded, isNotStarted, isAllowViewChallenges]);

  const onEventAdminPage = () => {
    window.open(EVENT_DETAILS_ROUTES.Summary.resolve(eventName), '_blank');
  };

  /**
   * makes all challenges available to all participants
   */
  const unlockChallenges = async () => {
    try {
      clearFlashbars();
      setLoading(true);
      await jamFacilitatorApi.unlockChallenges(eventName);
      // fallback for websockets
      setTimeout(() => void loadEventDetails(eventName, false), 500);
    } catch (e) {
      // error handled in API
    }
    setLoading(false);
  };

  /**
   * put the event back into "not yet started" mode
   */
  const lockChallenges = async () => {
    try {
      clearFlashbars();
      setLoading(true);
      await jamFacilitatorApi.lockChallenges(eventName);
      // fallback for websockets
      setTimeout(() => void loadEventDetails(eventName, false), 500);
    } catch (e) {
      // error handled in API
    }
    setLoading(false);
  };

  const addOneHour = async () => {
    try {
      clearFlashbars();
      setLoading(true);
      await jamFacilitatorApi.addOneHourToEvent(eventName);
      // fallback for websockets
      setTimeout(() => void loadEventDetails(eventName, false), 500);
    } catch (e) {
      // error handled in API
    }
    setLoading(false);
  };

  const subtractOneHour = async () => {
    try {
      clearFlashbars();
      setLoading(true);
      await jamFacilitatorApi.subtractOneHourFromEvent(eventName);
      // fallback for websockets
      setTimeout(() => void loadEventDetails(eventName, false), 500);
    } catch (e) {
      // error handled in API
    }
    setLoading(false);
  };

  const enableSupportChat = async () => {
    try {
      clearFlashbars();
      setLoading(true);
      await jamFacilitatorApi.enableSupportChat(eventName);
      // fallback for websockets
      setTimeout(() => void loadEventDetails(eventName, false), 500);
    } catch (e) {
      // error handled in API
    }
    setLoading(false);
  };

  const disableSupportChat = async () => {
    try {
      clearFlashbars();
      setLoading(true);
      await jamFacilitatorApi.disableSupportChat(eventName);
      // fallback for websockets
      setTimeout(() => void loadEventDetails(eventName), 100);
    } catch (e) {
      // error handled in API
    }
    setLoading(false);
  };

  const renderJamStatus = () => {
    if (isEnded) {
      return <div className="jam-status danger">{t(i18nKeys.facilitator.jamSettings.jamStatus.scoringLocked)}</div>;
    }
    if (!isEnded && isNotStarted) {
      return <div className="jam-status warning">{t(i18nKeys.facilitator.jamSettings.jamStatus.warmupMode)}</div>;
    }
    if (!isEnded && !isNotStarted) {
      return <div className="jam-status active">{t(i18nKeys.facilitator.jamSettings.jamStatus.activeMode)}</div>;
    }
    return '';
  };

  const renderJamStatusActions = () => {
    if (isEnded || event?.isSingleParticipantEvent) {
      return null;
    }
    if (isNotStarted) {
      return (
        <Box margin={{ top: 'm' }}>
          <Button onClick={() => void unlockChallenges()}>
            {t(i18nKeys.facilitator.jamSettings.buttons.makeAvailable)}
          </Button>
        </Box>
      );
    }

    return (
      <Box margin={{ top: 'm' }}>
        <Button onClick={() => void lockChallenges()}>
          {t(i18nKeys.facilitator.jamSettings.buttons.makeWamupAvailable)}
        </Button>
      </Box>
    );
  };

  return (
    <>
      {loading && <JamSpinner />}
      <div className="jam-settings-container">
        <ContentLayout
          header={
            <Header
              variant="h1"
              actions={
                <Button variant="primary" iconName="external" onClick={onEventAdminPage}>
                  {t(i18nKeys.facilitator.jamSettings.buttons.eventAdminPage)}
                </Button>
              }>
              {t(i18nKeys.facilitator.jamSettings.title)}
            </Header>
          }>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }, { colspan: 6 }, { colspan: 6 }]}>
            <Container fitHeight header={<Header>{t(i18nKeys.facilitator.jamSettings.jamStatus.title)}</Header>}>
              <SpaceBetween direction="horizontal" size="l" alignItems="center">
                {renderJamStatus()}
                <Box fontWeight="bold">{t(jamStatusMessage)}</Box>
              </SpaceBetween>
              {renderJamStatusActions()}
            </Container>
            {!eventLocked && (
              <Container fitHeight header={<Header>{t(i18nKeys.facilitator.jamSettings.supportChat.title)}</Header>}>
                <SpaceBetween direction="horizontal" size="l" alignItems="center">
                  {supportChatEnabled ? (
                    <div className="jam-status active">{t(i18nKeys.facilitator.jamSettings.supportChat.enabled)}</div>
                  ) : (
                    <div className="jam-status disabled">
                      {t(i18nKeys.facilitator.jamSettings.supportChat.disabled)}
                    </div>
                  )}
                </SpaceBetween>

                <Box margin={{ top: 'm' }}>
                  {supportChatEnabled && (
                    <Button onClick={() => void disableSupportChat()}>
                      {t(i18nKeys.facilitator.jamSettings.buttons.disableSupportChat)}
                    </Button>
                  )}
                  {!supportChatEnabled && (
                    <Button onClick={() => void enableSupportChat()}>
                      {t(i18nKeys.facilitator.jamSettings.buttons.enableSupportChat)}
                    </Button>
                  )}
                </Box>
              </Container>
            )}
            <Container
              fitHeight
              header={
                <Header description={t(i18nKeys.facilitator.jamSettings.scoringDeadline.caption)}>
                  {t(i18nKeys.facilitator.jamSettings.scoringDeadline.title)}
                </Header>
              }>
              <JamContainer>
                <Box>
                  {moment(event?.eventEndDate).format('MMMM D, h:mm A')} (
                  <CountDown format="D_h:m:s" targetDate={moment(event?.eventEndDate).valueOf()} />)
                </Box>
                {(minutesRemaining > 60 || !eventLocked) && (
                  <Box margin={{ top: 'm' }}>
                    <SpaceBetween direction="horizontal" size="s">
                      {minutesRemaining > 60 && (
                        <div className="substract-button">
                          <Button onClick={() => void subtractOneHour()}>
                            <img className="substract" alt="minimize" src="/assets/minus.svg" />
                            {t(i18nKeys.facilitator.jamSettings.buttons.substract)}
                          </Button>
                        </div>
                      )}
                      {!eventLocked && (
                        <Button iconName="add-plus" onClick={() => void addOneHour()}>
                          {t(i18nKeys.facilitator.jamSettings.buttons.add)}
                        </Button>
                      )}
                    </SpaceBetween>
                  </Box>
                )}
              </JamContainer>

              <Box margin={{ top: 'l' }}>{t(i18nKeys.facilitator.jamSettings.scoringDeadline.info)}</Box>
            </Container>
            <Container
              fitHeight
              header={
                <Header description="Challenges will be available until the time shown below.">
                  {t(i18nKeys.facilitator.jamSettings.challengeExpiration.title)}
                </Header>
              }>
              <JamContainer>
                <Box>
                  {moment(labEndTime).format('MMMM D, h:mm A')} (
                  <CountDown targetDate={labEndTime.valueOf()} format="D_h:m:s" />)
                </Box>
              </JamContainer>
              <Box margin={{ top: 'm' }}>{t(i18nKeys.facilitator.jamSettings.challengeExpiration.info)}</Box>
            </Container>
          </Grid>
        </ContentLayout>
      </div>
    </>
  );
};

export default FacilitatorJamSettings;
