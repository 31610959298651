import { cloneDeep, kebabCase, pick } from 'lodash';
import moment from 'moment-timezone';
import { decodeForMarkdown } from '../utils/string.utils';
import { ChallengeModes } from '../constants/shared/challenge-modes';
import { CastleDefenseProviders } from '../constants/shared/castle-defense-providers';
import { JamConstants } from '../constants/shared/jam-constants';
import { EventBase, EventScoringSettings } from './Event';
import * as common from './common';
import { DiffChange, diffObjects } from './Diff';
import { doesEventSupportChallenge, getAllowedRegions } from '../utils/supported-regions';
import { User } from './User';
import { LabShutoffStatus } from './LabShutoff';
import { DEFAULT_LAB_PROVIDER, LAB_PROVIDER_LABELS, LabProvider } from './LabProvider';
import { EventLabSummary } from './EventLabSummary';
import { jsonArrayMember, jsonMapMember, jsonMember, jsonObject } from 'typedjson';
import { Comment, updateTranslatedAttribute } from '../types/common';
import { toPercentage } from '../utils/percentage.utils';
import { getTimeForPolarisTimePicker } from '../utils/event-time.utils';
import { randomId } from '../utils/randomId';
import { i18nKeys } from '../utils/i18n.utils';

export enum ChallengeTaskValidationType {
  GLOBAL_STATIC_ANSWER = 'GLOBAL_STATIC_ANSWER',
  CFN_OUTPUT_PARAMETER = 'CFN_OUTPUT_PARAMETER',
  LAMBDA_FUNCTION = 'LAMBDA_FUNCTION',
  LAMBDA_FUNCTION_WITH_INPUT = 'LAMBDA_FUNCTION_WITH_INPUT',
}

// https://docs.aws.amazon.com/lambda/latest/dg/lambda-runtimes.html
export enum ChallengeTaskValidationFnRuntimes {
  NODEJS_12_X = 'nodejs12.x',
  NODEJS_10_X = 'nodejs10.x',
  NODEJS_18_X = 'nodejs18.x',
  PYTHON_3_8 = 'python3.8',
  PYTHON_3_7 = 'python3.7',
  PYTHON_3_6 = 'python3.6',
  PYTHON_2_7 = 'python2.7',
}

export enum ChallengeLearningType {
  TEAM = 'TEAM',
  INDIVIDUAL = 'INDIVIDUAL',
}

export const DEFAULT_CHALLENGE_FN_RUNTIME = ChallengeTaskValidationFnRuntimes.NODEJS_18_X;

export const DEFAULT_REGION_ALLOWLIST = ['us-east-1'];

export interface UpdateBackupChallengesEvent {
  forChallengeId?: string;
  newBackupChallenges?: ChallengeDescriptor[];
}

@jsonObject
export class ChallengeGlobalFlags {
  @jsonMember(Boolean)
  isArchived = false;

  @jsonMember(Boolean)
  isPublic = false;

  @jsonMember(Boolean)
  isDemo = false;

  @jsonMember(Boolean)
  isDefective = false;

  get isPrivate(): boolean {
    return !this.isPublic;
  }

  copyGlobalFlagsTo(other: common.Nullable<ChallengeGlobalFlags>) {
    if (other) {
      other.isArchived = this.isArchived;
      other.isPublic = this.isPublic;
      other.isDefective = this.isDefective;
      other.isDemo = this.isDemo;
    } else {
      return null;
    }
  }
}

@jsonObject
export class LabDeploymentMetric {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  rangeKey: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableNumberValue)
  challengeVersion: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  challengeMajorVersion: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  challengeMinorVersion: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  challengeCfnHash: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  labProvider: common.NullableString = null;

  @jsonMember(common.NullableNumberValue)
  lastUpdated: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  deploymentRequestTime: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  deploymentResolveTime: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  deploymentResolutionDuration: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  stackStartTime: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  stackCompleteTime: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  stackDuration: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  stackStatus: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  stackStatusReason: common.NullableString = null;

  @jsonMember(Boolean)
  deployedSuccessfully = false;

  @jsonMember(Object)
  metadata: { [key: string]: string } = {};
}

@jsonObject
export class CastleDefenseDetails {
  @jsonMember(String)
  provider = 'GAMEDAY';

  @jsonMember(common.NullableStringValue)
  templateId: common.NullableString = null;
}

@jsonObject
export class Sponsor {
  @jsonMember(common.NullableStringValue)
  name: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  url: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  logo: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  static isEmpty(sponsor: Sponsor): boolean {
    return !sponsor || Object.values(sponsor).every((values) => !values);
  }
}

@jsonObject
export class ChallengeSettings {
  @jsonMember(common.NullableStringValue)
  category: common.NullableString = null;

  @jsonMember(Number)
  difficulty = 1;

  @jsonMember(Boolean)
  challengeAlwaysOn = false;

  @jsonMember(common.NullableStringValue)
  challengeIcon: common.NullableString = null;

  @jsonArrayMember(String)
  awsServices: string[] = [];

  @jsonMember(common.NullableStringValue)
  jamType: common.NullableString = null;

  @jsonMember(Boolean)
  codeWhispererDisabled = false;

  @jsonMember(String)
  mode = ChallengeModes.TRADITIONAL;

  @jsonMember(String)
  learningType: ChallengeLearningType = ChallengeLearningType.TEAM;

  @jsonMember(String)
  defaultLabProvider: LabProvider = DEFAULT_LAB_PROVIDER;

  @jsonArrayMember(String)
  regionAllowlist: string[] = DEFAULT_REGION_ALLOWLIST;

  @jsonArrayMember(String)
  regionDenylist: string[] = [];

  @jsonMember(Boolean)
  sshKeyPairRequired = false;

  @jsonMember(common.NullableStringValue)
  customKeyPairName: common.NullableString = null;

  @jsonArrayMember(String)
  allowlistServicesRequired: string[] = [];

  @jsonMember(Number)
  idleMinsBeforeReady = 0;

  @jsonMember(CastleDefenseDetails)
  castleDefenseDetails: CastleDefenseDetails = new CastleDefenseDetails();

  @jsonMember(Sponsor)
  sponsor: common.Nullable<Sponsor> = null;

  isCastleDefense(): boolean {
    return this.mode === ChallengeModes.CASTLE_DEFENSE;
  }

  isGameDay(): boolean {
    return (
      this.isCastleDefense() &&
      this.castleDefenseDetails &&
      this.castleDefenseDetails.provider === CastleDefenseProviders.GAMEDAY
    );
  }

  get defaultLabProviderLabel(): string {
    return LAB_PROVIDER_LABELS[this.defaultLabProvider];
  }

  static diff(previousSettings: ChallengeSettings, updatedSettings: ChallengeSettings): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeSettings());
    return diffObjects(previousSettings, updatedSettings, propertiesToDiff);
  }
}

@jsonObject
export class ChallengeLearningOutcome {
  @jsonMember(common.NullableStringValue)
  summary: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  content?: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  introduction: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  topicsCovered: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  technicalKnowledgePrerequisites: common.NullableString = null;

  static diff(previousOutcome: ChallengeLearningOutcome, updatedOutcome: ChallengeLearningOutcome): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeLearningOutcome());
    return diffObjects(previousOutcome, updatedOutcome, propertiesToDiff);
  }
}

@jsonObject
export class Clue {
  @jsonMember(common.NullableNumberValue)
  order: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  title?: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description?: common.NullableString = null;

  decodeForMarkdown() {
    this.description = decodeForMarkdown(this.description as string);
  }

  static defaultClue() {
    const defaultClue = new Clue();
    defaultClue.order = 1;
    return defaultClue;
  }

  static diff(previousClue: Clue, updatedClue: Clue): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new Clue());
    return diffObjects(previousClue, updatedClue, propertiesToDiff);
  }
}

@jsonObject
export class ChallengeTask {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;

  @jsonMember(Number)
  taskNumber = 1;

  @jsonMember(Number)
  scorePercent = 100;

  @jsonArrayMember(String)
  dependsOnTaskIds: string[] = [];

  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  content: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  background: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  gettingStarted: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  inventory: common.NullableString = null;

  @jsonArrayMember(String)
  awsServicesUsed: string[] = [];

  @jsonMember(common.NullableStringValue)
  validationDescription: common.NullableString = null;

  @jsonArrayMember(Clue)
  clues: Clue[] = [];

  @jsonMember(String)
  validationType: ChallengeTaskValidationType = ChallengeTaskValidationType.LAMBDA_FUNCTION;

  @jsonMember(common.NullableStringValue)
  answerOutputParamName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  globalStaticAnswer: common.NullableString = null;

  @jsonMember(String)
  validationFunctionRuntime: string = DEFAULT_CHALLENGE_FN_RUNTIME;

  @jsonMember(common.NullableStringValue)
  validationFunction: common.NullableString = null;

  get label(): string {
    if (this.title) {
      return `Task ${this.taskNumber}: ${this.title}`;
    } else {
      return `Task ${this.taskNumber}`;
    }
  }

  decodeForMarkdown() {
    this.content = decodeForMarkdown(this.content as string);
    this.clues.forEach((clue) => clue.decodeForMarkdown());
  }

  updateClueOrders() {
    if (this.clues && this.clues.length > 0) {
      for (let i = 0; i < this.clues.length; i++) {
        this.clues[i].order = i + 1;
      }
    }
  }

  // dummy setter, dont use the value from the server, always use the dynamic getter
  set validatedByLambda(value: boolean) {
    return;
  }

  get validatedByLambda(): boolean {
    switch (this.validationType) {
      case 'LAMBDA_FUNCTION':
      case 'LAMBDA_FUNCTION_WITH_INPUT':
        return true;
      default:
        return false;
    }
  }

  get requiresInput(): boolean {
    return this.validationType !== 'LAMBDA_FUNCTION';
  }

  get hasStaticAnswer(): boolean {
    return this.validationType === 'GLOBAL_STATIC_ANSWER';
  }

  get hasDynamicAnswer(): boolean {
    return this.validationType === 'CFN_OUTPUT_PARAMETER';
  }

  getClue(order: number): Clue | undefined {
    return this.clues.find((clue) => clue.order === order);
  }

  static defaultChallengeTask(): ChallengeTask {
    const defaultTask = new ChallengeTask();
    defaultTask.id = randomId();
    defaultTask.clues = [Clue.defaultClue()];
    return defaultTask;
  }

  static diff(previousTask: ChallengeTask, updatedTask: ChallengeTask): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeTask());
    return diffObjects(previousTask, updatedTask, propertiesToDiff);
  }
}

@jsonObject
export class ChallengeIssueStatusItem {
  @jsonMember(common.NullableNumberValue)
  time: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  status: common.Nullable<ChallengeIssueStatus> = null;
}
@jsonObject
export class ChallengeIssue {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;

  @jsonMember(String)
  title = '';

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(Number)
  challengeVersion = 0;

  @jsonMember(Number)
  challengeMajorVersion = 0;

  @jsonMember(Number)
  challengeMinorVersion = 0;

  @jsonMember(String)
  challengeVersionStatus: ChallengeStatus = ChallengeStatus.APPROVED;

  @jsonMember(String)
  severity: ChallengeIssueSeverity = ChallengeIssueSeverity.LOW;

  @jsonMember(String)
  description = '';

  @jsonArrayMember(String)
  attachments: string[] = [];

  @jsonMember(String)
  eventName = 'N/A';

  // This TypedJSON requires () => to avoid potential ciruclar reference/type being undefined
  /* istanbul ignore next */
  @jsonArrayMember(() => Comment)
  comments: Comment[] = [];

  @jsonMember(String)
  status: ChallengeIssueStatus = ChallengeIssueStatus.OPEN;

  @jsonArrayMember(ChallengeIssueStatusItem)
  statusHistory: ChallengeIssueStatusItem[] = [];

  @jsonMember(common.NullableStringValue)
  openedBy: common.NullableString = null;

  @jsonMember(common.NullableBooleanValue)
  isPrivate: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  minify: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  assignee: common.NullableString = null;

  @jsonArrayMember(ChallengeIssueStatusItem)
  assigneeHistory: ChallengeIssueAssignmentItem[] = [];

  @jsonMember(common.NullableStringValue)
  resolvedBy: common.NullableString = null;

  @jsonMember(common.NullableNumberValue)
  resolvedTime: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  lastUpdatedBy: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  createdDate: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  lastUpdatedDate: common.NullableTimeStamp = null;

  static getShortSeverity(severity: ChallengeIssueSeverity): string {
    if (severity === ChallengeIssueSeverity.CRITICAL) {
      return ChallengeIssueSeverityDescriptions.CRITICAL.split(':', 1)[0];
    }
    if (severity === ChallengeIssueSeverity.HIGH) {
      return ChallengeIssueSeverityDescriptions.HIGH.split(':', 1)[0];
    }
    if (severity === ChallengeIssueSeverity.MEDIUM) {
      return ChallengeIssueSeverityDescriptions.MEDIUM.split(':', 1)[0];
    }
    return ChallengeIssueSeverityDescriptions.LOW.split(':', 1)[0];
  }

  static compareSeverity(sev1: ChallengeIssueSeverity, sev2: ChallengeIssueSeverity): number {
    if (sev1 === sev2) {
      return 0;
    }
    if (sev1 === ChallengeIssueSeverity.CRITICAL) {
      return -1;
    }
    if (sev1 === ChallengeIssueSeverity.HIGH) {
      if (sev2 === ChallengeIssueSeverity.CRITICAL) {
        return 1;
      }
      return -1;
    }
    if (sev2 === ChallengeIssueSeverity.LOW) {
      return -1;
    }
    return 1;
  }

  static compareIssue(issue1: ChallengeIssue, issue2: ChallengeIssue): number {
    return ChallengeIssue.compareSeverity(issue1.severity, issue2.severity);
  }
}

@jsonObject
export class ChallengeProps extends ChallengeSettings {
  // Metadata Properties
  @jsonMember(common.NullableStringValue)
  lastEditedBy: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  lastUpdatedBy: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  lastApprovedBy: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  reviewer: common.NullableString = null;

  @jsonMember(common.Email)
  barRaiser: common.NullableEmail = null;

  @jsonMember(common.NullableStringValue)
  owner: common.NullableString = null;

  @jsonArrayMember(String)
  maintainers: string[] = [];

  // Challenge Properties
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  @jsonArrayMember(ChallengeTask)
  tasks: ChallengeTask[] = [];

  @jsonMember(common.NullableStringValue)
  cfnTemplate: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  studentPolicy: common.NullableString = null;

  @jsonArrayMember(String)
  tags: string[] = [];

  @jsonMember(common.NullableStringValue)
  wiki: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  facilitatorNotes: common.NullableString = null;

  // This TypedJSON requires () => to avoid potential ciruclar reference/type being undefined
  @jsonArrayMember(Comment)
  comments: Comment[] = [];

  @jsonMember(ChallengeLearningOutcome)
  learningOutcome: ChallengeLearningOutcome = new ChallengeLearningOutcome();

  @jsonMember(ChallengeIssue)
  issue: ChallengeIssue = new ChallengeIssue();

  @jsonMember(updateTranslatedAttribute)
  translation: updateTranslatedAttribute;

  @jsonMember(common.NullableStringValue)
  nextSteps: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  difficultyDesc: common.NullableString = null; // frontend only

  @jsonArrayMember(String)
  searchableText: string[] = []; // frontend only

  getTask(taskId: string): ChallengeTask | undefined {
    return this.tasks.find((task) => task.id === taskId);
  }

  getClue(taskId: string, clueOrder: number): Clue | undefined {
    return this.getTask(taskId)?.getClue(clueOrder);
  }

  pick(...properties: (ChallengeSettingsAttribute | ChallengePropsBaseAttribute)[]) {
    return pick(this, properties);
  }

  pickTask(taskId: string): { tasks: ChallengeTask[] } {
    const obj: { tasks: ChallengeTask[] } = this.pick('tasks');
    obj.tasks = obj.tasks.filter((task) => task.id === taskId);
    return obj;
  }

  get settings(): object {
    return this.pick(...(Object.keys(new ChallengeSettings()) as ChallengeSettingsAttribute[]));
  }

  /**
   * Get the list of allowlisted regionIds that are not also in the challenge denylist.
   */
  get allowedRegions(): string[] {
    return getAllowedRegions(this.regionAllowlist, this.regionDenylist);
  }

  static diffTitle(previousChallengeProps: ChallengeProps, updatedChallengeProps: ChallengeProps): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeProps()).filter((prop) => prop === 'title');
    return diffObjects(previousChallengeProps, updatedChallengeProps, propertiesToDiff);
  }

  static diffDescription(previousChallengeProps: ChallengeProps, updatedChallengeProps: ChallengeProps): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeProps()).filter((prop) => prop === 'description');
    return diffObjects(previousChallengeProps, updatedChallengeProps, propertiesToDiff);
  }

  static diffCfnTemplate(previousChallengeProps: ChallengeProps, updatedChallengeProps: ChallengeProps): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeProps()).filter((prop) => prop === 'cfnTemplate');
    return diffObjects(previousChallengeProps, updatedChallengeProps, propertiesToDiff);
  }

  static diffStudentPolicy(
    previousChallengeProps: ChallengeProps,
    updatedChallengeProps: ChallengeProps
  ): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeProps()).filter((prop) => prop === 'studentPolicy');
    return diffObjects(previousChallengeProps, updatedChallengeProps, propertiesToDiff);
  }

  static diffWiki(previousChallengeProps: ChallengeProps, updatedChallengeProps: ChallengeProps): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new ChallengeProps()).filter((prop) => prop === 'wiki');
    return diffObjects(previousChallengeProps, updatedChallengeProps, propertiesToDiff);
  }
}

export enum ChallengeStatus {
  DRAFT = 'DRAFT',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  IN_REVIEW = 'IN_REVIEW',
  NEEDS_WORK = 'NEEDS_WORK',
  APPROVED = 'APPROVED',
}

export enum ChallengeReviewStatus {
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  IN_REVIEW = 'IN_REVIEW',
  NEEDS_WORK = 'NEEDS_WORK',
  APPROVED = 'APPROVED',
  NOT_REVIEWED = 'NOT_REVIEWED',
}

@jsonObject
export class ChallengeTaskScoring {
  @jsonMember(common.NullableStringValue)
  taskId: common.NullableString = null;

  @jsonMember(Number)
  taskNumber = 1;

  @jsonMember(Number)
  pointsPossible = 0;

  @jsonMember(Number)
  clue1PenaltyPoints = 0;

  @jsonMember(Number)
  clue2PenaltyPoints = 0;

  @jsonMember(Number)
  clue3PenaltyPoints = 0;

  getCluePenaltyPoints(clueOrder: number) {
    switch (clueOrder) {
      case 1:
        return this.clue1PenaltyPoints;
      case 2:
        return this.clue2PenaltyPoints;
      default:
        return this.clue3PenaltyPoints;
    }
  }
}

@jsonObject
export class Challenge extends ChallengeGlobalFlags {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableNumberValue)
  version: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  minorVersion: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  majorVersion: common.NullableNumber = null;

  @jsonMember(Number)
  avgStackDeployTime = 0;

  @jsonMember(Number)
  avgDeployResolveTime = 0;

  @jsonMember(common.NullableTimeStampValue)
  avgDeployTimesLastUpdated: common.NullableTimeStamp = null;

  @jsonMember(LabDeploymentMetric)
  lastSuccessfulDeployment: LabDeploymentMetric = new LabDeploymentMetric();

  @jsonMember(String)
  status: common.Nullable<ChallengeStatus> = null;

  @jsonMember(String)
  barRaiserReviewStatus: common.Nullable<ChallengeReviewStatus> = null;

  @jsonMember(common.NullableTimeStampValue)
  createdDate: common.NullableTimeStamp = null;

  @jsonMember(ChallengeProps)
  props: ChallengeProps = new ChallengeProps();

  @jsonMember(Number)
  pointsPossible = 0;

  @jsonArrayMember(ChallengeTaskScoring)
  taskScoring: ChallengeTaskScoring[] = [];

  /**
   * 0 - 1 float representing the likelihood of this challenge to succeed deployment.
   */
  @jsonMember(common.NullableNumberValue)
  stability: common.NullableNumber = null;

  /**
   * Shutoff status of the challenge. May be undefined.
   */
  @jsonMember(LabShutoffStatus)
  shutoffStatus?: LabShutoffStatus;

  @jsonMember(common.NullableBooleanValue)
  approved: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  challengeAlwaysOn: common.NullableBoolean = null;

  get id(): common.NullableString {
    return this.challengeId;
  }

  get awsAccountBased(): boolean {
    return ChallengeUtils.isGameDay(this) || this.hasStack || this.hasIamPolicy;
  }

  get hasStack(): boolean {
    return ChallengeUtils.isGameDay(this) || this.hasCfnTemplate;
  }

  get hasCfnTemplate(): boolean {
    return !!this.props.cfnTemplate && this.props.cfnTemplate.length > 1;
  }

  get hasIamPolicy(): boolean {
    return !!this.props.studentPolicy && this.props.studentPolicy.length > 1;
  }

  get hasCustomResources(): boolean {
    return this.props.tasks.some((t) => t.validatedByLambda);
  }

  get difficultyColor(): 'blue' | 'grey' | 'green' | 'red' {
    switch (this.props.difficulty) {
      case 0:
        return 'green';
      case 1:
        return 'grey';
      default:
        return 'red';
    }
  }

  get difficultyKey(): string {
    switch (this.props.difficulty) {
      case 0:
        return 'easy';
      case 1:
        return 'medium';
      case 2:
        return 'hard';
      default:
        return 'expert';
    }
  }

  /**
   * Get a label for the stability score column. i.e. "Fair (87%)"
   */
  get stabilityLabel(): { label: common.Stability; percent: common.NullableString } {
    if (!this.hasStack) {
      return { label: common.Stability.N_A, percent: null };
    }

    if (this.stability == null) {
      return { label: common.Stability.LIMITED_DATA, percent: null };
    }

    let label: common.Stability;

    if (this.stability >= 0.99) {
      label = common.Stability.PERFECT;
    } else if (this.stability > 0.95) {
      label = common.Stability.GREAT;
    } else if (this.stability > 0.9) {
      label = common.Stability.GOOD;
    } else if (this.stability > 0.85) {
      label = common.Stability.FAIR;
    } else {
      label = common.Stability.POOR;
    }

    return {
      label,
      percent: toPercentage(this.stability),
    };
  }

  get sponsored(): boolean {
    if (this.props.sponsor) {
      return !Sponsor.isEmpty(this.props.sponsor);
    }

    return false;
  }

  decodeForMarkdown() {
    this.props.wiki = decodeForMarkdown(this.props.wiki as string);
    this.props.facilitatorNotes = decodeForMarkdown(this.props.facilitatorNotes as string);
    this.props.description = decodeForMarkdown(this.props.description as string);
    this.props.nextSteps = decodeForMarkdown(this.props.nextSteps as string);
    this.props.tasks.forEach((task) => task.decodeForMarkdown());
  }

  get numTasks(): number {
    return this.props.tasks.length;
  }

  get numLambdaValidatedTasks(): number {
    return this.props.tasks.filter((task) => task.validatedByLambda).length;
  }

  get numStaticAnswerTasks(): number {
    return this.props.tasks.filter((task) => task.hasStaticAnswer).length;
  }

  get numDynamicAnswerTasks(): number {
    return this.props.tasks.filter((task) => task.hasDynamicAnswer).length;
  }

  get isGameDay(): boolean {
    return this.props.isGameDay();
  }

  get badges(): common.colorAndText[] {
    const defectiveBadge: common.colorAndText = {
      color: "red",
      text: "Defective"
    }

    const privateBadge: common.colorAndText = {
      color: "blue",
      text: "Private"
    }

    const difficultyBadge: common.colorAndText = {
      color: this.difficultyColor,
      text: this.difficultyKey
    }

    const lambdaValidatedBadge: common.colorAndText = {
      color: "grey",
      text: "Lambda Validated"
    }

    const dynamicAnswerBadge: common.colorAndText = {
      color: "grey",
      text: "Dynamic Answer"
    }

    const staticAnswerBadge: common.colorAndText = {
      color: "grey",
      text: "Static Answer"
    }

    return [
      ...(this.isDefective ? [defectiveBadge]:[]),
      ...(this.isPrivate ? [privateBadge]:[]),
      difficultyBadge,
      ...(this.hasCustomResources ? [lambdaValidatedBadge]:[]),
      ...(this.numDynamicAnswerTasks ? [dynamicAnswerBadge]:[]),
      ...(this.numStaticAnswerTasks ? [staticAnswerBadge]:[])
    ]
  }
  /**
   * NOTE: Helper functions are lost during
   * serialization to JSON. Please use ChallengeUtils
   * for future helper functions.
   */
}

@jsonObject
export class ChallengeSolveTime {
  @jsonMember(common.NullableNumberValue)
  timeSolved: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  numSeconds: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  teamName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  participantLogin: common.NullableString = null;
}

@jsonObject
export class ChallengeSolveTimes {
  @jsonArrayMember(ChallengeSolveTime)
  recentSolveTimes: ChallengeSolveTime[] = [];

  @jsonMember(common.NullableNumberValue)
  trimmedAvgSeconds: common.NullableNumber = null;

  // Necessary to disable following lines to allow the use of TypedJson annotation with these nullable classes
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(ChallengeSolveTime))
  lowestSolveTime: common.Nullable<ChallengeSolveTime> = null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(ChallengeSolveTime))
  highestSolveTime: common.Nullable<ChallengeSolveTime> = null;
}

@jsonObject
export class ChallengeGlobalStatistics {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null; // frontend only

  @jsonMember(Number)
  rating = 0;

  @jsonMember(Number)
  ratingCount = 0;

  @jsonMember(Number)
  difficultyRating = 0;

  @jsonMember(Number)
  jamsUsed = 0;

  @jsonMember(Number)
  totalIncorrect = 0;

  @jsonMember(Number)
  totalRequestedClues = 0;

  @jsonMember(Number)
  totalCompletedTasks = 0;

  @jsonMember(Number)
  passRate = 0;

  @jsonMember(common.NullableNumberValue)
  firstUsed: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  lastUsed: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  lastSolved: common.NullableNumber = null;

  @jsonMember(ChallengeSolveTimes)
  solveTimes: ChallengeSolveTimes = new ChallengeSolveTimes();

  @jsonMember(common.NullableNumberValue)
  averageCostPerHour: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  unresolvedChallengeIssues: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  highestIssueSeverity: common.Nullable<ChallengeIssueSeverity> = null;

  get highestIssueSeverityBadgeType(): 'red' | 'green' | 'blue' | 'grey' {
    if (!this.highestIssueSeverity) {
      return 'green';
    }

    switch (this.highestIssueSeverity) {
      case 'CRITICAL':
        return 'red';
      case 'HIGH':
        return 'blue';
      case 'MEDIUM':
      case 'LOW':
      default:
        return 'grey';
    }
  }

  get id() {
    return this.challengeId;
  }

  solvedRecently(): boolean {
    return this.solvedInPastXDays(90);
  }

  solvedInPastXDays(days: number): boolean {
    if (!this.lastSolved) {
      return false;
    }
    const lastSolved = moment(this.lastSolved);
    const xDaysAgo = moment().subtract(days, 'days');
    return lastSolved.isAfter(xDaysAgo);
  }
}

@jsonObject
export class ChallengeWrapper extends ChallengeGlobalFlags {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(Challenge))
  latest: common.Nullable<Challenge> = new Challenge();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(Challenge))
  latestApproved: common.Nullable<Challenge> = null;

  @jsonMember(ChallengeGlobalStatistics)
  globalStatistics: ChallengeGlobalStatistics = new ChallengeGlobalStatistics();

  @jsonMember(Boolean)
  isArchived = false;

  @jsonMember(Boolean)
  isPublic = false;

  @jsonMember(Boolean)
  isDemo = false;

  @jsonMember(Boolean)
  isDefective = false;

  @jsonMember(common.NullableStringValue)
  testEventName: common.NullableString = null;

  get challengeId() {
    return this.latest?.challengeId;
  }

  /**
   * copy any flags or attributes that are on the ChallengeWrapper level but also convenient to have on
   * the challenge object for now it's only the archived flag.
   */
  copyChallengeLevelFlagsToVersions() {
    this.copyGlobalFlagsTo(this.latest);

    if (this.latestApproved) {
      this.copyGlobalFlagsTo(this.latestApproved);
    }
  }

  decodeForMarkdown() {
    if (this.latest) {
      this.latest.decodeForMarkdown();
    }
    if (this.latestApproved) {
      this.latestApproved.decodeForMarkdown();
    }
  }

  get hasApprovedVersion() {
    return this.latest?.status === ChallengeStatus.APPROVED || this.latestApproved != null;
  }

  get latestVersionApproved(): boolean {
    return this.latest?.status === ChallengeStatus.APPROVED;
  }

  isEligibleForEvents(): boolean {
    // hide archived challenges from the source list
    if (this.isArchived === true) {
      return false;
    }

    // filter defective challenges from the list
    if (this.isDefective) {
      return false;
    }

    // filter out challenges that dont have an approved version
    return this.hasApprovedVersion;
  }
}

@jsonObject
export class ChallengeListItem extends Challenge {
  @jsonMember(ChallengeGlobalStatistics)
  globalStatistics: ChallengeGlobalStatistics = new ChallengeGlobalStatistics();

  constructor(challenge: Challenge, globalStatistics: ChallengeGlobalStatistics) {
    super();
    Object.assign(this, challenge);
    this.globalStatistics = globalStatistics;
  }
}

@jsonObject
export class ChallengeForImport {
  @jsonMember(String)
  id = '';
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonMember(Boolean)
  warmup = false;
  @jsonMember(Number)
  difficulty = 0;
  @jsonMember(String)
  difficultyDesc = '';
  @jsonMember(Boolean)
  ssh = false;
  @jsonMember(Boolean)
  solvedRecently = false;
  @jsonMember(Boolean)
  defective = false;
  @jsonMember(Boolean)
  missingSupportedRegions = false;
  @jsonMember(Boolean)
  missing = false;
}

@jsonObject
export class ClueOverrides {
  @jsonMember(common.NullableNumberValue)
  order: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  penalty: common.NullableNumber = null;
}

@jsonObject
export class ChallengeOverrides {
  @jsonMember(common.NullableNumberValue)
  score: common.NullableNumber = null;

  @jsonArrayMember(ClueOverrides)
  clues: ClueOverrides[] = [];

  @jsonMember(common.NullableNumberValue)
  difficulty: common.NullableNumber = null;

  @jsonMember(common.NullableBooleanValue)
  challengeAlwaysOn: common.NullableBoolean = null;
}

@jsonObject
export class ChallengePrizeInformation {
  @jsonMember(common.NullableNumberValue)
  prizeCount: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  maxClues: common.NullableNumber = null;
}

@jsonObject
export class ChallengeVirtualQueueSettings {
  @jsonMember(Boolean)
  enabled = false;

  @jsonMember(Number)
  minutesPerTimeSlot = 0;

  @jsonMember(Number)
  maxTeamsPerTimeSlot = 0;

  @jsonMember(Number)
  openingHour = 0; // 0-23

  @jsonMember(Number)
  openingMinute = 0; // 0-59

  @jsonMember(Number)
  closingHour = 0; // 0-23

  @jsonMember(Number)
  closingMinute = 0; // 0-59
}

@jsonObject
export class ChallengeDescriptor {
  static readonly LATEST_APPROVED_VERSION = 0;
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(Number)
  version: number = ChallengeDescriptor.LATEST_APPROVED_VERSION;

  /**
   * Used to override the lab provider for a specific challenge.
   * Leave as null to use the default lab provider configured at the challenge level.
   */
  @jsonMember(common.NullableStringValue)
  labProvider: common.Nullable<LabProvider> = null;

  /**
   * Copied from the ChallengeProps.defaultLabProvider, not editable.
   */
  @jsonMember(common.NullableStringValue)
  defaultLabProvider: common.Nullable<LabProvider> = null;

  @jsonMember(ChallengeOverrides)
  overrides: ChallengeOverrides = new ChallengeOverrides();

  @jsonMember(ChallengePrizeInformation)
  prizeInformation: ChallengePrizeInformation = new ChallengePrizeInformation();

  @jsonMember(ChallengeVirtualQueueSettings)
  virtualQueueSettings: ChallengeVirtualQueueSettings = new ChallengeVirtualQueueSettings();

  @jsonMember(common.NullableNumberValue)
  labAutoScalingOverride: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  restartsAllowed: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  displayTime: common.NullableString = null;

  @jsonMember(common.NullableBooleanValue)
  hidden: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  startChallengeLink: common.NullableString = null;

  @jsonMember(common.NullableBooleanValue)
  codeWhispererDisabled: common.NullableBoolean = null;

  @jsonArrayMember(String)
  dependsOnChallengeIds: string[] = [];

  /**
   * May be set by frontend components that need to keep track of shutoff status in their state.
   */
  @jsonMember(LabShutoffStatus)
  shutoffStatus?: LabShutoffStatus;

  /**
   * May be set by frontend components that need to keep track of shutoff status in their state.
   * The shutoff status of this challenge in its corresponding test event, if there is one.
   */
  @jsonMember(LabShutoffStatus)
  testEventShutoffStatus?: LabShutoffStatus;

  /**
   * optional, if set, the system will attempt to run this challenge using the EE poolId provided
   * only applicable if this challenge is running in
   */
  @jsonMember(common.NullableStringValue)
  poolIdOverride: common.NullableString = null;

  @jsonMember(common.NullableDateStringValue)
  displayDateModel: common.NullableDateString = null; // frontend only

  @jsonMember(common.NullableDateStringValue)
  displayTimeModel: common.NullableDateString = null; // frontend only

  static fromChallenge(challenge: Challenge) {
    const cd = new ChallengeDescriptor();
    cd.challengeId = challenge.challengeId;
    cd.version = ChallengeDescriptor.LATEST_APPROVED_VERSION;
    cd.defaultLabProvider = challenge.props.defaultLabProvider;
    return cd;
  }

  setDateTimeModels() {
    if (this.displayTime) {
      this.displayDateModel = this.displayTime?.split('T')[0];
      this.displayTimeModel = getTimeForPolarisTimePicker(this.displayTime);
    } else {
      this.displayDateModel = null;
      this.displayTimeModel = null;
    }
  }

  afterImportFromAnotherEvent(): void {
    this.version = ChallengeDescriptor.LATEST_APPROVED_VERSION;
    this.displayTime = null;
    this.setDateTimeModels();
    this.labAutoScalingOverride = null;
    this.prizeInformation = new ChallengePrizeInformation();
    this.virtualQueueSettings = new ChallengeVirtualQueueSettings();
    this.restartsAllowed = null;
  }

  static filterNonUniqueChallengeDescriptorsFromOriginal = (
    originalChallenges: ChallengeDescriptor[],
    newChallenges: ChallengeDescriptor[]
  ) => {
    const challengeDescriptors: ChallengeDescriptor[] = [];
    newChallenges.forEach((challenge: ChallengeDescriptor) => {
      const indexOfChallengeDescriptor = originalChallenges.findIndex(
        (cd: ChallengeDescriptor) => cd.challengeId === challenge.challengeId
      );
      if (indexOfChallengeDescriptor < 0) {
        challengeDescriptors.push(challenge);
      }
    });
    return challengeDescriptors;
  };
}

export type ChallengeSettingsAttribute = keyof ChallengeSettings;
export type ChallengePropsBaseAttribute = keyof ChallengeProps;

@jsonObject
export class ChallengeStatistics {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  challengeName: common.NullableString = null;

  @jsonArrayMember(String)
  teamsStartedChallenge: string[] = [];

  @jsonArrayMember(String)
  teamsAnsweredCorrectly: string[] = [];

  @jsonArrayMember(String)
  teamsAnsweredIncorrectly: string[] = [];

  @jsonArrayMember(String)
  teamsRequestedClues: string[] = [];

  @jsonMember(common.NullableNumberValue)
  totalAnsweredCorrectly: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  totalAnsweredIncorrectly: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  totalRequestedClues: common.NullableNumber = null;
}

@jsonObject
export class ChallengeFeedback {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(Number)
  challengeRank = 0;

  @jsonMember(Number)
  challengeDifficulty = 0;

  @jsonMember(common.NullableBooleanValue)
  didYouLearnSomethingNew: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  notes: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  createdDate: common.NullableDateString = null;

  @jsonMember(common.NullableStringValue)
  updatedDate: common.NullableDateString = null;

  @jsonMember(common.NullableNumberValue)
  version: common.NullableNumber = null;
}

export interface ChallengeFeedbackSortOptions {
  sortType: ChallengeFeedbackSortType;
  ascending: boolean;
  label: string;
}

export enum ChallengeFeedbackSortType {
  DATE = 'DATE',
  RATING = 'RATING',
}

@jsonObject
export class ChallengeFeedbackSummary {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(Number)
  total = 0;

  @jsonMember(Number)
  totalWithComments = 0;

  @jsonMember(Number)
  rating1 = 0;

  @jsonMember(Number)
  rating2 = 0;

  @jsonMember(Number)
  rating3 = 0;

  @jsonMember(Number)
  rating4 = 0;

  @jsonMember(Number)
  rating5 = 0;

  @jsonMember(Number)
  difficulty1 = 0;

  @jsonMember(Number)
  difficulty2 = 0;

  @jsonMember(Number)
  difficulty3 = 0;

  @jsonMember(Number)
  difficulty4 = 0;

  @jsonMember(Number)
  difficulty5 = 0;

  @jsonMember(Number)
  learnedSomethingNew = 0;

  @jsonMember(Number)
  didNotLearnSomethingNew = 0;
}

/**
 * Shape of the object returned by the backend
 * warning the user before they perform a protected operation on a challenge.
 *
 * Examples of these operations include approving a challenge and marking a
 * challenge defective.
 *
 * Example warning: "Approving this challenge will redeploy X lab accounts." or
 * "Marking this challenge defective will affect X active events".
 */
export interface ChallengeWarningResponse {
  /**
   * List of warnings, if any, that the user should be aware of
   * before performing the operation on the challenge.
   */
  warnings?: string[];
  /**
   * Token that is needed to make the call to actually perform
   * the operation on the challenge.
   */
  warningToken: string;
}

/**
 * Shape of the request the frontend makes to
 * mark a challenge defective.
 */
export interface MarkChallengeDefectiveRequest {
  challengeId: string;
  warningToken: string;
  defective: boolean;
  optionalComment: string;
}

@jsonObject
export class ChallengeFeedbackTrend {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableDateStringValue)
  date: common.NullableDateString = null;

  @jsonMember(common.NullableNumberValue)
  itemCount: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  avgRating: common.NullableNumber = null;
}

export interface DifficultyLevel {
  key: number;
  defaultScore: number;
  i18nKeyShort: string;
  i18nKeyLong: string;
}

export class ChallengeDifficulty {
  static readonly FUNDAMENTAL: DifficultyLevel = {
    key: 0,
    defaultScore: 80,
    i18nKeyShort: i18nKeys.general.fundamental,
    i18nKeyLong: i18nKeys.challenge.optionDefinitions.difficulty.fundamental,
  };
  static readonly INTERMEDIATE: DifficultyLevel = {
    key: 1,
    defaultScore: 150,
    i18nKeyShort: i18nKeys.general.intermediate,
    i18nKeyLong: i18nKeys.challenge.optionDefinitions.difficulty.intermediate,
  };
  static readonly ADVANCED: DifficultyLevel = {
    key: 2,
    defaultScore: 200,
    i18nKeyShort: i18nKeys.general.advanced,
    i18nKeyLong: i18nKeys.challenge.optionDefinitions.difficulty.advanced,
  };
  static readonly EXPERT: DifficultyLevel = {
    key: 3,
    defaultScore: 250,
    i18nKeyShort: i18nKeys.general.expert,
    i18nKeyLong: i18nKeys.challenge.optionDefinitions.difficulty.expert,
  };

  static readonly values: DifficultyLevel[] = [
    ChallengeDifficulty.FUNDAMENTAL,
    ChallengeDifficulty.INTERMEDIATE,
    ChallengeDifficulty.ADVANCED,
    ChallengeDifficulty.EXPERT,
  ];

  /**
   * Gets the corresponding DifficultyLevel object based on the provided key. If the key is not within the range
   * of existing difficulty levels, INTERMEDIATE is returned as a default.
   *
   * @param key The numeric value of the difficulty level as stored in the database.
   */
  static getByKey(key: number): DifficultyLevel {
    return ChallengeDifficulty.values[key] || ChallengeDifficulty.INTERMEDIATE;
  }
}

export const cloneChallengeWrapper = (cw: ChallengeWrapper) => {
  const challengeWrapper = new ChallengeWrapper();
  challengeWrapper.latest = cloneChallenge(cw.latest as Challenge);
  challengeWrapper.latestApproved = cloneChallenge(cw.latestApproved as Challenge);
  challengeWrapper.globalStatistics = Object.assign(
    new ChallengeGlobalStatistics(),
    cloneGlobalStats(cw.globalStatistics)
  );
  cw.copyGlobalFlagsTo(challengeWrapper);
  challengeWrapper.copyChallengeLevelFlagsToVersions();

  return challengeWrapper;
};

export const cloneChallenge = (c: Challenge) => {
  return cloneDeep(c);
};

export const cloneGlobalStats = (globalStatistics: ChallengeGlobalStatistics) => {
  return cloneDeep(globalStatistics);
};

export const getChallengePointsPossible = (
  scoringSettings: EventScoringSettings,
  challenge: Challenge,
  cd: ChallengeDescriptor,
  useOverride = true
) => {
  const challengeScoreOverride = cd.overrides.score;

  if (useOverride && challengeScoreOverride != null) {
    return challengeScoreOverride;
  }

  const difficulty = cd.overrides.difficulty || challenge.props.difficulty;

  if (difficulty === 0) {
    if (scoringSettings.easyScore != null) {
      return scoringSettings.easyScore;
    }
    return JamConstants.DEFAULT_EASY_CHALLENGE_SCORE;
  }

  if (difficulty === 1) {
    if (scoringSettings.mediumScore != null) {
      return scoringSettings.mediumScore;
    }
    return JamConstants.DEFAULT_MEDIUM_CHALLENGE_SCORE;
  }

  if (scoringSettings.hardScore != null) {
    return scoringSettings.hardScore;
  }

  return JamConstants.DEFAULT_HARD_CHALLENGE_SCORE;
};

@jsonObject
export class TranslatedChallengeWrapper {
  // Necessary to disable following lines to allow the use of TypedJson annotation with these nullable classes
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(Challenge))
  original: common.Nullable<Challenge> = null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(Challenge))
  translated: common.Nullable<Challenge> = null;
}

export const getChallengesDiff = (
  previousChallengeDescriptors: ChallengeDescriptor[],
  updatedChallengeDescriptors: ChallengeDescriptor[]
): DiffChange[] => {
  const changes: DiffChange[] = [];
  const challengeStatus = {
    NOT_INCLUDED_IN_EVENT: 'Not included in event',
    INCLUDED_IN_EVENT: 'Included in event',
    WARMUP: 'Warmup',
    LOCKED: 'Locked',
    DELAYED_DISPLAY: 'Delayed Display',
  };
  const previousChallenges: common.NullableString[] = previousChallengeDescriptors.map((cd) => cd.challengeId);
  const updatedChallenges: common.NullableString[] = updatedChallengeDescriptors.map((cd) => cd.challengeId);

  // handle removed challenges
  previousChallenges
    .filter((challengeId) => !updatedChallenges.includes(challengeId))
    .forEach((challengeId) => {
      changes.push({
        property: `Challenge: ${challengeId}`,
        previousValue: challengeStatus.INCLUDED_IN_EVENT,
        updatedValue: challengeStatus.NOT_INCLUDED_IN_EVENT,
      });
    });

  const getChallengeLabel = (cd: ChallengeDescriptor) => {
    const overrides = cd.overrides;
    const labelParts = [];

    if (overrides.challengeAlwaysOn === true) {
      labelParts.push(challengeStatus.WARMUP);
    }
    if (cd.hidden === true) {
      labelParts.push(challengeStatus.LOCKED);
    }
    if (cd.displayTime) {
      labelParts.push(challengeStatus.DELAYED_DISPLAY);
    }
    return labelParts.join(', ');
  };

  // handle added or changed challenges
  updatedChallengeDescriptors.forEach((cd) => {
    const label = getChallengeLabel(cd);
    if (previousChallenges.includes(cd.challengeId)) {
      const previousCd = previousChallengeDescriptors.find((ocd) => ocd.challengeId === cd.challengeId);
      if (previousCd) {
        const previousLabel = getChallengeLabel(previousCd);

        if (label !== previousLabel && cd.challengeId) {
          changes.push({
            property: `Challenge: ${cd.challengeId}`,
            previousValue: previousLabel,
            updatedValue: label,
          });
        }
      }
    } else if (cd.challengeId) {
      // added challenge
      changes.push({
        property: `Challenge: ${cd.challengeId}`,
        previousValue: challengeStatus.NOT_INCLUDED_IN_EVENT,
        updatedValue: challengeStatus.INCLUDED_IN_EVENT,
      });
    }
  });

  return changes;
};

export interface WithChallenges {
  idAttribute: string; // the name of the ID attribute for this object
  challengeDescriptors: ChallengeDescriptor[];
}

export interface WithChallengesAndScoring extends WithChallenges {
  getScoringSettings(): EventScoringSettings;
}

export class ChallengeWarning {
  isDefective = false;
  supportedRegions = false;
  solvedRecently = false;
}

export const isMissingSupportedRegions = <E extends EventBase>(
  challenge: Challenge,
  event: common.Nullable<E | EventLabSummary> = null
) => {
  // if a challenge is not aws account based, then is doesnt require regions
  if (challenge && challenge.awsAccountBased) {
    // if the challenge has no allowlisted regions, then it is missing supported regions for sure
    if (!challenge.props.regionAllowlist || challenge.props.regionAllowlist.length < 1) {
      return true;
    }

    // if an event is supplied, then compare the supported challenge to the event supported challenges
    if (event) {
      return !doesEventSupportChallenge(event, challenge);
    }
  }

  return false;
};

export enum ChallengeWarnings {
  IS_DEFECTIVE = 'isDefective',
  SUPPORTED_REGIONS = 'supportedRegions',
  SOLVED_RECENTLY = 'solvedRecently',
}

export const ChallengeWarningDictionary = {
  [ChallengeWarnings.IS_DEFECTIVE]: i18nKeys.challenges.challengeSelection.challengeWarnings.defective,
  [ChallengeWarnings.SOLVED_RECENTLY]: i18nKeys.challenges.challengeSelection.challengeWarnings.unsolvedRecently,
  [ChallengeWarnings.SUPPORTED_REGIONS]: i18nKeys.challenges.challengeSelection.challengeWarnings.noSupportedRegion,
};

export const getChallengeWarnings = <E extends EventBase>(
  challenge: Challenge,
  globalStatistics: ChallengeGlobalStatistics,
  event?: E | EventLabSummary
): ChallengeWarning => {
  const warnings: ChallengeWarning = new ChallengeWarning();

  if (challenge && challenge.isDefective) {
    warnings.isDefective = true;
  }
  if (challenge && event && isMissingSupportedRegions(challenge, event)) {
    warnings.supportedRegions = true;
  }
  if (globalStatistics && !globalStatistics.solvedRecently()) {
    warnings.solvedRecently = true;
  }

  return warnings;
};

export enum ChallengeIssueSeverity {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const ChallengeIssueSeverityDescriptions = {
  CRITICAL: '1 - Critical: Participants impacted by broken challenge',
  HIGH: '2 - High: Challenge not usable, but participants not yet impacted',
  MEDIUM: '3 - Medium: Challenge usable, but requires attention',
  LOW: '4 - Low: Usability not immediately impacted',
};

export enum ChallengeIssueStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
}

@jsonObject
export class ChallengeIssueAssignmentItem {
  @jsonMember(common.NullableNumberValue)
  time: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  assignee: common.NullableString = null;
}



@jsonObject
export class CreateChallengeIssueRequest {
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;

  @jsonMember(String)
  description = '';

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(String)
  severity: ChallengeIssueSeverity = ChallengeIssueSeverity.LOW;

  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;
}

@jsonObject
export class ChallengeConfiguration {
  /**
   * List of regions that challenge authors are able to add to their challenge.
   */
  @jsonArrayMember(String)
  supportedLabRegions: string[] = [];

  @jsonArrayMember(String)
  supportedLabRegionIds: string[] = [];

  /**
   * List of supported task validation lambda runtimes.
   *
   * @see https://docs.aws.amazon.com/lambda/latest/dg/lambda-runtimes.html
   */
  @jsonArrayMember(String)
  supportedLambdaRuntimes: string[] = [];

  /**
   * Sample NodeJs lambda validation code.
   */
  @jsonMember(String)
  nodeSampleLambdaCode: common.NullableString = null;

  /**
   * Sample Python lambda validation code.
   */
  @jsonMember(String)
  pythonSampleLambdaCode: common.NullableString = null;

  /**
   * The default lab-key-pair-name
   */
  @jsonMember(String)
  defaultLabKeyPairName = 'KeyName';

  /**
   * The name of the parameter that should be included in CFN templates for SSH Keypair name
   */
  @jsonMember(String)
  keyPairCfnParameterName = 'KeyName';

  /**
   * The name of the output that should be included in CFN templates for SSH Keypair name
   */
  @jsonMember(String)
  keyPairCfnOutputName = 'lab-key-pair';

  /**
   * CSS classes that can be used in challenge content on html attributes.
   */
  @jsonArrayMember(String)
  allowedCssClasses: string[] = [];

  /**
   * HTML elements that are allowed to have CSS classes in challenge content.
   */
  @jsonArrayMember(String)
  allowedCssClassElements: string[] = [];

  /**
   * Map the supported region ids to an array with label/value.
   *
   * @param regionIds
   */
  setSupportedLabRegions(regionIds: string[]) {
    this.supportedLabRegions = [...regionIds];
  }

  /**
   * Filter a supplied list of region ids and only return those that are supported.
   */
  filterSupportedRegions(regionIds: string[]): string[] {
    return regionIds.filter((id) => this.supportedLabRegionIds.includes(id));
  }
}

@jsonObject
export class TimeRangeStatistic {
  @jsonMember(common.NullableNumberValue)
  week: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  month: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  year: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  all: common.NullableNumber = null;
}

@jsonObject
export class DeploymentStatistics {
  @jsonMember(TimeRangeStatistic)
  successfulDeployments: common.Nullable<TimeRangeStatistic> = null;

  @jsonMember(TimeRangeStatistic)
  totalDeployments: common.Nullable<TimeRangeStatistic> = null;

  @jsonMember(TimeRangeStatistic)
  avgStackDeployTime: common.Nullable<TimeRangeStatistic> = null;
  @jsonMember(TimeRangeStatistic)
  avgVendTime: common.Nullable<TimeRangeStatistic> = null;

  @jsonMember(TimeRangeStatistic)
  deploymentSuccessRate: common.Nullable<TimeRangeStatistic> = null;
}

@jsonObject
export class ChallengeDeploymentStatistics {
  @jsonMember(DeploymentStatistics)
  globalStatistics: common.Nullable<DeploymentStatistics> = null;

  @jsonMapMember(String, DeploymentStatistics)
  regionalStatistics: common.Nullable<Map<string, DeploymentStatistics>> = null;

  @jsonMember(Number)
  stability: common.NullableNumber = null;
}

@jsonObject
export class GetChallengeDeploymentStatisticsResponse {
  @jsonMember(ChallengeDeploymentStatistics)
  statistics: common.Nullable<ChallengeDeploymentStatistics> = null;
}

export enum ChallengeReviewableSection {
  SUMMARY = 'SUMMARY',
  LEARNING_OUTCOME = 'LEARNING_OUTCOME',
  SETTINGS = 'SETTINGS',
  TASKS = 'TASKS',
  ASSETS_RESOURCES = 'ASSETS_RESOURCES',
  IAM_POLICY = 'IAM_POLICY',
  CFN_TEMPLATE = 'CFN_TEMPLATE',
  TESTING = 'TESTING',
  NEXT_STEPS = 'NEXT_STEPS',
  WIKI = 'WIKI',
  DEFAULT = 'DEFAULT',
  // kept solely for filtering purposes.
  // Does not point to an actual section
}

@jsonObject
export class ChallengeSectionFeedback {
  @jsonMember(common.NullableStringValue)
  section: common.Nullable<ChallengeReviewableSection> = null;

  @jsonMember(common.NullableStringValue)
  comment: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  status: common.Nullable<ChallengeReviewStatus> = null;

  @jsonMember(common.NullableBooleanValue)
  required: common.NullableBoolean = null;
}

@jsonObject
export class ChallengeReviewFeedback {
  @jsonMember(ChallengeSectionFeedback)
  feedback: ChallengeSectionFeedback[] = [];
}

@jsonObject
export class ChallengeReview {
  @jsonMember(common.NullableNumberValue)
  minorVersion: common.NullableNumber = null;

  @jsonMember(ChallengeReviewFeedback)
  challengeReviewFeedback: common.Nullable<ChallengeReviewFeedback> = null;

  @jsonMember(common.Email)
  reviewer: common.NullableEmail = null;

  @jsonMember(common.NullableStringValue)
  reviewedOn: common.NullableNumber = null;

  @jsonMember(common.NullableStringValue)
  status: common.Nullable<ChallengeStatus> = null;

  @jsonMember(common.NullableStringValue)
  reviewVersion: common.NullableString = null;
}

export class ChallengeUtils {
  public static isChallengeAdmin(user: User | null): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return user != null && (user.isChallengeAdmin || user.isSuperAdmin);
  }

  public static isReviewable(item: Challenge | ChallengeReview | undefined): boolean {
    return (
      item != null && (item.status === ChallengeStatus.IN_REVIEW || item.status === ChallengeStatus.READY_FOR_REVIEW)
    );
  }

  public static isComplete(review: ChallengeReview | undefined): boolean {
    if (!review || !review.challengeReviewFeedback || review.challengeReviewFeedback.feedback.length === 0) {
      return false;
    }
    return (review.challengeReviewFeedback?.feedback || [])
      .filter((item) => item.required === true)
      .map((item) => item.status === ChallengeReviewStatus.APPROVED || item.status === ChallengeReviewStatus.NEEDS_WORK)
      .reduce((prev, next) => prev && next, true);
  }

  public static hasReviewer(challenge: Challenge | undefined, user: User | null): boolean {
    const email = user?.email || '';
    return challenge != null && email != null && challenge.props?.barRaiser?.emailAddress === email;
  }

  public static needsReview(challenge: Challenge | undefined, user: User | null): boolean {
    return (
      user != null &&
      challenge != null &&
      ChallengeUtils.isBarRaiser(challenge, user) &&
      challenge.barRaiserReviewStatus === ChallengeReviewStatus.READY_FOR_REVIEW
    );
  }

  public static isBeingReviewed(challenge: Challenge | undefined, user: User | null): boolean {
    return (
      challenge != null &&
      user != null &&
      ChallengeUtils.isBarRaiser(challenge, user) &&
      challenge.barRaiserReviewStatus === ChallengeReviewStatus.IN_REVIEW
    );
  }

  public static isBarRaiser(challenge: Challenge | undefined, user: User | null): boolean {
    return user != null && challenge != null && challenge.props?.barRaiser?.emailAddress === user.email;
  }

  public static isOwner(challenge: Challenge | undefined, user: User | null): boolean {
    return user != null && challenge != null && challenge.props.owner === user.email;
  }

  public static isMaintainer(challenge: Challenge | undefined, user: User | null): boolean {
    return (
      user != null &&
      challenge != null &&
      challenge.props.maintainers != null &&
      challenge.props.maintainers.includes(user.email)
    );
  }

  public static isOwnerOrMaintainer(challenge: Challenge | undefined, user: User | null): boolean {
    return ChallengeUtils.isOwner(challenge, user) || ChallengeUtils.isMaintainer(challenge, user);
  }

  public static isCollaborator(challenge: Challenge | undefined, user: User | null): boolean {
    return ChallengeUtils.isChallengeAdmin(user) || ChallengeUtils.isOwnerOrMaintainer(challenge, user);
  }

  public static isLastEditor(challenge: Challenge | undefined, user: User | null): boolean {
    return user != null && challenge != null && user.email === challenge.props.lastEditedBy;
  }

  /**
   * @deprecated Use ChallengeUtils#isBarRaiser() instead
   */
  public static isReviewer(challenge: Challenge | undefined, user: User | null): boolean {
    return user != null && challenge != null && user.email === challenge.props.reviewer;
  }

  public static isCastleDefense(challenge: Challenge | undefined): boolean {
    return challenge != null && challenge.props.isCastleDefense();
  }

  public static isGameDay(challenge: Challenge | undefined): boolean | null {
    return challenge != null && challenge.props.isGameDay();
  }

  public static isIndividualLearningType(challenge: Challenge | undefined): boolean {
    return !!challenge && challenge.props.learningType === ChallengeLearningType.INDIVIDUAL;
  }

  public static removeTag(challenge: Challenge | undefined, tag: string) {
    if (challenge != null) {
      challenge.props.tags = challenge.props.tags.filter((t) => t !== tag);
    }
  }

  public static addTag(challenge: Challenge | undefined, tag: string) {
    ChallengeUtils.removeTag(challenge, tag);
    if (challenge != null) {
      challenge.props.tags = [...challenge.props.tags, kebabCase(tag)];
    }
  }

  public static needsReviewer(challenge: Challenge | undefined, user: User | null): boolean {
    return (
      challenge != null &&
      user != null &&
      ChallengeUtils.isReviewable(challenge) &&
      user.isBarRaiser &&
      challenge.barRaiserReviewStatus === ChallengeReviewStatus.READY_FOR_REVIEW &&
      challenge.props?.barRaiser == null
    );
  }

  public static getSectionFeedback(
    feedback: ChallengeSectionFeedback[] | undefined,
    section: ChallengeReviewableSection
  ) {
    if (!feedback || feedback.length === 0) {
      return undefined;
    }
    const challengeSectionFeedback = feedback.filter((item) => item.section === section);
    return challengeSectionFeedback ? challengeSectionFeedback[0] : undefined;
  }

  static isCompleteSectionFeedback(sectionFeedback: common.Nullable<ChallengeSectionFeedback> | undefined): boolean {
    if (!sectionFeedback) {
      return false;
    }
    return (
      sectionFeedback?.comment !== null &&
      (sectionFeedback?.status === ChallengeReviewStatus.APPROVED ||
        sectionFeedback?.status === ChallengeReviewStatus.NEEDS_WORK)
    );
  }

  static isReviewableByUser(challenge: Challenge, user: User) {
    return (
      challenge != null &&
      user != null &&
      ChallengeUtils.isReviewable(challenge) &&
      user.isBarRaiser &&
      challenge.barRaiserReviewStatus === ChallengeReviewStatus.READY_FOR_REVIEW
    );
  }
}

export class DeploymentStatisticListItem {
  region: common.NullableString = null;
  successfulLabs: common.NullableNumber = null;
  totalLabs: common.NullableNumber = null;
  precentLabsSuccessful: common.NullableNumber = null;
  stackDeployTime: common.NullableNumber = null;
  labVendTime: common.NullableNumber = null;

  constructor(
    region: common.NullableString,
    successfulLabs: common.NullableNumber,
    totalLabs: common.NullableNumber,
    percentLabsSuccessful: common.NullableNumber,
    stackDeployTime: common.NullableNumber,
    labVendTime: common.NullableNumber
  ) {
    this.region = region;
    this.successfulLabs = successfulLabs;
    this.totalLabs = totalLabs;
    this.precentLabsSuccessful = percentLabsSuccessful;
    this.stackDeployTime = stackDeployTime;
    this.labVendTime = labVendTime;
  }
}

export interface TemplateScannerResponse {
  readonly result: string;
}

export interface TemplateScannerRequest {
  readonly challengeId?: string;
  readonly cfnTemplate?: string;
  readonly iamPolicy?: string;
}

export interface IamPolicyValidationResponse {
  findings: ValidationFindings[];
}

export interface ValidationFindings {
  finding: string;
  learnMoreLink: string;
  issueCode: string;
  findingType: string;
}

@jsonObject
export class FacilitatorChallengeIssueResponse {

  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;
  @jsonMember(String)
  severity: ChallengeIssueSeverity = ChallengeIssueSeverity.LOW;
  @jsonMember(String)
  status: ChallengeIssueStatus = ChallengeIssueStatus.OPEN;

  static compareSeverity(
    sev1: ChallengeIssueSeverity,
    sev2: ChallengeIssueSeverity
  ): number {
    if (sev1 === sev2) {
      return 0;
    }
    if (sev1 === ChallengeIssueSeverity.CRITICAL) {
      return -1;
    }
    if (sev1 === ChallengeIssueSeverity.HIGH) {
      if (sev2 === ChallengeIssueSeverity.CRITICAL) {
        return 1;
      }
      return -1;
    }
    if (sev2 === ChallengeIssueSeverity.LOW) {
      return -1;
    }
    return 1;
  }

  static compareIssue(
    issue1: FacilitatorChallengeIssueResponse,
    issue2: FacilitatorChallengeIssueResponse
  ): number {
    return FacilitatorChallengeIssueResponse.compareSeverity(
      issue1.severity,
      issue2.severity
    );
  }
}
export enum ChallengeAttributes {
  TITLE = 'title',
  CATEGORY = 'category',
  DESCRIPTION = 'description',
  TASK = 'task',
  TASK_TITLE = 'task-title',
  TASK_CONTENT = 'task-content',
  CLUE = 'clue',
  CLUE_TITLE = 'clue-title',
  CLUE_DESCRIPTION = 'clue-description',
  LEARNING_OUTCOME = 'learning-outcome',
  LEARNING_OUTCOME_SUMMARY = 'learning-outcome-summary',
  LEARNING_OUTCOME_CONTENT = 'learning-outcome-content',
}
export const dividerStart = (attribute: string) => {
  return `<!-- ${attribute} -->\n`;
};

export const dividerEnd = (attribute: string) => {
  return `<!-- /${attribute} -->\n`;
};
export const formattedData = (sectionName: string, sectionData: string | undefined | null) => {
  return `${dividerStart(sectionName)} ${sectionData} ${dividerEnd(sectionName)}`;
};

export type ChallengeCreateFields = {
  title: string,
  body: string
}