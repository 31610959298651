import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, ColumnLayout, Container, Grid } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import ChallengeNotCompleteModal from './Modals/ChallengeNotCompleteModal';
import ChallengeSolvedModal from './Modals/ChallengeSolvedModal/ChallengeSolvedModal';
import { JamTask, JamTaskStatus } from '@/src/types/JamChallengeDetails';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useHistory, useParams } from 'react-router-dom';
import { JAM_CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { preProdLogger } from '@/src/utils/log.utils';
import { sum } from 'lodash';

interface TaskPointContainerProps {
  task: JamTask | undefined;
}

const TaskPointContainer: React.FC<TaskPointContainerProps> = ({ task }) => {
  const { t } = useTranslation();
  const [taskStatus, setTaskStatus] = useState<JamTaskStatus>();
  const { eventName, eventSlug } = useJamEventDetails();
  const params: { 0: string; 1: string } = useParams();
  const { loadJamChallengeProgressData, jamChallengeId } = useJamChallengeDetails();
  const [enableModal, setModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (task?.status === JamTaskStatus.COMPLETE) {
      setTaskStatus(JamTaskStatus.COMPLETE);
    }
  }, [task]);

  const handleSolvedModalClose = useCallback(() => {
    setModal(false);
    history.push(JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventSlug, params[1]]));
  }, [history, eventSlug, params]);

  const handleProgressCheck = useCallback(async () => {
    try {
      const response = await loadJamChallengeProgressData({ id: eventName, challengeId: jamChallengeId });
      const taskData = response.taskProgress.find((taskItem) => taskItem.taskId === task?.id);
      if (taskData) {
        setTaskStatus(taskData.completed ? JamTaskStatus.COMPLETE : JamTaskStatus.INCOMPLETE);
      }
      setModal(true);
    } catch (err) {
      preProdLogger('Progress Check failed', err);
    }
  }, [loadJamChallengeProgressData, eventName, jamChallengeId, task]);

  const TotalPenalty = React.useMemo(
    () =>
      task?.clues && task.clues.length > 0
        ? sum(task?.clues.filter((clue) => clue.used).map((clue) => clue.cluePenalty))
        : 0,
    [task?.clues]
  );

  const AvailablePoints = React.useMemo(() => {
    const Points = (task?.pointsPossible ?? 0) - (task?.pointsEarned ?? 0);
    return !!Points ? Points - TotalPenalty : 0;
  }, [task?.clues, TotalPenalty]);

  return (
    <Container variant="default" className="dark-bg">
      <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
        <ColumnLayout columns={3} variant="text-grid">
          <Box variant="div">
            <Box>{t(i18nKeys.myJams.challenges.details.task.possiblePoints)}</Box>
            <Box variant="h4" className="global-value">
              {task?.pointsPossible}
            </Box>
          </Box>
          <Box variant="div">
            <Box>{t(i18nKeys.myJams.challenges.details.task.cluePenalty)}</Box>
            <Box variant="h4" className="global-value">
              {TotalPenalty}
            </Box>
          </Box>
          <Box variant="div">
            <Box>{t(i18nKeys.myJams.challenges.details.task.PointsAvailable)}</Box>
            <Box variant="h4" className="global-value">
              {AvailablePoints}
            </Box>
          </Box>
        </ColumnLayout>
        {task?.status !== JamTaskStatus.COMPLETE && task?.validatedByLambda && (
          <Box variant="div" margin={{ top: 'xs' }}>
            <Button variant="primary" onClick={() => void handleProgressCheck()}>
              {t(i18nKeys.myJams.challenges.details.task.buttons.checkMyProgress)}
            </Button>
          </Box>
        )}
        {enableModal &&
          (taskStatus && taskStatus === JamTaskStatus.COMPLETE ? (
            <ChallengeSolvedModal value={enableModal} onClose={handleSolvedModalClose} />
          ) : (
            <ChallengeNotCompleteModal value={enableModal} setValue={setModal} />
          ))}
      </Grid>
    </Container>
  );
};

export default TaskPointContainer;
