import {
  Badge,
  Button,
  ColumnLayout,
  Container,
  Header,
  Icon,
  Link,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { useApi } from '../../../store/api.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useEvents } from '../../../store/events.context';
import { ChallengeDescriptor, ChallengeListItem } from '../../../types/Challenge';
import { Nullable } from '../../../types/common';
import { Event } from '../../../types/Event';
import { formatLabProviderLabel } from '../../../types/LabProvider';
import { i18nKeys } from '../../../utils/i18n.utils';
import DifficultyIndicator from '../../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { getDuration } from '../../../utils/time.utils';
import CircleRating from '../CircleRating';
import { KeyValue } from '../KeyValue';
import StarRating from '../StarRating';
import ChallengeCardAdvanced from './ChallengeCardAdvanced';
import { HorizontalRule } from '../HorizontalRule';
import PerChallengeBackupChallenges from './PerChallengeBackupChallenges';
import { Campaign } from '../../../types/Campaign';
import { useCampaigns } from '../../../store/campaigns.context';
import { useUser } from '@/src/store/user.context';
import { useChallenges } from '@/src/store/challenge.context';

interface ChallengeCardProps {
  challenge: ChallengeListItem;
  index: number;
  getChallengePointsPossible?: (challenge: Nullable<ChallengeListItem>) => number;
  getDefaultChallengePointsPossible?: (challenge: ChallengeListItem) => number;
  handleRemoveChallengeAction?: (challengeId: string) => void;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
  advancedMode?: boolean;
  target: Event | Campaign;
  backupChallenges?: boolean;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  challenge,
  index,
  getChallengePointsPossible,
  getDefaultChallengePointsPossible,
  handleRemoveChallengeAction,
  toggleChallengeInfo,
  advancedMode = false,
  target,
  backupChallenges = false,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { handleUpdateEditEvent, editMode } = useEditEvent();
  const { event } = useEvents();
  const { campaign } = useCampaigns();
  const { eventsApi } = useApi();
  const { updateChallengeDescriptor, getChallengeDescriptor, wrapperMapInitalized } = useChallenges();
  const [challengeDescriptor, setChallengeDescriptor] = useState<Nullable<ChallengeDescriptor>>(null);

  const isEvent = target instanceof Event;

  const isAdmin = user && target?.isAdmin(user);

  const handleDownloadImportedCSV = () => {
    if (event && challenge.challengeId) {
      void eventsApi.downloadTeamChallengeProperties(event.name, challenge?.challengeId);
    }
  };

  useEffect(() => {
    if (challenge && challenge.challengeId) {
      setChallengeDescriptor(getChallengeDescriptor(challenge));
    }
  }, [wrapperMapInitalized]);

  const canEditWithAttribute = (attribute: string) => {
    if (user) {
      return (
        editMode &&
        (isAdmin || user.isSuperAdmin || user.isEventAdmin || (target as Event)?.hasOwnerPermission(user?.email)) &&
        target.canEditAttribute(attribute, user)
      );
    } else {
      return false;
    }
  };

  const handleEditEvent = (newChallengeDescriptor: ChallengeDescriptor) => {
    updateChallengeDescriptor(newChallengeDescriptor);
    const indexOfChallengeDescriptor = target.challengeDescriptors.findIndex(
      (cd: ChallengeDescriptor) => cd.challengeId === newChallengeDescriptor.challengeId
    );
    const newChallengeDescriptors = _.cloneDeep(target.challengeDescriptors);
    newChallengeDescriptors[indexOfChallengeDescriptor] = newChallengeDescriptor;
    handleUpdateEditEvent(EditEventActions.CHALLENGE_DESCRIPTORS, newChallengeDescriptors);
  };

  const toggleCodeWhispererDisabled = () => {
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.codeWhispererDisabled = !challengeDescriptor?.codeWhispererDisabled;
      handleEditEvent(modifiedDescriptor);
    }
  };

  const canRemoveChallenge =
    challenge && challenge.challengeId !== null && editMode && !backupChallenges && handleRemoveChallengeAction;

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <div style={{ lineHeight: '30px' }}>
              <SpaceBetween direction="horizontal" size="s">
                <span>
                  {t(i18nKeys.challenges.browseChallenges.headers.rating)}:
                  <StarRating
                    value={challenge?.globalStatistics?.rating}
                    valueCount={challenge?.globalStatistics?.ratingCount}
                  />
                </span>
                <span>
                  {t(i18nKeys.challenges.browseChallenges.headers.difficulty)}:
                  <CircleRating value={challenge.globalStatistics.difficultyRating} />
                </span>
                {canRemoveChallenge && (
                  <div className="left-border">
                    <Button
                      iconName="status-negative"
                      variant="link"
                      onClick={() => handleRemoveChallengeAction(challenge.challengeId as string)}>
                      {t(i18nKeys.general.remove)}
                    </Button>
                  </div>
                )}
              </SpaceBetween>
            </div>
          }>
          {`${index + 1}. ${challenge.props.title}`}{' '}
          <span onClick={() => toggleChallengeInfo(challenge)}>
            <Link variant="info">{t(i18nKeys.general.info)}</Link>
          </span>
        </Header>
      }>
      <ColumnLayout columns={4}>
        <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.browseChallenges.headers.difficulty)}>
          <DifficultyIndicator challenge={challenge} />
        </KeyValue>
        <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.browseChallenges.headers.lastUsed)}>
          {challenge.globalStatistics.lastUsed ? (
            <TimeAgo datetime={challenge.globalStatistics?.lastUsed} />
          ) : (
            t(i18nKeys.general.nA)
          )}
        </KeyValue>
        <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.browseChallenges.headers.type)}>
          <div>{challenge.props.jamType}</div>
        </KeyValue>
        <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.browseChallenges.headers.poweredBy)}>
          <div>{formatLabProviderLabel(challenge.props.defaultLabProvider)}</div>
        </KeyValue>
        {!backupChallenges && getChallengePointsPossible && (
          <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.browseChallenges.headers.points)}>
            <div>{getChallengePointsPossible(challenge)}</div>
          </KeyValue>
        )}
        <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.browseChallenges.headers.solvedRecently)}>
          <div>
            {!challenge.globalStatistics.solvedRecently() ? (
              <span>
                <Icon name="status-warning" variant="warning" /> {t(i18nKeys.general.no)}
              </span>
            ) : (
              <span>{t(i18nKeys.general.yes)}</span>
            )}
          </div>
        </KeyValue>
        <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.challengeDetails.titles.category)}>
          <div>{challenge.props.category}</div>
        </KeyValue>
        <div>
          {challenge.hasCfnTemplate && (
            <Link
              variant="info"
              href={`/labs?${isEvent ? `event-name=${event?.name}` : `campaign-id=${campaign?.id}`}challenge-id=${
                challenge?.id
              }}`}>
              {t(i18nKeys.challenges.browseChallenges.headers.viewLabs)}
            </Link>
          )}
          {advancedMode && (
            <div onClick={handleDownloadImportedCSV}>
              <Link variant="info">{t(i18nKeys.challenges.challengeSelection.labels.downloadImportedCSV)}</Link>
            </div>
          )}
        </div>
        {advancedMode && (
          <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.challengeSelection.labels.avgSolveTime)}>
            {challenge?.globalStatistics.solveTimes.trimmedAvgSeconds
              ? getDuration(challenge?.globalStatistics.solveTimes.trimmedAvgSeconds)
              : t(i18nKeys.general.nA)}
          </KeyValue>
        )}
        {advancedMode && (
          <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.challengeSelection.labels.lastSolved)}>
            {challenge?.globalStatistics.lastSolved ? (
              <TimeAgo datetime={challenge?.globalStatistics.lastSolved} />
            ) : (
              t(i18nKeys.general.nA)
            )}
          </KeyValue>
        )}
        {advancedMode && (
          <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.challengeSelection.labels.awsServices)}>
            {challenge?.props.awsServices.map((awsService, i) => {
              if (i + 1 !== challenge?.props.awsServices.length) {
                return `${awsService}, `;
              } else {
                return `${awsService}`;
              }
            })}
          </KeyValue>
        )}
        {advancedMode && (
          <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.challengeSelection.labels.tags)}>
            <SpaceBetween direction="horizontal" size="xs">
              {challenge.props.tags.map((tag, i) => {
                return <Badge key={`tag-${i}`}>{tag}</Badge>;
              })}
            </SpaceBetween>
          </KeyValue>
        )}
        {advancedMode && (
          <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.challengeSelection.labels.avgDeployTime)}>
            {challenge?.hasStack
              ? getDuration(challenge?.avgStackDeployTime)
              : t(i18nKeys.challenges.challengeSelection.labels.hasNoStack)}
          </KeyValue>
        )}
        {advancedMode && (
          <KeyValue secondaryLabelColor label={t(i18nKeys.challenges.challengeDetails.titles.warmup)}>
            {challenge?.props.challengeAlwaysOn ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}
          </KeyValue>
        )}
        {target instanceof Event && (
          <Toggle
            disabled={!canEditWithAttribute('codeWhispererDisabled')}
            checked={challengeDescriptor?.codeWhispererDisabled ?? false}
            onChange={() => toggleCodeWhispererDisabled()}>
            {t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.disableCodeWhisperer)}
          </Toggle>
        )}
      </ColumnLayout>
      {advancedMode && !backupChallenges && getDefaultChallengePointsPossible && (
        <ChallengeCardAdvanced
          challenge={challenge}
          getDefaultChallengePointsPossible={getDefaultChallengePointsPossible}
          target={target}
          canEditWithAttribute={canEditWithAttribute}
          challengeDescriptor={challengeDescriptor}
          handleEditEvent={handleEditEvent}
        />
      )}
      {target instanceof Event && backupChallenges && challenge.challengeId && (
        <React.Fragment>
          <HorizontalRule />
          <PerChallengeBackupChallenges target={target} challengeId={challenge.challengeId} />
        </React.Fragment>
      )}
    </Container>
  );
};
export default ChallengeCard;
