/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  Box,
  Button,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Textarea,
  TextContent,
} from '@amzn/awsui-components-react';
import { BaseChangeDetail } from '@amzn/awsui-components-react/polaris/input/interfaces';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  ChallengePropAction,
  CLUE_DESCRIPTION_MAX_LENGTH,
  CLUE_DESCRIPTION_MIN_LENGTH,
  CLUE_TITLE_MAX_LENGTH,
  CLUE_TITLE_MIN_LENGTH,
  ClueFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Clue } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { KeyValue } from '../../../common/KeyValue';
import { ClueViewOption } from '../../ChallengeModel';

interface ChallengeClueDetailProps {
  clue: Clue;
  clueNumber: number;
  onRemove: (clueNumber: number) => void;
  taskNumber: number;
}

const ChallengeClueDetail: React.FC<ChallengeClueDetailProps> = ({ clue, clueNumber, onRemove, taskNumber }) => {
  const { t } = useTranslation();
  const { editMode, handleUpdateChallengeClue, challengeClueValidator } = useCreateChallenge();
  const [clueView] = useState(ClueViewOption.EDIT);

  const fieldPrefix = i18nKeys.challenges.subSections.tasks.subSections.fields.clues;
  const [titleErrorText, setTitleErrorText] = useState('');
  const [descriptionErrorText, setDescriptionErrorText] = useState('');

  const clueValidator = challengeClueValidator(
    clue,
    new Map<ClueFields, (error: string) => void>([
      [ClueFields.TITLE, (error: string) => setTitleErrorText(error)],
      [ClueFields.DESCRIPTION, (error: string) => setDescriptionErrorText(error)],
    ])
  );

  const handleInputChange = (type: ClueFields, detail: BaseChangeDetail) => {
    const { value } = detail;
    switch (type) {
      case ClueFields.TITLE:
        handleUpdateChallengeClue(ChallengePropAction.CLUE_TITLE, taskNumber, clueNumber, value);
        break;
      case ClueFields.DESCRIPTION:
        handleUpdateChallengeClue(ChallengePropAction.CLUE_DESCRIPTION, taskNumber, clueNumber, value);
        break;
      default:
        throw new Error('Not supported!');
    }
  };

  return (
    <React.Fragment>
      {!editMode ? (
        <React.Fragment>
          <Header variant="h2">
            {t(i18nKeys.challenges.challengeDetails.titles.clueNumber, { count: Number(clueNumber) })}
          </Header>
          <KeyValue className="primary-text" label={clue.title}>
            {clue.description && <ReactMarkdown rehypePlugins={[rehypeRaw]}>{clue.description}</ReactMarkdown>}
            {!clue.description && t(i18nKeys.challenges.challengeDetails.text.challengeClueEmptyState)}
          </KeyValue>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TextContent>
            <p>
              {t(i18nKeys.challenges.subSections.tasks.subSections.fields.clues.title, { count: Number(clueNumber) })}
            </p>
          </TextContent>
          <Box padding={'l'}>
            <Box float={'right'}>
              {clueNumber !== 1 && <Button iconName="close" variant="icon" onClick={() => onRemove(clueNumber)} />}
            </Box>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.title)}>
              <FormField
                errorText={titleErrorText}
                constraintText={t(fieldPrefix.fields.titleConstraint, {
                  CLUE_TITLE_MIN_LENGTH,
                  CLUE_TITLE_MAX_LENGTH,
                })}>
                <Input
                  onBlur={() => clueValidator.isValidField(ClueFields.TITLE)}
                  onChange={({ detail }) => handleInputChange(ClueFields.TITLE, detail)}
                  value={clue.title || ''}
                />
              </FormField>
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.description)}>
              <FormField
                errorText={descriptionErrorText}
                constraintText={t(fieldPrefix.fields.descriptionConstraint, {
                  CLUE_DESCRIPTION_MIN_LENGTH,
                  CLUE_DESCRIPTION_MAX_LENGTH,
                })}>
                {clueView === ClueViewOption.EDIT && (
                  <Textarea
                    onBlur={() => clueValidator.isValidField(ClueFields.DESCRIPTION)}
                    onChange={({ detail }) => handleInputChange(ClueFields.DESCRIPTION, detail)}
                    value={clue.description || ''}
                  />
                )}
                {clueView === ClueViewOption.BOTH && (
                  <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
                    <Textarea
                      onBlur={() => clueValidator.isValidField(ClueFields.DESCRIPTION)}
                      onChange={({ detail }) => handleInputChange(ClueFields.DESCRIPTION, detail)}
                      value={clue.description || ''}
                    />
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{clue.description || ''}</ReactMarkdown>
                  </Grid>
                )}
                {clueView === ClueViewOption.PREVIEW && (
                  <SpaceBetween size="m" direction="horizontal">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{clue.description || ''}</ReactMarkdown>
                  </SpaceBetween>
                )}
              </FormField>
            </KeyValue>
            {/* <Box>
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => setClueView(ClueViewOption.EDIT)}>
                  <FontAwesomeIcon icon={'code'} />
                </Button>
                <Button onClick={() => setClueView(ClueViewOption.BOTH)}>
                  <SpaceBetween direction="horizontal" size="xs">
                    <FontAwesomeIcon icon={'code'} />
                    <FontAwesomeIcon icon={'image'} />
                  </SpaceBetween>
                </Button>
                <Button onClick={() => setClueView(ClueViewOption.PREVIEW)}>
                  <FontAwesomeIcon icon={'image'} />
                </Button>
              </SpaceBetween>
            </Box> */}
          </Box>
          <HorizontalRule />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ChallengeClueDetail;
