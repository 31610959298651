import { Grid, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import { useState } from 'react';
import JamCodeEditor from '../../challenges/challengesCommon/JamCodeEditor';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './MarkDownPreviewEditor.scss';

const iconImg = (): JSX.Element => (
  <svg
    style={{ display: 'flex' }}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 16 16"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.41421 8.00008L5.20711 5.20718L3.79289 3.79297L0.292893 7.29297C-0.0976311 7.68349 -0.0976311 8.31666 0.292893 8.70718L3.79289 12.2072L5.20711 10.793L2.41421 8.00008ZM5.54611 11.3179L8.60611 3.91787L10.4543 4.68213L7.39432 12.0821L5.54611 11.3179ZM15.7071 7.29297L12.2071 3.79297L10.7929 5.20718L13.5858 8.00008L10.7929 10.793L12.2071 12.2072L15.7071 8.70718C16.0976 8.31666 16.0976 7.68349 15.7071 7.29297Z"
      fill="#0972D3"
    />
  </svg>
);

interface IMarkdownPreviewEditor {
  text?: string;
  onTextChanged: (text: string) => void;
  editorContentHeight?: number;
  activeTabIndex?: number;
}

const MarkDownPreviewEditor: React.FC<IMarkdownPreviewEditor> = ({
  text,
  onTextChanged,
  editorContentHeight,
  activeTabIndex,
}) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex || 1);

  return (
    <div className="markdonw-preview-editor">
      <SpaceBetween size="s">
        <div className="main-container">
          <div className="button-container">
            <SpaceBetween direction="horizontal" size="xs">
              <button
                className={`custom-btn ${activeTab === 1 ? 'active' : ''}`}
                role="button"
                onClick={() => setActiveTab(1)}>
                <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                  {iconImg()} Markdown
                </SpaceBetween>
              </button>
              <button
                className={`custom-btn ${activeTab === 2 ? 'active' : ''}`}
                role="button"
                onClick={() => setActiveTab(2)}>
                <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                  {iconImg()}
                  <img src="/assets/eye-blue-icon.png" alt="" />
                  Markdown / Preview
                </SpaceBetween>
              </button>
              <button
                className={`custom-btn ${activeTab === 3 ? 'active' : ''}`}
                role="button"
                onClick={() => setActiveTab(3)}>
                <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                  <img src="/assets/eye-blue-icon.png" alt="" />
                  Preview
                </SpaceBetween>
              </button>
            </SpaceBetween>
          </div>
          <div style={{ padding: '2rem' }}>
            {activeTab === 1 && (
              <Grid gridDefinition={[{ colspan: 12 }]}>
                <JamCodeEditor
                  language="markdown"
                  value={text || ''}
                  setValue={(e) => {
                    onTextChanged(e);
                  }}
                  editorContentHeight={editorContentHeight}
                />
              </Grid>
            )}
            {activeTab === 2 && (
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <JamCodeEditor
                  language="markdown"
                  value={text || ''}
                  setValue={(e) => {
                    onTextChanged(e);
                  }}
                  editorContentHeight={editorContentHeight}
                />
                <TextContent>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text || ''}</ReactMarkdown>
                </TextContent>
              </Grid>
            )}
            {activeTab === 3 && (
              <Grid gridDefinition={[{ colspan: 12 }]}>
                <TextContent>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text || ''}</ReactMarkdown>
                </TextContent>
              </Grid>
            )}
          </div>
        </div>
      </SpaceBetween>
    </div>
  );
};

export default MarkDownPreviewEditor;
