import {AwsRegions} from './aws-regions';

// noinspection JSUnusedGlobalSymbols
export const SupportedLabRegions = [
  AwsRegions.US_EAST_1,
  AwsRegions.US_EAST_2,
  AwsRegions.US_WEST_1,
  AwsRegions.US_WEST_2,
  AwsRegions.AP_SOUTH_1,
  AwsRegions.AP_NORTHEAST_1,
  AwsRegions.AP_NORTHEAST_2,
  AwsRegions.AP_NORTHEAST_3,
  AwsRegions.AP_SOUTHEAST_1,
  AwsRegions.AP_SOUTHEAST_2,
  AwsRegions.CA_CENTRAL_1,
  AwsRegions.EU_CENTRAL_1,
  AwsRegions.EU_WEST_1,
  AwsRegions.EU_WEST_2,
  AwsRegions.EU_WEST_3,
  AwsRegions.SA_EAST_1,
];
