import {
  Box,
  Checkbox,
  Container,
  DatePicker,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  Link,
  Select,
  SpaceBetween,
  Tiles,
  TimeInput,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Moment } from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HOURS_PER_MONTH, MeridiemOptions } from '../../../../constants/DateTimeConstants';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { NullableString } from '../../../../types/common';
import { Event, MAX_DURATION_NON_ADMIN } from '../../../../types/Event';
import {
  convert24To12HourTime,
  getBrowserTimezoneName,
  getTimeForPolarisTimePicker,
  getTimeInBrowserLocalTime,
  getTimezonesWithUTCOffsetAsOptionList,
  polarisDateTimeToMoment,
  extractMeridiem,
} from '../../../../utils/event-time.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { getLanguageCodeSafe } from '../../../../utils/locale.utils';
import { containsOnlyNumbers } from '../../../../utils/string.utils';
import { DateTime } from '../../../common/DateTime';
import { KeyValue } from '../../../common/KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import './EventTimes.scss';
import { useUser } from '@/src/store/user.context';
import { isDateNotInThePast } from '@/src/utils/common.utils';
import { useFlashbars } from '@/src/store/flashbar.context';
import { EventFields, eventTimesValidator } from '@/src/utils/event.validation.utils';
import { Validator } from '@/src/utils/validation.utils';
import { DateTimeKeys, TimezoneFormat } from '@/src/components/common/CommonModel';
import {
  EVENT_DURATION_MAX_HOURS,
  EVENT_DURATION_MAX_LIMIT,
  EVENT_DURATION_MAX_MINUTES,
  MAX_EVENT_DURATION,
  NUMBER_REGEX,
  TIME_DURATION_REGEX,
} from '@/src/constants/validation-constants';
import _ from 'lodash';
import moment from 'moment';
import { LoggingService } from '@/src/utils/logging-service.utils';
import { IEventTemplate } from '@/src/types/EventTemplate';

interface EventTimesProps {
  target: Event | undefined;
  eventTemplate?: IEventTemplate | undefined;
  handleCancelOrDeleteEvent?: () => void;
  validationHandler?: (validateSection: () => Promise<boolean>) => void;
  eventTitleValidation?: {
    isValidSection: (setErrors?: boolean | undefined) => boolean;
    isValidField: (field: EventFields, setError?: boolean | undefined) => boolean;
  };
}

const VALID_TIME_REGEX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
const isValidStartTime = (startTime: string) => {
  return VALID_TIME_REGEX.test(startTime);
};

const EventTimes: React.FC<EventTimesProps> = ({
  target,
  eventTemplate,
  handleCancelOrDeleteEvent,
  validationHandler,
  eventTitleValidation,
}) => {
  const { i18n, t } = useTranslation();
  const { user } = useUser();
  const {
    editMode,
    newEventMode,
    handleUpdateEditEvent,
    newDuration,
    isGuestUserAndEditModeEnabled,
    setNewDuration,
    selectedSpecificDuration,
    setSelectedSpecificDuration,
    getEventDurationInfo,
  } = useEditEvent();
  const [timezoneOptions, setTimezoneOptions] = useState<OptionDefinition[]>([]);
  const [selectedTimezoneOption, setSelectedTimezoneOption] = useState<OptionDefinition | null>(null);
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [durationError, setDurationError] = useState('');
  const [selectedMeridiem, setSelectedMeridiem] = useState<OptionDefinition>(MeridiemOptions.AM);
  const [autoUnlockChallenges, setAutoUnlockChallenges] = useState(false);
  const [autoUnlockChallengesMinutes, setAutoUnlockChallengesMinutes] = useState('0');
  const [minParticipants, setMinParticipants] = useState<NullableString>(`${(target as Event)?.minExpectedParticipants}`);
  const [participants, setParticipants] = useState<NullableString>(`${(target as Event)?.maxExpectedParticipants}`);
  const { clearFlashbars } = useFlashbars();

  const canEdit = newEventMode || editMode;
  const languageCode: string = getLanguageCodeSafe(i18n.language);
  const isDisabled = user?.isOnlyBasicUser && !target?.hasOwnerPermission(user?.email);
  const isInvalid = target?.isAfterStart && isValidStartTime(startTime);

  const editDisabled = !!isGuestUserAndEditModeEnabled && isValidStartTime(startTime);

  const durationItems: { [key: number]: { label: string; value: string; disabled: boolean } } = {
    4: {
      label: t(i18nKeys.events.eventDetails.labels.durationItems.fourHours),
      value: '4',
      disabled: editDisabled,
    },
    8: {
      label: t(i18nKeys.events.eventDetails.labels.durationItems.eightHours),
      value: '8',
      disabled: editDisabled,
    },
  };

  useEffect(() => {
    if ((target as Event)?.catalogId !== null){
      setMinParticipants(participants);
      handleUpdateEditEvent(EditEventActions.MIN_EXPECTED_PARTICIPANTS, participants);
    }
  }, [participants]);

  useEffect(() => {
    setupSelections();
  }, []);

  useEffect(() => {
    setupSelections();
  }, [editMode, newEventMode]);

  /**
   * Url template generator isnt catching default update to target until second time it renders,
   * watch target change here and verify we add default if target changes and it doesnt exist
   */
  useEffect(() => {
    if (newEventMode && !target?.timezone) {
      const defaultTimezone = getBrowserTimezoneName();
      handleUpdateEditEvent(EditEventActions.TIMEZONE, defaultTimezone);
    }
  }, [target]);

  useEffect(() => {
    if (
      (editMode || newEventMode) &&
      (startDate !== target?.startDate ||
        durationChanged() ||
        getTimeForPolarisTimePicker(target?.startDate) !== startTime ||
        target?.timezone !== selectedTimezoneOption?.value)
    ) {
      eventTimeValidation.isValidField(EventFields.EVENT_START_DATE);
      eventTimeValidation.isValidField(EventFields.EVENT_START_TIME);
      eventTimeValidation.isValidField(EventFields.DURATION);
      handleSetStartAndEndDateTimes();
    }
    // remove the previous message everytime user change any input
    clearFlashbars();
  }, [startDate, startTime, selectedMeridiem, selectedTimezoneOption, newDuration, selectedSpecificDuration]);

  // this will validate the field and show the message
  const eventTimeValidation: Validator<EventFields> = useMemo(
    () =>
      eventTimesValidator(
        startDate,
        {
          required: t(i18nKeys.eventTemplates.catalogDetails.startDate.error),
          minimum: t(i18nKeys.eventTemplates.catalogDetails.startDate.minimum)
        },
        startTime,
        {
          required: t(i18nKeys.eventTemplates.catalogDetails.startTime.error),
          minimum: t(i18nKeys.eventTemplates.catalogDetails.startTime.minimum)
        },
        {
          isAdmin: user ? user.isSuperAdmin : false,
          duration: newDuration ? (newDuration === 'specific' ? selectedSpecificDuration : "0" + newDuration + ":00") : newDuration,
        },
        {
          required: t(i18nKeys.newEvent.errors.duration),
          maximum: t(i18nKeys.newEvent.errors.durationLimit, {bound: MAX_DURATION_NON_ADMIN})
        },
        selectedMeridiem.value ?? 'AM',
        selectedTimezoneOption?.value || getBrowserTimezoneName() || '',
        new Map<EventFields, (error: string) => void>([
          [EventFields.EVENT_START_DATE, (error: string) => setStartDateError(error)],
          [EventFields.EVENT_START_TIME, (error: string) => setStartTimeError(error)],
          [EventFields.DURATION, (error: string) => setDurationError(error)],
        ])
      ),
    [startDate, startTime, selectedMeridiem, newDuration, selectedSpecificDuration, eventTimesValidator]
  );

  // this will prevent user to next step in wizard
  if (validationHandler && eventTitleValidation && eventTimeValidation) {
    validationHandler(() => {
      const eventTitleValidationResult = eventTitleValidation.isValidSection(true);
      const eventTimesValidationResult = eventTimeValidation.isValidSection(true);
      if (eventTitleValidationResult && eventTimesValidationResult) {
        return Promise.resolve(eventTitleValidationResult && eventTimesValidationResult);
      } else {
        return Promise.reject(eventTitleValidationResult && eventTimesValidationResult);
      }
    });
  }

  const setupSelections = () => {
    const newTimezoneOptions = getTimezonesWithUTCOffsetAsOptionList();
    setTimezoneOptions(newTimezoneOptions);
    const defaultTimezone = target?.timezone ? target.timezone : getBrowserTimezoneName();
    if (!target?.timezone) {
      handleUpdateEditEvent(EditEventActions.TIMEZONE, defaultTimezone);
    }
    if (defaultTimezone) {
      const selectedTimezone = newTimezoneOptions.find((e) => e.value === defaultTimezone);
      if (selectedTimezone) {
        setSelectedTimezoneOption(selectedTimezone);
      }
    }
    if (target?.autoUnlockChallengesOffsetMinutes != null) {
      setAutoUnlockChallenges(true);
      setAutoUnlockChallengesMinutes(target?.autoUnlockChallengesOffsetMinutes.toString());
    }
    setSelectedStartDateAndTime();
    getSelectedDurationPreset();
  };

  const durationChanged = () => {
    if (target?.duration.minutes !== 0 && target?.duration.hours && selectedSpecificDuration !== null) {
      return formatDuration(target?.duration?.hours, target?.duration.minutes) !== selectedSpecificDuration;
    } else if (newDuration && target?.duration.hours) {
      return target?.duration.hours !== Number(newDuration);
    }
    return false;
  };

  const getSelectedDurationPreset = () => {
    if (eventTemplate && getEventDurationInfo(eventTemplate)) {
      const durationInfo = getEventDurationInfo(eventTemplate);
      if (durationInfo) setNewDuration(durationInfo?.hours.toString());
      return;
    }

    if (target && (target?.duration?.hours || target?.duration?.minutes)) {
      if (durationItems[target.duration.hours] && target.duration.minutes === 0) {
        setNewDuration(target.duration.hours.toString());
      } else if (target.duration.minutes !== 0 || target.duration.hours > HOURS_PER_MONTH) {
        setNewDuration('specific');
        setSelectedSpecificDuration(formatDuration(target.duration.hours, target.duration.minutes));
      }
    }
  };

  const setSelectedStartDateAndTime = () => {
    if (target?.startDate) {
      // Polaris DatePicker component does not properly parse full startDate with time
      // Spliting here fixes default selection for DatePicker
      setStartDate(target?.startDate.split('T')[0]);
      const startTimeFromEvent = getTimeInBrowserLocalTime(target?.startDate, {
        includeDate: false,
        includeTime: true,
      });
      const dateSelectorTime = getTimeForPolarisTimePicker(target?.startDate);

      if (startTimeFromEvent && dateSelectorTime) {
        setSelectedMeridiem(extractMeridiem(target.startDate));
        
        const convertedTime = convert24To12HourTime(startTimeFromEvent, false);
        setStartTime(convertedTime);
      }
    }
  };

  const formatDuration = (hours: number, minutes: number) => {
    return `${hours}:${minutes}`;
  };

  const handleTimezoneSelection = (optionSelection: OptionDefinition) => {
    setSelectedTimezoneOption(optionSelection);
    handleUpdateEditEvent(EditEventActions.TIMEZONE, optionSelection.value);
  };

  const handleparticipantChange = (numOfparticipants: string) => {
    setParticipants(numOfparticipants);
    handleUpdateEditEvent(EditEventActions.MAX_EXPECTED_PARTICIPANTS, numOfparticipants);
  };

  const handleMinparticipantChange = (numOfparticipants: string) => {
    setMinParticipants(numOfparticipants);
    handleUpdateEditEvent(EditEventActions.MIN_EXPECTED_PARTICIPANTS, numOfparticipants);
  };

  const handleDurationChange = (updatedDuration: string) => {
    setNewDuration(updatedDuration);
    setSelectedSpecificDuration(null);
    setDurationError('');
  };

  const handleAutoUnlockChallengesCheck = (autoUnlockChallengesCheck: boolean) => {
    setAutoUnlockChallenges(autoUnlockChallengesCheck);
    setAutoUnlockChallengesMinutes('0');
    handleUpdateEditEvent(EditEventActions.AUTO_UNLOCK_CHALLENGES_MINUTES, !autoUnlockChallengesCheck ? null : 0);
  };

  const handleAutoUnlockChallengesMinutesChange = (selectedAutoUnlockChallengesMinutes: string) => {
    if (containsOnlyNumbers(selectedAutoUnlockChallengesMinutes)) {
      setAutoUnlockChallengesMinutes(selectedAutoUnlockChallengesMinutes);
      handleUpdateEditEvent(
        EditEventActions.AUTO_UNLOCK_CHALLENGES_MINUTES,
        Number(selectedAutoUnlockChallengesMinutes)
      );
    }
  };

  const handleSetStartAndEndDateTimes = () => {
    if (!isValidStartTime(startTime)) return; // don't update start time if it's invalid
    if (startDate && startTime && selectedMeridiem && selectedTimezoneOption && selectedTimezoneOption.value) {
      const startDateTime = polarisDateTimeToMoment(
        startDate,
        `${startTime} ${selectedMeridiem.value}`,
        selectedTimezoneOption.value
      ).format();

      // Cloning/reassigning startDateTime to endDateTime was causing the math to affect both start and end dateTimes
      // so, we recreate the startDateTime moment to modify here
      let endDateTime: Moment | string = polarisDateTimeToMoment(
        startDate,
        `${startTime} ${selectedMeridiem.value}`,
        selectedTimezoneOption.value
      );

      LoggingService.debug('newDuration', newDuration);
      if (selectedSpecificDuration) {
        const [hours, minutes] = selectedSpecificDuration.split(':');
        endDateTime = endDateTime.add(hours, 'hours').add(minutes, 'minutes').format();
      } else {
        endDateTime = endDateTime.add(newDuration, 'hours').format();
      }

      handleUpdateEditEvent(EditEventActions.START_AND_END_DATETIMES, {
        startDateTime,
        endDateTime,
      });
    }
  };

  const validSpecificDate = (duration: string) => {
    if (duration.includes(':')) {
      if (TIME_DURATION_REGEX.test(duration)) {
        setSelectedSpecificDuration(duration);
      } else {
        const timeArr = duration.split(':');
        const hours = _.toString(timeArr[0]);
        const minutes = _.toString(timeArr[1]);
        if (hours <= EVENT_DURATION_MAX_HOURS) {
          let validDuration;
          if (minutes <= EVENT_DURATION_MAX_MINUTES) {
            validDuration = `${_.toString(hours)}:${_.toString(minutes)}`;
          } else {
            validDuration = `${_.toString(hours)}:00`;
          }
          setSelectedSpecificDuration(validDuration);
        } else {
          setSelectedSpecificDuration(MAX_EVENT_DURATION);
        }
      }
    } else {
      if (duration.length >= 4) {
        setSelectedSpecificDuration(MAX_EVENT_DURATION);
      } else if (duration.length === 3) {
        if (duration <= EVENT_DURATION_MAX_LIMIT) {
          const validDuration = `${duration}:00`;
          setSelectedSpecificDuration(validDuration);
        } else {
          setSelectedSpecificDuration(MAX_EVENT_DURATION);
        }
      } else {
        const validDuration = duration ? `${duration}:00` : '';
        setSelectedSpecificDuration(validDuration);
      }
    }
  };

  const setSpecificDate = (value: string) => {
    if (NUMBER_REGEX.test(value || '') && value.length <= 6) {
      setSelectedSpecificDuration(value);
    } else {
      setSelectedSpecificDuration('');
    }
  };

  const isDatetInThePast = (
    selectStartDate: string,
    selectStartTime: string,
    selectMeridiem: OptionDefinition,
    selectTimezoneOption: OptionDefinition | null
  ): boolean => {
    if (selectStartDate && selectStartTime && selectMeridiem && selectTimezoneOption && selectTimezoneOption.value) {
      const currentDateAndTime = moment().tz(selectTimezoneOption.value).format();
      const selectedDateAndTime = polarisDateTimeToMoment(
        selectStartDate,
        `${selectStartTime} ${selectMeridiem.value}`,
        selectTimezoneOption.value
      ).format();
      return selectedDateAndTime > currentDateAndTime;
    }
    return false;
  };

  const onTimeZoneChange = (timezone: OptionDefinition) => {
    const isValidDate = isDatetInThePast(startDate, startTime, selectedMeridiem, timezone);
    if (isValidDate) {
      handleTimezoneSelection(timezone);
    }
  };

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        <div className="container-table-header">
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
            <div className="table-divider" />
            <div>
              <strong>{t(i18nKeys.events.eventDetails.labels.eventLocalTime)}</strong>
            </div>
            <div className="table-divider">
              <strong>{t(i18nKeys.events.eventDetails.labels.yourLocalTime)}</strong>
            </div>
          </Grid>
        </div>
        <div style={{ paddingTop: '10px', marginBottom: '-20px' }}>
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
            <div style={{ lineHeight: '40px' }}>{t(i18nKeys.events.eventDetails.labels.startTime)}</div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.START}
                  event={target}
                  timezoneFormat={TimezoneFormat.UTC}
                  displayEventTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.START}
                  event={target}
                  timezoneFormat={TimezoneFormat.LOCAL}
                  displayLocalTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
          </Grid>
        </div>
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
            <div style={{ lineHeight: '40px' }}>{t(i18nKeys.events.eventDetails.labels.endTime)}</div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.END}
                  event={target}
                  timezoneFormat={TimezoneFormat.UTC}
                  displayEventTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.END}
                  event={target}
                  timezoneFormat={TimezoneFormat.LOCAL}
                  displayLocalTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
          </Grid>
        </div>
      </React.Fragment>
    ) : (
      canEdit && (
            <SpaceBetween direction="vertical" size="s">
              <KeyValue label={<b>{t(i18nKeys.events.eventDetails.labels.timezone)}</b>} required>
                <Select
                  selectedOption={selectedTimezoneOption}
                  options={timezoneOptions}
                  onChange={({ detail }) => handleTimezoneSelection(detail.selectedOption)}
                  disabled={editDisabled}
                />
              </KeyValue>
              <FormField
                label={
                  <Box variant="span">
                    <b>{t(i18nKeys.events.eventDetails.labels.startTime)}</b>
                    <span style={{ color: awsui.colorTextStatusError }}> *</span>
                  </Box>
                }
                description={t(i18nKeys.events.eventDetails.messages.startTimeDescription)}>
                <SpaceBetween direction="horizontal" size="xs">
                  <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }} errorText={startDateError}>
                    <DatePicker
                      className="inline"
                      onChange={({ detail }) => setStartDate(detail.value)}
                      value={startDate}
                      openCalendarAriaLabel={(selectedDate) =>
                        t(i18nKeys.general.chooseDate) +
                        (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
                      }
                      placeholder="YYYY/MM/DD"
                      locale={languageCode}
                      previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
                      nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
                      todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
                      isDateEnabled={isDateNotInThePast}
                      onBlur={() => eventTimeValidation.isValidField(EventFields.EVENT_START_DATE)}
                      disabled={editDisabled}
                    />
                  </FormField>
                  <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }} errorText={startTimeError}>
                    <TimeInput
                      value={startTime}
                      placeholder="hh:mm"
                      format="hh:mm"
                      use24Hour={false}
                      onChange={({ detail }) => setStartTime(detail.value)}
                      onBlur={() => eventTimeValidation.isValidField(EventFields.EVENT_START_TIME)}
                      disabled={editDisabled}
                    />
                  </FormField>
                  <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }} errorText="">
                    <Select
                      className="inline-select"
                      options={[MeridiemOptions.AM, MeridiemOptions.PM]}
                      onChange={({ detail }) => setSelectedMeridiem(detail.selectedOption)}
                      onBlur={() => eventTimeValidation.isValidField(EventFields.EVENT_START_TIME)}
                      selectedOption={selectedMeridiem}
                      disabled={editDisabled}
                    />
                  </FormField>
                </SpaceBetween>
              </FormField>
              <KeyValue label={<b>{t(i18nKeys.events.eventDetails.labels.duration)}</b>} required>
                <SpaceBetween direction="vertical" size="s">
                  <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }} errorText={durationError}>
                    <Tiles
                      value={newDuration}
                      onChange={({ detail }) => handleDurationChange(detail.value)}
                      items={[
                        durationItems[4],
                        durationItems[8],
                        {
                          controlId: 'specificTileId',
                          label: (
                            <KeyValue label={t(i18nKeys.events.eventDetails.labels.specific)}>
                              {newDuration === 'specific' &&
                                (user?.isSuperAdmin ? (
                                  <Input
                                    placeholder="hhh:mm"
                                    value={selectedSpecificDuration || ''}
                                    onChange={({ detail }) => setSpecificDate(detail.value)}
                                    onBlur={() => validSpecificDate(selectedSpecificDuration || '')}
                                  />
                                ) : (
                                  <TimeInput
                                    placeholder="hh:mm"
                                    value={selectedSpecificDuration || ''}
                                    format="hh:mm"
                                    onChange={({ detail }) => setSelectedSpecificDuration(detail.value)}
                                    onBlur={() => eventTimeValidation.isValidField(EventFields.DURATION)}
                                  />
                                ))}
                            </KeyValue>
                          ),
                          value: 'specific',
                          disabled: editDisabled,
                        },
                      ]}
                    />
                  </FormField>
                  <div>
                    <span
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '0.5em',
                        marginTop: '-0.2em'
                      }}>
                      <Checkbox
                        checked={autoUnlockChallenges}
                        onChange={() => handleAutoUnlockChallengesCheck(!autoUnlockChallenges)}
                        disabled={editDisabled}
                        ariaLabel={t(i18nKeys.events.eventDetails.labels.autoUnlockChallenges)}
                      />
                    </span>
                    <span style={{ verticalAlign: 'middle' }}>
                      {t(i18nKeys.events.eventDetails.messages.automaticallyUnlockChallenges)}
                      <Input
                        disabled={!autoUnlockChallenges}
                        inputMode="numeric"
                        type="number"
                        value={autoUnlockChallengesMinutes}
                        onChange={({ detail }) => handleAutoUnlockChallengesMinutesChange(detail.value)}
                        className="numeric-input"
                        ariaLabel={t(i18nKeys.events.eventDetails.labels.numericUnlockChallengesMinutes)}
                      />
                      {t(i18nKeys.events.eventDetails.messages.minutesAfterStart)}
                    </span>
                  </div>
                  <div className="secondary-text">
                    {autoUnlockChallenges && autoUnlockChallengesMinutes === '0'
                      && t(i18nKeys.events.eventDetails.messages.autoUnlockChallengesInfo)
                      + t(i18nKeys.events.eventDetails.messages.autoUnlockChallengesInfoEventStartTime)}

                    {autoUnlockChallenges && autoUnlockChallengesMinutes > '0'
                      && t(i18nKeys.events.eventDetails.messages.autoUnlockChallengesInfo)
                      + autoUnlockChallengesMinutes + " "
                      + t(i18nKeys.events.eventDetails.messages.autoUnlockChallengesInfoMinutesAfterEventStartTime)}

                    {!autoUnlockChallenges && t(i18nKeys.events.eventDetails.messages.challengesWillRemainLocked)}
                  </div>
                </SpaceBetween>
              </KeyValue>
            </SpaceBetween>
        
      )
    );
  };

  const renderEditableContent = () => {
    return (
      <>
          <SpaceBetween direction="vertical" size="s">
            <KeyValue label={<b>{t(i18nKeys.events.eventDetails.labels.timezone)}</b>} required>
              <div className="input-width-limit">
              <Select
                selectedOption={selectedTimezoneOption}
                options={timezoneOptions}
                onChange={({ detail }) => onTimeZoneChange(detail.selectedOption)}
                disabled={isDisabled}
              />
              </div>
            </KeyValue>
            <FormField
              label={
                <Box variant="span">
                  <b>{t(i18nKeys.events.eventDetails.labels.startTime)}</b>
                  <span style={{ color: awsui.colorTextStatusError }}> *</span>
                </Box>
              }
              description={t(i18nKeys.events.eventDetails.messages.startTimeDescription)}>
              <Grid gridDefinition={[{colspan: { default: 12, xxs: 5 }}, {colspan: { default: 9, xxs: 5 }}, {colspan: { default: 3, xxs: 2 }}]}>
                <FormField errorText={startDateError}>
                <DatePicker
                  onChange={({ detail }) => setStartDate(detail.value)}
                  value={startDate}
                  openCalendarAriaLabel={(selectedDate) =>
                    t(i18nKeys.general.chooseDate) +
                    (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
                  }
                  placeholder="YYYY/MM/DD"
                  locale={languageCode}
                  previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
                  nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
                  todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
                  disabled={isDisabled}
                  invalid={isInvalid}
                  isDateEnabled={isDateNotInThePast}
                />
                </FormField>
                <FormField errorText={startTimeError}>
                <TimeInput
                  value={startTime}
                  placeholder="hh:mm"
                  format="hh:mm"
                  use24Hour
                  invalid={isInvalid}
                  onChange={({ detail }) => setStartTime(detail.value)}
                  disabled={isDisabled}
                />
                </FormField>
                <FormField>
                <Select
                  className="inline-select"
                  options={[MeridiemOptions.AM, MeridiemOptions.PM]}
                  onChange={({ detail }) => setSelectedMeridiem(detail.selectedOption)}
                  selectedOption={selectedMeridiem}
                  invalid={isInvalid}
                  disabled={isDisabled}
                />
                </FormField>
              </Grid>
              <Box>
              {
                  (target as Event)?.catalogId === null &&
                  <KeyValue label={<b>{t(i18nKeys.challenges.challengeDetails.titles.minExpectedParticipants)}</b>} required>
                    <div style={{ marginTop: '5px' }}>
                      <Input
                        value={minParticipants ?? ''}
                        onChange={({ detail }) => handleMinparticipantChange(detail.value)}
                        disabled={isDisabled}
                      />
                    </div>
                  </KeyValue>
                }
                <KeyValue label={<b>{t(i18nKeys.events.fields.numberOfParticipants.participants)}</b>} required>
                  <div style={{ marginTop: '5px' }}>
                    <Input
                      value={participants ?? ''}
                      onChange={({ detail }) => handleparticipantChange(detail.value)}
                      disabled={isDisabled}
                    />
                  </div>
                </KeyValue>
              </Box>
              {user?.isOnlyBasicUser && (
                <SpaceBetween direction="horizontal" size="l">
                  <div>
                    {t(i18nKeys.events.fields.numberOfParticipants.editText)}{' '}
                    <Link onFollow={handleCancelOrDeleteEvent}>
                      {t(i18nKeys.events.eventDetails.buttons.cancelEvent)}
                    </Link>
                  </div>
                </SpaceBetween>
              )}
            </FormField>
          </SpaceBetween>
      </>
    );
  };

  return !newEventMode ? (
    <div>
      {!user?.hasBasicAccess ? (
        <ExpandableSection
          variant="container"
          headerText={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventTimes)}</Header>}>
          {renderContent()}
        </ExpandableSection>
      ) : (
        renderEditableContent()
      )}
    </div>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventTimes)}</Header>}>
      {renderContent()}
    </Container>
  );
};
export default EventTimes;
