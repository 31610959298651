import React from 'react';
import { Box, ColumnLayout, Container } from '@amzn/awsui-components-react';
import ClueContainer from './Clues/ClueContainer';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import { JamTaskClue } from '@/src/types/JamChallengeDetails';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface CollapsibleTabContentProps {
  content?: string;
  showClues?: boolean;
  clues?: JamTaskClue[];
  isFacilitator: boolean;
}

const CollapsibleTabContent: React.FC<CollapsibleTabContentProps> = ({ content, showClues, clues, isFacilitator }) => {
  const { t } = useTranslation();
  return (
    <Container className="dark-bg">
      {content && !showClues ? (
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
      ) : (
        <React.Fragment>
          {clues && clues.length > 0 && (
            <ColumnLayout columns={1} borders="horizontal">
              <Box variant="h2">{t(i18nKeys.myJams.challenges.details.task.clue.clues)}</Box>
              {clues?.map((clue) => (
                <ClueContainer
                  key={`${clue.title}-${clue.order}`}
                  title={clue.title ?? ''}
                  penalty={clue.cluePenalty ?? 0}
                  order={clue.order}
                  description={clue.description}
                  btnText={t(i18nKeys.myJams.challenges.details.task.clue.unlockClue)}
                  used={clue.used}
                  isFacilitator={isFacilitator}
                />
              ))}
            </ColumnLayout>
          )}
        </React.Fragment>
      )}
    </Container>
  );
};

export default CollapsibleTabContent;
