import React from 'react';
import { Box, RadioGroup, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface LearnSomethingAndFeedbackProps {
  radio: boolean | string;
  textArea: string;
  radioName: string;
  textAreaName: string;
  setValue: (name: string, value: string) => void;
}

const LearnSomethingAndFeedback: React.FC<LearnSomethingAndFeedbackProps> = ({
  radio,
  textArea,
  radioName,
  textAreaName,
  setValue,
}) => {
  const { t } = useTranslation();
  return (
    <SpaceBetween direction="vertical" size="m">
      <Box variant="div">
        <Box variant="h4">{t(i18nKeys.myJams.feedback.radio.learnSomethingNew)}</Box>
        <RadioGroup
          onChange={({ detail }) => setValue(radioName, detail.value)}
          value={String(radio)}
          items={[
            { value: 'yes', label: t(i18nKeys.myJams.feedback.radio.yes) },
            { value: 'no', label: t(i18nKeys.myJams.feedback.radio.no) },
          ]}
        />
      </Box>
      <Box variant="div" className="feedback-textarea">
        <Box variant="h4">{t(i18nKeys.myJams.feedback.label.additionalFeedback)}</Box>
        <Textarea
          onChange={({ detail }) => setValue(textAreaName, detail.value)}
          value={textArea}
          placeholder="Placeholder"
        />
      </Box>
    </SpaceBetween>
  );
};
export default LearnSomethingAndFeedback;
