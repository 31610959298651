import { Wizard, WizardProps } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../RoutePath';
import {
  useChallengeSet,
} from '../../../store/challenge-set.context';
import { useChallenges } from '../../../store/challenge.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { ChallengeSetRequest } from '../../../types/ChallengeSet';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import BrowseChallenges from '../../common/Challenges/BrowseChallenges';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { LoggingService } from '@/src/utils/logging-service.utils';
import { ChallengeSetTitleDescription } from './ChallengeSetTitleDescription';
import './CreateChallengeSet.scss';
import { customEventTrigger } from '../../analytics/createEventTrigger';

const CreateChallengeSet: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleChallengeInfo } = useToolPanel();
  const { getChallengeDescriptors } = useChallenges();
  const { createChallengeSet } = useChallengeSet();
  const isCurrentSectionValid = useRef<() => Promise<boolean>>();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [challengeSetRequest, setChallengeSetRequest] = useState<ChallengeSetRequest>(new ChallengeSetRequest());
  const [currentChallengeDescriptors, setCurrentChallengeDescriptors] = useState<ChallengeDescriptor[]>([]);

  useEffect(() => {
    setCurrentChallengeDescriptors(getChallengeDescriptors(challengeSetRequest.challengeIds));
  }, [challengeSetRequest]);

  const handleChallengeAction = (payload: ChallengeDescriptor[]) => {
    if (challengeSetRequest) {
      const challengeIds: string[] = payload.map((cd) => cd.challengeId as string);
      setChallengeSetRequest((prevState) => ({ ...prevState, challengeIds }));
    }
  };

  const handleSubmit = () => {
    customEventTrigger('submit', 'Create Challenge Set', window.location.href, 'Create Challenge Set', {});
    createChallengeSet(challengeSetRequest)
      .then((cs) => {
        if (cs) {
          history.push(`${cs.id}`);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const isSectionValid = useCallback(async () => {
    if (!isCurrentSectionValid.current) return false;
    try {
      const res = await isCurrentSectionValid.current();
      return res;
    } catch (error) {
      preProdLogger('Could not complete validation.', error);
      throw error;
    }
  }, [isCurrentSectionValid.current]);

  const validationHandler = (validateSection: () => Promise<boolean>) => {
    isCurrentSectionValid.current = validateSection;
  };

  const navigateWithValidation = useCallback(
    (requestedStepIndex: number) => {
      isSectionValid()
        .then(() => {
          setActiveStepIndex(requestedStepIndex);
        })
        .catch((err) => {
          LoggingService.error('Validation failed', err);
          preProdLogger('Could not complete validation.', err);
        });
    },
    [isSectionValid]
  );

  const handleWizardNavigation = useCallback<NonCancelableEventHandler<WizardProps.NavigateDetail>>(
    ({ detail }) => {
      customEventTrigger('click', 'Create Challenge Set Next', window.location.href, 'Create Challenge Set Next', {});
      const { reason, requestedStepIndex } = detail;
      if (reason === 'next') {
        navigateWithValidation(requestedStepIndex);
      } else {
        setActiveStepIndex(requestedStepIndex);
      }
    },
    [navigateWithValidation]
  );

  return (
    <Wizard
      allowSkipTo
      activeStepIndex={activeStepIndex}
      onNavigate={handleWizardNavigation}
      onSubmit={handleSubmit}
      onCancel={() => {
        customEventTrigger(
          'click',
          'Cancel Create Challenge Set',
          window.location.href,
          'Cancel Create Challenge Set',
          {}
        );
        history.push(RoutePath.CHALLENGE_SETS);
      }}
      steps={[
        {
          title: t(i18nKeys.challenges.subSections.overview.title),
          content: (
            <ChallengeSetTitleDescription
              challengeSet={challengeSetRequest}
              onChange={(challengeSet) => setChallengeSetRequest(challengeSet)}
              validationHandler={validationHandler}
            />
          ),
        },
        {
          title: t(i18nKeys.challenges.challengeSet.header.challengeSelection),
          description: t(i18nKeys.challenges.challengeSet.message.challengeSelectionDescription),
          content: (
            <BrowseChallenges
              handleChallengeAction={handleChallengeAction}
              currentChallengeDescriptors={currentChallengeDescriptors}
              toggleChallengeInfo={toggleChallengeInfo}
              editMode
            />
          ),
        },
      ]}
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t(i18nKeys.general.stepCounter, { count: stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) =>
          t(i18nKeys.general.stepOf, { stepNumber, totalSteps: stepsCount }),
        skipToButtonLabel: (step) => t(i18nKeys.general.skipTo, { stepName: step.title }),
        cancelButton: t(i18nKeys.general.cancel),
        previousButton: t(i18nKeys.general.previous),
        nextButton: t(i18nKeys.general.next),
        submitButton: t(i18nKeys.challenges.challengeSet.buttons.newChallengeSet),
        optional: t(i18nKeys.general.optional),
      }}
    />
  );
};

export default CreateChallengeSet;
