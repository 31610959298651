import { Container, ExpandableSection, Header, Textarea } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';

interface EventDetailsNotesProps {
  target: Event | undefined;
}

const EventDetailsNotes: React.FC<EventDetailsNotesProps> = ({ target }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, handleUpdateEditEvent, newEventMode, isGuestUserAndEditModeEnabled } = useEditEvent();

  const canEdit = editMode || newEventMode;

  const renderContent = () => {
    return (
      <React.Fragment>
        {!canEdit &&
          (target?.notes && target.notes.length > 0 ? (
            <div>{target?.notes}</div>
          ) : (
            <strong>{t(i18nKeys.general.none)}</strong>
          ))}
        {canEdit && user && (
          <Textarea
            value={target?.notes || ''}
            disabled={!target?.canEditAttribute('notes', user) || isGuestUserAndEditModeEnabled}
            onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.NOTES, detail.value)}
            ariaLabel={t(i18nKeys.events.eventDetails.labels.eventNote)}
          />
        )}
      </React.Fragment>
    );
  };

  return !newEventMode ? (
    <ExpandableSection
      variant="container"
      header={
        <Header variant="h2" description={t(i18nKeys.events.eventDetails.headers.notes.description)}>
          {t(i18nKeys.events.eventDetails.headers.notes.title)}
        </Header>
      }>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container
      header={
        <Header variant="h2" description={t(i18nKeys.events.eventDetails.headers.notes.description)}>
          {t(i18nKeys.events.eventDetails.headers.notes.title)}
        </Header>
      }>
      {renderContent()}
    </Container>
  );
};
export default EventDetailsNotes;
