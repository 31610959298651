import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingBar } from '../common/LoadingBar';
import { Challenge, ChallengeWrapper } from '@/src/types/Challenge';
import { i18nKeys } from '@/src/utils/i18n.utils';
import DifficultyIndicator from '../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface ChallengeProps {
  challenge: ChallengeWrapper;
}

export const ChallengeDetails: React.FC<ChallengeProps> = ({ challenge }) => {
  const { t } = useTranslation();

  if (!challenge) {
    return (
      <div style={{ marginTop: '32px' }}>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <h4>{t(i18nKeys.eventTemplates.challengeSidebar.difficulty)}:</h4>
        <DifficultyIndicator challenge={challenge.latest as Challenge} />
        <h4>{t(i18nKeys.eventTemplates.challengeSidebar.summary)}:</h4>
        <p>{challenge.latest?.props.learningOutcome.summary}</p>
        <h4>{t(i18nKeys.eventTemplates.challengeSidebar.learn)}:</h4>
        {challenge.latest?.props.description && (
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.latest?.props.description}</ReactMarkdown>
        )}
        <h4>{t(i18nKeys.eventTemplates.challengeSidebar.topicsCovered)}:</h4>
        <p>{t(i18nKeys.eventTemplates.challengeSidebar.topicsCoveredRes)}:</p>
        <ul>{challenge.latest?.props.jamType}</ul>
        <h4>{t(i18nKeys.eventTemplates.challengeSidebar.awsServiceUsed)}:</h4>
        <ol>
          {challenge.latest?.props.awsServices.map((service: string) => (
            <li key={service}>{service}</li>
          ))}
        </ol>
      </React.Fragment>
    );
  }
};

export default ChallengeDetails;
