import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SideNavigation } from '@amzn/awsui-components-react';
import { RoutePath } from '@/src/RoutePath';
import { faqSectionRoute } from '@/src/routes';
import './FAQSidebar.scss';
import { QuestionSections } from '../../faq.config';

const faqSections = [
  {
    title: QuestionSections.general.title,
    href: faqSectionRoute.resolvePath('general'),
  },
  {
    title: QuestionSections.host.title,
    href: faqSectionRoute.resolvePath('host'),
  },
  {
    title: QuestionSections.facilitating.title,
    href: faqSectionRoute.resolvePath('facilitating'),
  },
  {
    title: QuestionSections.play.title,
    href: faqSectionRoute.resolvePath('play'),
  },
];

const FAQSidebar = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [activeHref, setActiveHref] = useState(location.pathname);

  return (
    <div className="faq-sidebar">
      <SideNavigation
        activeHref={activeHref}
        onFollow={(event) => {
          if (!event.detail.external) {
            event.preventDefault();
            const newUrl = event.detail.href === '#' ? faqSectionRoute.resolvePath('general') : event.detail.href;
            setActiveHref(newUrl);
            history.push(newUrl);
          }
        }}
        header={{
          href: RoutePath.ROOT,
          text: 'AWS Jam',
        }}
        items={[
          {
            type: 'expandable-link-group',
            text: 'FAQ',
            href: '#',
            items: faqSections.map((section) => ({ type: 'link', text: t(section.title), href: section.href })),
            defaultExpanded: true,
          },
        ]}
      />
    </div>
  );
};

export default FAQSidebar;
