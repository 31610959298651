import { ErrorSetterByField, InputValidationHelper } from '../types/InputValidator';
import { JoinSpecificTeamFields } from '../types/JoinSpecificTeam';
import { validateField, validateIsEmpty, validateSection } from './validation.utils';

interface Params {
  teamName: string;
  password: string;
}

export const joinSpecificTeamValidator: (
  fields: Params,
  errors: Params,
  errorSetterByField: ErrorSetterByField<JoinSpecificTeamFields>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: JoinSpecificTeamFields, setError?: boolean) => boolean;
} = (fields, errors, errorSetterByField: Map<JoinSpecificTeamFields, (error: string) => void>) => {
  const validatorHelperByField = new Map<JoinSpecificTeamFields, InputValidationHelper>([
    [JoinSpecificTeamFields.TEAM_NAME, validateIsEmpty(fields.teamName, errors.teamName)],
    [JoinSpecificTeamFields.PASSWORD, validateIsEmpty(fields.password, errors.password)],
  ]);

  const isValidField = (field: JoinSpecificTeamFields, setError = true) => {
    return validateField<JoinSpecificTeamFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection<JoinSpecificTeamFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
