import React from 'react';
import { Box, Link } from '@amzn/awsui-components-react';
import { LeaderboardTeam } from '@/src/types/JamLeaderboard';
import { TFunctionProvider } from '../../common/TFunctionProvider';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ChallengeMetricsRow } from '@/src/types/JamReport';
import { getPassRate } from '@/src/utils/leaderboard.utils';
import moment from 'moment';

export const COLUMN_DEFINITIONS = (showTeamDetails: (id: string | null) => void) => {
  return [
    {
      id: 'rank',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.rank)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ rank }: LeaderboardTeam) => (
        <Box padding={{ left: 'm' }} variant="h2">
          {rank}
        </Box>
      ),
    },
    {
      id: 'teamName',
      header: (
        <Box fontWeight="bold">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamName)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ teamName, teamDisplayName }: LeaderboardTeam) => (
        <Link onFollow={() => showTeamDetails(teamName)}>
          <Box variant="h2" color="text-status-info">
            {teamDisplayName}
          </Box>
        </Link>
      ),
    },
    {
      id: 'completedChallenges',
      header: (
        <Box fontWeight="bold">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.completedChallenges)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ solvedChallenges }: LeaderboardTeam) => <Box variant="h2">{solvedChallenges}</Box>,
    },
    {
      id: 'cluesUsed',
      header: (
        <Box fontWeight="bold">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.cluesUsed)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ cluesUsed }: LeaderboardTeam) => <Box variant="h2">{cluesUsed}</Box>,
    },
    {
      id: 'score',
      header: (
        <Box fontWeight="bold">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.score)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ score }: LeaderboardTeam) => <Box variant="h2">{score < 0 ? 0 : score}</Box>,
    },
    {
      id: 'totalTime',
      header: (
        <Box fontWeight="bold">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.totalTime)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ totalTime }: LeaderboardTeam) => <Box variant="h2">{moment.utc(totalTime*1000).format('HH:mm:ss')}</Box>,
    },
  ];
};

export const COLUMN_DEFINITIONS_CHALLNGES = (challengeTitles: { [key: string]: string }) => {
  return [
    {
      id: 'title',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.challengeTitle)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ challengeId }: ChallengeMetricsRow) => (
        <Box color="text-status-info" padding={{ left: 'm' }} variant="h2">
          {challengeId ? challengeTitles[challengeId] : challengeId}
        </Box>
      ),
    },
    {
      id: 'numTeamsStarted',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamsStarted)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numTeamsStarted }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h2">
          {numTeamsStarted}
        </Box>
      ),
    },
    {
      id: 'numTeamsAttempted',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamsAttempted)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numTeamsAttempted }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h2">
          {numTeamsAttempted}
        </Box>
      ),
    },
    {
      id: 'teamsSolved',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamsSolved)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ numTeamsSolved }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h2">
          {numTeamsSolved}
        </Box>
      ),
    },
    {
      id: 'incorrectAnswers',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.incorrectAnswers)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numIncorrectAnswers }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h2">
          {numIncorrectAnswers}
        </Box>
      ),
    },
    {
      id: 'requestedClues',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.requestedClues)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numCluesRequested }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h2">
          {numCluesRequested}
        </Box>
      ),
    },
    {
      id: 'passRate',
      header: (
        <Box padding={{ left: 'm' }} fontWeight="bold">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.passRate)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ numCompletedTasks, numIncorrectAnswers }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h2">
          {getPassRate(numCompletedTasks || 0, numIncorrectAnswers || 0) * 100}%
        </Box>
      ),
    },
  ];
};
