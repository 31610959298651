import {
  Button,
  Toggle,
  Container,
  ExpandableSection,
  Grid,
  Header,
  Icon,
  Input,
  Popover,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import _, { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { copyToClipboard } from '../../../../utils/copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event, EventPermission, EventPermissionType } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { isEmailValid, splitDelimitedEmails } from '../../../../utils/string.utils';
import { useFlashbars } from '../../../../store/flashbar.context';
import { successFlashbar } from '../../../../utils/notification.utils';
import { EnterKeyCode } from '../../../../types/common';
import { Campaign } from '../../../../types/Campaign';
import { fromPlainObject } from '../../../../utils/mapper.utils';
import { EditCampaignActions, useEditCampaign } from '../../../../store/edit-campaign.context';

interface OwnerPermissionsProps {
  target: Event | Campaign | undefined;
  targetType: 'event' | 'campaign';
}

const OwnerPermissions: React.FC<OwnerPermissionsProps> = ({ target }) => {
  const [eventPermissions, setEventPermissions] = useState<EventPermission[]>([]);
  const { user } = useUser();
  const { addFlashbar } = useFlashbars();
  const { editMode, newEventMode, isGuestUserAndEditModeEnabled, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, handleUpdateEditCampaign, newCampaignMode } = useEditCampaign();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [creatorEmail, setCreatorEmail] = useState('');

  const canEdit = editMode || campaignEditMode || newEventMode || newCampaignMode;
  const isEvent = target instanceof Event;

  useEffect(() => {
    loadEventPermissions();
  }, []); // We only need to load the first time this component is rendered. Using `[]` as the second parameter will accomplish this.

  useEffect(() => {
    if (
      (newEventMode || newCampaignMode) &&
      !_.isEqual(isEvent ? target?.eventPermissions : target?.campaignSettings?.eventPermissions, eventPermissions)
    ) {
      if (isEvent) {
        handleUpdateEditEvent(EditEventActions.EVENT_PERMISSIONS, eventPermissions);
      } else {
        handleUpdateEditCampaign(EditCampaignActions.EVENT_PERMISSIONS, eventPermissions);
      }
    }
  }, [eventPermissions]);

  /**
   * method to store facilitator, owners, and support users into Event Permissions object
   */
  const loadEventPermissions = (): void => {
    const permissions = isEvent ? target?.eventPermissions : target?.campaignSettings?.eventPermissions;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const eventPermissionList: EventPermission[] = permissions?.filter((ep: { email: any }) => ep?.email) || [];
    const newCreatorEmail: string = target?.requestedBy || user?.email || '';
    // add the owner if not already there
    const users = eventPermissionList.map((ep: { email: string }) => ep.email);
    setCreatorEmail(newCreatorEmail);
    if (!users.includes(newCreatorEmail)) {
      eventPermissionList.unshift(
        fromPlainObject(
          {
            email: newCreatorEmail,
            eventPermissionType: EventPermissionType.OWNER,
          },
          EventPermission
        ) as EventPermission
      );
    }
    const newEventPermissions = eventPermissionList.map(
      (permission) => fromPlainObject(permission, EventPermission) as EventPermission
    );

    setEventPermissions(_.cloneDeep(newEventPermissions));
  };

  const validateAndSplitEmails = (newEmails: string): string[] => {
    const emails: string[] = splitDelimitedEmails(newEmails);
    const invalidEmails = (emails || []).filter((e) => !isEmailValid(e));
    if (invalidEmails.length > 0) {
      setErrorMessage(t(i18nKeys.errors.eventPermissions.invalidEmail) + invalidEmails.join(', '));
    } else {
      setErrorMessage('');
    }
    return emails;
  };

  const addEmails = () => {
    const emails = validateAndSplitEmails(newEmail);
    const eventPermissionList = eventPermissions;
    if (errorMessage.length < 1) {
      emails.forEach((email) => {
        const emailAlreadyExists = eventPermissionList.find((item) => item.email === email);
        if (!emailAlreadyExists) {
          eventPermissionList.push(
            EventPermission.fromPlainObject({
              email,
              eventPermissionType: null,
            })
          );
        } else {
          setErrorMessage(t(i18nKeys.errors.eventPermissions.existingEmail));
        }
      });
    }
    const newEventPermissions = _.cloneDeep(eventPermissionList);
    setEventPermissions(newEventPermissions);
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.EVENT_PERMISSIONS, newEventPermissions);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.EVENT_PERMISSIONS, newEventPermissions);
    }
    setNewEmail('');
  };

  /**
   * Removes email item from list of items to add permissions to
   *
   * @param email Email to remove
   */
  const removeEmail = (email: string) => {
    const newEventPermissions = eventPermissions.filter((i) => i.email !== email);
    setEventPermissions(newEventPermissions);
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.EVENT_PERMISSIONS, newEventPermissions);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.EVENT_PERMISSIONS, newEventPermissions);
    }
    setErrorMessage('');
  };

  const togglePermission = (item: EventPermission, index: number, permission: EventPermissionType) => {
    switch (permission) {
      case EventPermissionType.OWNER:
        if (!item.isOwner) {
          item.eventPermissionType = EventPermissionType.OWNER;
        } else {
          item.eventPermissionType = null;
        }
        break;
      case EventPermissionType.FACILITATOR:
        if (!item.isOwner && !item.isFacilitator) {
          item.eventPermissionType = EventPermissionType.FACILITATOR;
        } else {
          item.eventPermissionType = null;
        }
        break;
      case EventPermissionType.SUPPORT:
        if (!item.isOwner && !item.isFacilitator && !item.isSupportUser) {
          item.eventPermissionType = EventPermissionType.SUPPORT;
        } else {
          item.eventPermissionType = null;
        }
        break;
    }
    const newEventPermissions = _.cloneDeep(eventPermissions);
    setEventPermissions(newEventPermissions);
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.EVENT_PERMISSIONS, newEventPermissions);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.EVENT_PERMISSIONS, newEventPermissions);
    }
  };

  const isEventCreator = (email: string) => {
    return email === creatorEmail && creatorEmail === user?.email;
  };

  /**
   * Apply the provided filter to the list of event permissions items, then return an array of all the distinct emails of the remaining items.
   *
   * @param filterFn
   * @private
   */
  const getFilteredEmails = (filterFn: (item: EventPermission) => boolean) => {
    return uniq(eventPermissions.filter(filterFn).map((item) => item.email));
  };

  /**
   * Returns the emails of all users with event owner access.
   */
  const getOwnerEmails = () => {
    return getFilteredEmails((item) => item.isOwner);
  };

  /**
   * Returns the emails of all users with facilitator access
   */
  const getFacilitatorEmails = () => {
    return getFilteredEmails((item) => item.isFacilitator);
  };

  /**
   * Join the provided array of emails with ; appended after each email.
   *
   * @param emails
   * @private
   */
  const joinEmails = (emails: string[]): string => {
    return (emails || []).join(';') + ';';
  };

  /**
   * Copy a string of emails to the clipboard for all users with event owner permission for this event.
   */
  const copyEventOwnerEmails = () => {
    copyEmailsToClipboard(joinEmails(getOwnerEmails()));
  };

  /**
   * Copy a string of emails to the clipboard for all users with facilitator permission for this event.
   * NOTE: this includes event owners because event owners have facilitator access.
   */
  const copyFacilitatorEmails = () => {
    copyEmailsToClipboard(joinEmails(getFacilitatorEmails()));
  };

  const copyEmailsToClipboard = (value: string) => {
    copyToClipboard(value);
    addFlashbar(successFlashbar(t(i18nKeys.clipboard.successCopiedToClipboard), 'Clipboard'));
  };

  const renderContent = () => {
    return (
      <div style={{ marginBottom: '-15px' }}>
        {canEdit && (
          <div className="mb-20">
            <ul style={{ marginLeft: '-20px' }}>
              <li>
                {isEvent
                  ? t(i18nKeys.events.eventDetails.labels.eventPermissions.messages.eventOwner)
                  : t(i18nKeys.campaigns.messages.campaignDetails.ownerPermissions.campaignOwner)}
              </li>
              <li>
                {isEvent
                  ? t(i18nKeys.events.eventDetails.labels.eventPermissions.messages.mustVerify)
                  : t(i18nKeys.campaigns.messages.campaignDetails.ownerPermissions.mustVerify)}
              </li>
            </ul>
            <Grid gridDefinition={[{ colspan: 8 }]}>
              <div>
                <Input
                  className="inline email-input"
                  placeholder="user@example.com"
                  value={newEmail}
                  onChange={({ detail }) => setNewEmail(detail.value)}
                  onKeyDown={({ detail }) => {
                    // 13 is the keyCode for "Enter"
                    if (detail.keyCode === EnterKeyCode) {
                      addEmails();
                    }
                  }}
                />{' '}
                <Button className="inline" onClick={addEmails}>
                  {t(i18nKeys.general.add)}
                </Button>
                <div style={{ color: awsui.colorTextStatusError }}>{errorMessage}</div>
              </div>
            </Grid>
          </div>
        )}
        <Header className="mb-20" variant="h3">
          {t(i18nKeys.events.eventDetails.headers.permissions)}
        </Header>
        <div className="container-table-header">
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }]}>
            <div>
              <strong>{t(i18nKeys.events.eventDetails.headers.owner)}</strong>
            </div>
            <div style={{ textAlign: 'center' }}>
              <strong>
                {isEvent
                  ? t(i18nKeys.events.eventDetails.headers.eventOwner)
                  : t(i18nKeys.campaigns.headers.campaignDetails.campaignOwner)}
              </strong>
            </div>
            <div style={{ textAlign: 'center' }}>
              <strong>{t(i18nKeys.events.eventDetails.headers.facilitator)}</strong>
            </div>
            <div style={{ textAlign: 'center' }}>
              <strong>{t(i18nKeys.events.eventDetails.headers.support)}</strong>
            </div>
          </Grid>
        </div>
        {eventPermissions.map((permission, i) => {
          return (
            user && (
              <div
                key={`permission-${i}`}
                style={{
                  padding: '10px 20px',
                  margin: '0px -20px',
                  borderTop: i !== 0 ? '1px solid #eaeded' : 'none',
                }}>
                <Grid gridDefinition={[{ colspan: 4 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }]}>
                  <div>
                    <strong>
                      {permission.email}
                      {isEventCreator(permission.email) && (
                        <React.Fragment>
                          <span>({t(i18nKeys.general.you)})</span>
                          <div className="ml-5" style={{ display: 'inline-block' }}>
                            <Popover
                              dismissButton={false}
                              position="top"
                              size="medium"
                              triggerType="custom"
                              content={
                                isEvent
                                  ? t(i18nKeys.events.eventDetails.labels.eventPermissions.messages.eventCreator)
                                  : t(i18nKeys.campaigns.messages.campaignDetails.ownerPermissions.campaignCreator)
                              }>
                              <Icon name="status-info" size="normal" alt="status-info" variant="link" />
                            </Popover>
                          </div>
                        </React.Fragment>
                      )}
                    </strong>
                  </div>
                  {!canEdit && (
                    <div style={{ textAlign: 'center' }}>
                      {permission.isOwner ? <Icon name="status-positive" /> : <span />}
                    </div>
                  )}
                  {!canEdit && (
                    <div style={{ textAlign: 'center' }}>
                      {permission.isFacilitator ? <Icon name="status-positive" /> : <span />}
                    </div>
                  )}
                  {!canEdit && (
                    <div style={{ textAlign: 'center' }}>
                      {permission?.isSupportUser ? <Icon name="status-positive" /> : <span />}
                    </div>
                  )}
                  {canEdit && (
                    <div style={{ marginLeft: '40%' }}>
                      <Toggle
                        checked={permission.isOwner}
                        ariaLabel={t(i18nKeys.events.eventDetails.labels.ownerPermission)}
                        onChange={() => togglePermission(permission, i, EventPermissionType.OWNER)}
                        disabled={
                          i === 0 ||
                          isEventCreator(permission?.email) ||
                          !target?.canEditAttribute('eventPermissions', user) ||
                          isGuestUserAndEditModeEnabled
                        }
                      />{' '}
                    </div>
                  )}
                  {canEdit && (
                    <div style={{ marginLeft: '40%' }}>
                      <Toggle
                        checked={permission.isFacilitator}
                        ariaLabel={t(i18nKeys.events.eventDetails.labels.facilitatorPermission)}
                        onChange={() => togglePermission(permission, i, EventPermissionType.FACILITATOR)}
                        disabled={
                          i === 0 ||
                          isEventCreator(permission.email) ||
                          permission.isOwner ||
                          !target?.canEditAttribute('eventPermissions', user) ||
                          isGuestUserAndEditModeEnabled
                        }
                      />{' '}
                    </div>
                  )}
                  {canEdit && (
                    <div style={{ marginLeft: '40%' }}>
                      <Toggle
                        checked={permission.isSupportUser}
                        ariaLabel={t(i18nKeys.events.eventDetails.labels.supportPermission)}
                        onChange={() => togglePermission(permission, i, EventPermissionType.SUPPORT)}
                        disabled={
                          i === 0 ||
                          permission.isFacilitator ||
                          permission.isOwner ||
                          !target?.canEditAttribute('eventPermissions', user) ||
                          isGuestUserAndEditModeEnabled
                        }
                      />{' '}
                    </div>
                  )}
                  {canEdit && !isEventCreator(permission.email) && i !== 0 && (
                    <div style={{ textAlign: 'center' }} onClick={() => removeEmail(permission.email)}>
                      <Icon name="close" />
                    </div>
                  )}
                </Grid>
              </div>
            )
          );
        })}
        {!canEdit && (
          <div className="table-row-border">
            <SpaceBetween direction="horizontal" size="s">
              <Button iconName="copy" onClick={copyEventOwnerEmails}>
                {isEvent
                  ? t(i18nKeys.events.eventDetails.labels.eventPermissions.buttons.copyEventOwnerEmails)
                  : t(i18nKeys.campaigns.buttons.copyCampaignOwnerEmails)}
              </Button>
              <Button iconName="copy" onClick={copyFacilitatorEmails}>
                {t(i18nKeys.events.eventDetails.labels.eventPermissions.buttons.copyFacilitatorEmails)}
              </Button>
            </SpaceBetween>
          </div>
        )}
      </div>
    );
  };

  return !newEventMode && !newCampaignMode ? (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.ownersAndPermissions)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.ownersAndPermissions)}</Header>}>
      {renderContent()}
    </Container>
  );
};
export default OwnerPermissions;
