import React, { Dispatch, SetStateAction } from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { JAM_CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { useHistory, useParams } from 'react-router-dom';
import { Icon } from '@amzn/awsui-components-react';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

interface JamTaskStatusModalProps {
  isCompleted: boolean;
  setTaskCompleted: Dispatch<SetStateAction<boolean>>;
}

const JamTaskStatusModal: React.FC<JamTaskStatusModalProps> = ({ isCompleted, setTaskCompleted }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { eventSlug } = useJamEventDetails();
  const params: { 0: string; 1: string } = useParams();
  const [visible, setVisible] = React.useState(true);
  const { modals } = i18nKeys.myJams.challenges.tabs;

  const handleClose = () => {
    setVisible(false);
    setTaskCompleted(false);
    if (isCompleted) {
      history.push(JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventSlug, params[1]]));
    }
  };

  return (
    <Modal
      onDismiss={handleClose}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <div id="taskCompletedCloseButton" data-testid="taskCompletedCloseButton" onClick={handleClose}>
              <Button variant="primary">{t(i18nKeys.general.close)}</Button>
            </div>
          </SpaceBetween>
        </Box>
      }
      header={isCompleted ? t(modals.completedTask.title) : t(modals.pendingTask.title)}>
      {isCompleted ? (
        <SpaceBetween direction="horizontal" size="xs">
          <Box color="text-status-success">
            <Icon name="status-positive" />
          </Box>
          <Box>{t(modals.completedTask.body)}</Box>
        </SpaceBetween>
      ) : (
        t(modals.pendingTask.body)
      )}
    </Modal>
  );
};

export default JamTaskStatusModal;
