import React, { useEffect, useState } from 'react';
import { useCountdown } from '@/src/hooks/useCountdown';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface CountDownProps {
  targetDate: number;
  format?: 'DHMS' | 'D_h:m:s';
  onExpire?: () => void;
}

const CountDown: React.FC<CountDownProps> = (params) => {
  const { targetDate, onExpire, format = 'DHMS' } = params;
  const { t } = useTranslation();
  const [expired, setExpired] = useState(false);
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      setExpired(true);
      onExpire?.();
    }
  }, [days, hours, minutes, seconds]);

  if (expired) {
    return <span className="time-expired">{t(i18nKeys.general.timeExpired)}</span>;
  }

  if (format === 'D_h:m:s') {
    return (
      <span className="countdown">
        {days ? `${days} days ` : ''} {hours}:{minutes}:{seconds}
      </span>
    );
  }
  return (
    <span className="countdown">
      {days ? `${days} days ` : ''} {hours} hrs {minutes} min {seconds} sec
    </span>
  );
};

export default CountDown;
