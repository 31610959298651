import { ChallengeStatus } from '../types/Challenge';
import { EventDurationType, IEventTemplate } from '../types/EventTemplate';
import { LabProvider } from '../types/LabProvider';

export const mockEventTemplates = [
  {
    name: 'Monolithic Problems',
    duration: EventDurationType.MEDIUM,
    price: 49,
    id: '26110e0f-2348-44ef-bbaa-c3fb429b7404',
    createDate: '2023-08-17T11:23:52.138Z',
    lastUpdatedDate: '2023-08-17T11:23:52.138Z',
    challenges: [
      {
        id: 'DEVOPS1',
        rationale:
          "1. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
      {
        id: 'ecmp-vpn',
        rationale:
          "2. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
      {
        id: 're2017sje-clone-3',
        rationale:
          "3. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
      {
        id: 're2017sje-iso27001',
        rationale:
          "4. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
      {
        id: 're2017sje-clone-6',
        rationale:
          "5. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
      {
        id: 'nonadmin-2-sanity-05-14',
        rationale:
          "6. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
      {
        id: 're2017sje-clone-5',
        rationale:
          "7. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
      {
        id: 're2017sje',
        rationale:
          "8. Jimbo's Jamcurity is a trusted name in Incident Response. They need your help to keep that reputation alive!",
      },
    ],
    status: 'private',
    summary:
      'Scenario: Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
    topics: ['2', '3', '4'],
    recommendedTeamSize: 4,
    minParticipants: 2,
    maxParticipants: 200,
    rating: 0,
    tags: ['1'],
    challengeList: [
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'DEVOPS1',
        version: 31,
        minorVersion: 1,
        majorVersion: 4,
        avgStackDeployTime: 146809,
        avgDeployResolveTime: 250812,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'DEVOPS1',
          rangeKey: null,
          eventName: '869a94e8-321b-449c-8ff0-ebd8c31908be',
          challengeVersion: 31,
          challengeMajorVersion: 4,
          challengeMinorVersion: 1,
          challengeCfnHash: '61b296ef9dd28ef4f5feeb7034812744',
          labProvider: 'EVENT_ENGINE',
          lastUpdated: 1624550193458,
          deploymentRequestTime: 1624550193456,
          deploymentResolveTime: 1624550428950,
          deploymentResolutionDuration: 235494,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606222723541,
        props: {
          category: 'DevOps',
          difficulty: 1,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [
            'Amazon Elastic Container Service (ECS)',
            'Amazon Elastic Compute Cloud (EC2) Auto Scaling',
            'Amazon Elastic Transcoder',
            'Amazon Kinesis Data Firehose',
            'Amazon Simple Notification Service (SNS)',
            'Amazon WorkSpaces Application Manager',
            'AWS AppSync',
          ],
          jamType: 'All In',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-2', 'us-east-2', 'us-east-1', 'us-west-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 10,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Blue/Green Deployments of Serverless Applications',
          description: 'REDACTED',
          tasks: [
            {
              id: 'aa5db29e-eb8a-4148-8d1a-bf25de86fb54',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['6', '12345', 'aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: 'In this challenge you can learn about the details of blue/green deployments',
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.96,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 'DEVOPS1',
          rating: 2.7777777,
          ratingCount: 27,
          difficultyRating: 3.2222223,
          jamsUsed: 2,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 1,
          firstUsed: 1539984886000,
          lastUsed: 1604408973248,
          lastSolved: 1592494121953,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592494121953,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 9,
            lowestSolveTime: {
              timeSolved: 1592494121953,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592494121953,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'DevOps',
        difficulty: 1,
        challengeIcon: null,
        awsServices: [
          'Amazon Elastic Container Service (ECS)',
          'Amazon Elastic Compute Cloud (EC2) Auto Scaling',
          'Amazon Elastic Transcoder',
          'Amazon Kinesis Data Firehose',
          'Amazon Simple Notification Service (SNS)',
          'Amazon WorkSpaces Application Manager',
          'AWS AppSync',
        ],
        jamType: 'All In',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-2', 'us-east-2', 'us-east-1', 'us-west-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 10,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Blue/Green Deployments of Serverless Applications',
        description: 'REDACTED',
        tasks: [
          {
            id: 'aa5db29e-eb8a-4148-8d1a-bf25de86fb54',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['6', '12345', 'aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: 'In this challenge you can learn about the details of blue/green deployments',
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 2.7777777,
        ratingCount: 27,
        difficultyRating: 3.2222223,
        jamsUsed: 2,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 1,
        firstUsed: 1539984886000,
        lastUsed: 1604408973248,
        lastSolved: 1592494121953,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592494121953,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 9,
          lowestSolveTime: {
            timeSolved: 1592494121953,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592494121953,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'ecmp-vpn',
        version: 185,
        minorVersion: 0,
        majorVersion: 9,
        avgStackDeployTime: 512497,
        avgDeployResolveTime: 591560,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'ecmp-vpn',
          rangeKey: null,
          eventName: 'c5a6315b-04d4-4bf7-98c9-908ae9cac037',
          challengeVersion: 185,
          challengeMajorVersion: 9,
          challengeMinorVersion: 0,
          challengeCfnHash: 'a92019a4c4321677b6e8786647dbce14',
          labProvider: 'AWS_LABS',
          lastUpdated: 1690556987508,
          deploymentRequestTime: 1690556416451,
          deploymentResolveTime: 1690556900904,
          deploymentResolutionDuration: 484453,
          stackStartTime: 1690556467131,
          stackCompleteTime: 1690556486628,
          stackDuration: 19497,
          stackStatus: 'CREATE_COMPLETE',
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1670448298056,
        props: {
          category: 'Networking',
          difficulty: 1,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: ['Amazon Virtual Private Cloud (VPC)'],
          jamType: 'All In',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: 'AWS_LABS',
          regionAllowlist: [
            'eu-west-2',
            'eu-west-1',
            'ap-southeast-1',
            'ap-southeast-2',
            'ap-northeast-2',
            'eu-central-1',
            'ap-northeast-1',
            'us-east-1',
            'us-east-2',
            'us-west-1',
            'us-west-2',
          ],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Enlarging Capacity Must Proceed for Very Performant Networking',
          description: 'REDACTED',
          tasks: [
            {
              id: 'dbdf55e7-1bf9-40fa-959f-836feaecc90f',
              taskNumber: 1,
              scorePercent: 40,
              dependsOnTaskIds: [],
              title: 'Complete VPN configuration',
              content: 'REDACTED',
              description: null,
              background:
                'Your colleague had begun the process of adding a second VPN connection to your Transit Gateway, but seems to have broken connectivity via the existing VPN in the process.',
              gettingStarted:
                'To complete this task, you will need to establish connectivity between the AWS VPC and the on-premises network via both Site-to-Site VPNs.',
              inventory:
                'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
              awsServicesUsed: [],
              validationDescription:
                'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen.',
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'python3.8',
              validationFunction: 'REDACTED',
            },
            {
              id: '91086c33-b1a1-4ee1-b459-f12d7fd57145',
              taskNumber: 2,
              scorePercent: 30,
              dependsOnTaskIds: ['dbdf55e7-1bf9-40fa-959f-836feaecc90f'],
              title: 'Load-balance traffic from from data centre to AWS',
              content: 'REDACTED',
              description: null,
              background:
                'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from the on-premises network to AWS across the four IPsec tunnels. As IP routing is inherently asymmetric, it is the on-premises router that decides which tunnel(s) are used to send traffic to AWS based on the attributes of routes the on-premises router receives from AWS, as well as its own configuration.',
              gettingStarted:
                "As AWS treats all tunnels equally, the attributes used by the on-premises router to determine the best path are already identical. To complete this task, you will therefore need to ensure that the on-premises router is configured appropriately to use all **four** tunnels.\nYou may want to read the following articles to learn more about configuring BGP to use multiple paths. Don't forget you can access the Quagga shell by running `sudo vtysh` on `onprem-cgw`.\n* https://networklessons.com/cisco/ccie-routing-switching-written/bgp-multipath-load-sharing-ibgp-and-ebgp\n* https://www.noction.com/blog/equal-cost-multipath-ecmp\nThe default Linux kernel configuration only hashes IP flows using IP addresses. There is a `sysctl` (not to be confused with `systemctl`!) parameter to control this — `net.ipv4.fib_multipath_hash_policy`. You'll need to change this from Layer 3 to Layer 4.\n* https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/7/html/7.6_release_notes/new_features_networking\n* https://cumulusnetworks.com/blog/celebrating-ecmp-part-two/",
              inventory:
                'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
              awsServicesUsed: [],
              validationDescription:
                'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen. Note that this task uses CloudWatch metrics to ensure that traffic from the data centre to the VPC is correctly load balanced, and these metrics are only updated once per minute. Move on to task 3 while you are waiting for these metrics to update.',
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'python3.8',
              validationFunction: 'REDACTED',
            },
            {
              id: '6ee918be-8b81-4eb7-8ad6-bb99261278b0',
              taskNumber: 3,
              scorePercent: 30,
              dependsOnTaskIds: ['91086c33-b1a1-4ee1-b459-f12d7fd57145'],
              title: 'Load-balance traffic from AWS to data centre',
              content: 'REDACTED',
              description: null,
              background:
                'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from AWS to the on-premises network across the four IPsec tunnels. As IP routing is inherently asymmetric, it is AWS that decides which tunnel(s) are used to send traffic to the on-premises network based on the attributes of routes AWS receives from the on-premises network.',
              gettingStarted:
                'To complete this task, you will need to ensure that the routes being advertised by the on-premises router have identical attributes.',
              inventory:
                'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
              awsServicesUsed: [],
              validationDescription:
                'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the “Check my progress” button on the task details screen.',
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'python3.8',
              validationFunction: 'REDACTED',
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['vpn', 'networking', 'transit-gateway', 'ecmp', 'used-by-training-certification'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary:
              'This challenge will help you learn how to scale AWS Site-to-Site VPN using AWS Transit Gateway and Equal-Cost Multi-Path (ECMP) routing.',
            introduction:
              'This challenge demonstrates how to scale up AWS Site-to-Site VPN by deploying multiple connections in parallel using equal-cost multi-path (ECMP) routing.\nIn this challenge you will learn how to configure AWS Transit Gateway and an on-premises customer gateway device to load-balance traffic across a pair of Site-to-Site VPN connections.',
            topicsCovered:
              'By solving and walking through this challenge, you will be able to:\n- Complete configuration of an AWS Site-to-Site VPN connection\n- Load-balance multiple AWS Site-to-Site VPN connections using AWS Transit Gateway',
            technicalKnowledgePrerequisites:
              'To successfully complete the challenge, you should be familiar with AWS Site-to-Site VPN and AWS Transit Gateway basics. You will also need basic Linux command-line experience, including editing configuration files and restarting services.',
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.99,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 'ecmp-vpn',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 4,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1636565774472,
          lastUsed: 1645044717621,
          lastSolved: 1636395326138,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1615360435997,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633015393345,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633617124310,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633618619341,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633958585223,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633964644029,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634056024588,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634395442719,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634399168751,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634400684697,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634402684635,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634799439146,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634800037768,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634815816855,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634818803757,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634819521369,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634822129015,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634973061196,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635429895618,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635477782783,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635478081562,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635482409595,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635487201774,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635498085097,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635500225310,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635781789304,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635785999157,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635787431875,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635790799136,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635832562030,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635953039209,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635969306324,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635970201248,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635981573680,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635994804761,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636036393171,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636043952739,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636049411494,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636082056982,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636092970189,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636094249101,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636116313768,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636132206478,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636219381360,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636277159647,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636360860256,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636392603180,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636395326138,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 8697,
            lowestSolveTime: {
              timeSolved: 1615360435997,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1635969306324,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Networking',
        difficulty: 1,
        challengeIcon: null,
        awsServices: ['Amazon Virtual Private Cloud (VPC)'],
        jamType: 'All In',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: 'AWS_LABS',
        regionAllowlist: [
          'eu-west-2',
          'eu-west-1',
          'ap-southeast-1',
          'ap-southeast-2',
          'ap-northeast-2',
          'eu-central-1',
          'ap-northeast-1',
          'us-east-1',
          'us-east-2',
          'us-west-1',
          'us-west-2',
        ],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Enlarging Capacity Must Proceed for Very Performant Networking',
        description: 'REDACTED',
        tasks: [
          {
            id: 'dbdf55e7-1bf9-40fa-959f-836feaecc90f',
            taskNumber: 1,
            scorePercent: 40,
            dependsOnTaskIds: [],
            title: 'Complete VPN configuration',
            content: 'REDACTED',
            description: null,
            background:
              'Your colleague had begun the process of adding a second VPN connection to your Transit Gateway, but seems to have broken connectivity via the existing VPN in the process.',
            gettingStarted:
              'To complete this task, you will need to establish connectivity between the AWS VPC and the on-premises network via both Site-to-Site VPNs.',
            inventory:
              'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
            awsServicesUsed: [],
            validationDescription:
              'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen.',
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'python3.8',
            validationFunction: 'REDACTED',
          },
          {
            id: '91086c33-b1a1-4ee1-b459-f12d7fd57145',
            taskNumber: 2,
            scorePercent: 30,
            dependsOnTaskIds: ['dbdf55e7-1bf9-40fa-959f-836feaecc90f'],
            title: 'Load-balance traffic from from data centre to AWS',
            content: 'REDACTED',
            description: null,
            background:
              'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from the on-premises network to AWS across the four IPsec tunnels. As IP routing is inherently asymmetric, it is the on-premises router that decides which tunnel(s) are used to send traffic to AWS based on the attributes of routes the on-premises router receives from AWS, as well as its own configuration.',
            gettingStarted:
              "As AWS treats all tunnels equally, the attributes used by the on-premises router to determine the best path are already identical. To complete this task, you will therefore need to ensure that the on-premises router is configured appropriately to use all **four** tunnels.\nYou may want to read the following articles to learn more about configuring BGP to use multiple paths. Don't forget you can access the Quagga shell by running `sudo vtysh` on `onprem-cgw`.\n* https://networklessons.com/cisco/ccie-routing-switching-written/bgp-multipath-load-sharing-ibgp-and-ebgp\n* https://www.noction.com/blog/equal-cost-multipath-ecmp\nThe default Linux kernel configuration only hashes IP flows using IP addresses. There is a `sysctl` (not to be confused with `systemctl`!) parameter to control this — `net.ipv4.fib_multipath_hash_policy`. You'll need to change this from Layer 3 to Layer 4.\n* https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/7/html/7.6_release_notes/new_features_networking\n* https://cumulusnetworks.com/blog/celebrating-ecmp-part-two/",
            inventory:
              'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
            awsServicesUsed: [],
            validationDescription:
              'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen. Note that this task uses CloudWatch metrics to ensure that traffic from the data centre to the VPC is correctly load balanced, and these metrics are only updated once per minute. Move on to task 3 while you are waiting for these metrics to update.',
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'python3.8',
            validationFunction: 'REDACTED',
          },
          {
            id: '6ee918be-8b81-4eb7-8ad6-bb99261278b0',
            taskNumber: 3,
            scorePercent: 30,
            dependsOnTaskIds: ['91086c33-b1a1-4ee1-b459-f12d7fd57145'],
            title: 'Load-balance traffic from AWS to data centre',
            content: 'REDACTED',
            description: null,
            background:
              'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from AWS to the on-premises network across the four IPsec tunnels. As IP routing is inherently asymmetric, it is AWS that decides which tunnel(s) are used to send traffic to the on-premises network based on the attributes of routes AWS receives from the on-premises network.',
            gettingStarted:
              'To complete this task, you will need to ensure that the routes being advertised by the on-premises router have identical attributes.',
            inventory:
              'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
            awsServicesUsed: [],
            validationDescription:
              'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the “Check my progress” button on the task details screen.',
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'python3.8',
            validationFunction: 'REDACTED',
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['vpn', 'networking', 'transit-gateway', 'ecmp', 'used-by-training-certification'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary:
            'This challenge will help you learn how to scale AWS Site-to-Site VPN using AWS Transit Gateway and Equal-Cost Multi-Path (ECMP) routing.',
          introduction:
            'This challenge demonstrates how to scale up AWS Site-to-Site VPN by deploying multiple connections in parallel using equal-cost multi-path (ECMP) routing.\nIn this challenge you will learn how to configure AWS Transit Gateway and an on-premises customer gateway device to load-balance traffic across a pair of Site-to-Site VPN connections.',
          topicsCovered:
            'By solving and walking through this challenge, you will be able to:\n- Complete configuration of an AWS Site-to-Site VPN connection\n- Load-balance multiple AWS Site-to-Site VPN connections using AWS Transit Gateway',
          technicalKnowledgePrerequisites:
            'To successfully complete the challenge, you should be familiar with AWS Site-to-Site VPN and AWS Transit Gateway basics. You will also need basic Linux command-line experience, including editing configuration files and restarting services.',
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 4,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1636565774472,
        lastUsed: 1645044717621,
        lastSolved: 1636395326138,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1615360435997,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633015393345,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633617124310,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633618619341,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633958585223,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633964644029,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634056024588,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634395442719,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634399168751,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634400684697,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634402684635,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634799439146,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634800037768,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634815816855,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634818803757,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634819521369,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634822129015,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634973061196,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635429895618,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635477782783,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635478081562,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635482409595,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635487201774,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635498085097,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635500225310,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635781789304,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635785999157,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635787431875,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635790799136,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635832562030,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635953039209,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635969306324,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635970201248,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635981573680,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635994804761,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636036393171,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636043952739,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636049411494,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636082056982,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636092970189,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636094249101,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636116313768,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636132206478,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636219381360,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636277159647,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636360860256,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636392603180,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636395326138,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 8697,
          lowestSolveTime: {
            timeSolved: 1615360435997,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1635969306324,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-3',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 211389,
        avgDeployResolveTime: 319172,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-3',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237711712,
          deploymentRequestTime: 1606237711712,
          deploymentResolveTime: 1606238072758,
          deploymentResolutionDuration: 361046,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221632230,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-1', 'us-east-2', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-3',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-3',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-1', 'us-east-2', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-3',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-iso27001',
        version: 76,
        minorVersion: 1,
        majorVersion: 4,
        avgStackDeployTime: 208153,
        avgDeployResolveTime: 315597,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-iso27001',
          rangeKey: null,
          eventName: '8d86f489-e89b-4f87-9494-a14e615f075d',
          challengeVersion: 76,
          challengeMajorVersion: 4,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'EVENT_ENGINE',
          lastUpdated: 1606246591361,
          deploymentRequestTime: 1606246591360,
          deploymentResolveTime: 1606246828772,
          deploymentResolutionDuration: 237412,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1593528746964,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1'],
          regionDenylist: [],
          sshKeyPairRequired: true,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Achieving ISO 27001 Certification',
          description: 'REDACTED',
          tasks: [
            {
              id: '7d0bdbcd-3fa9-409a-ac80-c4c63ffb9a1b',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: null,
          tags: [],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: 'i want to save this later',
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-iso27001',
          rating: 2.3448277,
          ratingCount: 29,
          difficultyRating: 2.7931035,
          jamsUsed: 4,
          totalIncorrect: 0,
          totalRequestedClues: 3,
          totalCompletedTasks: 0,
          passRate: 1,
          firstUsed: 1552423780000,
          lastUsed: 1594225161789,
          lastSolved: 1594131362687,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1552423857000,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1552670472000,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1592493960510,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1594131362687,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 182,
            lowestSolveTime: {
              timeSolved: 1552423857000,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493960510,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1'],
        regionDenylist: [],
        sshKeyPairRequired: true,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Achieving ISO 27001 Certification',
        description: 'REDACTED',
        tasks: [
          {
            id: '7d0bdbcd-3fa9-409a-ac80-c4c63ffb9a1b',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: null,
        tags: [],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: 'i want to save this later',
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 2.3448277,
        ratingCount: 29,
        difficultyRating: 2.7931035,
        jamsUsed: 4,
        totalIncorrect: 0,
        totalRequestedClues: 3,
        totalCompletedTasks: 0,
        passRate: 1,
        firstUsed: 1552423780000,
        lastUsed: 1594225161789,
        lastSolved: 1594131362687,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1552423857000,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1552670472000,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1592493960510,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1594131362687,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 182,
          lowestSolveTime: {
            timeSolved: 1552423857000,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493960510,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-6',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 207753,
        avgDeployResolveTime: 316268,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-6',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237708314,
          deploymentRequestTime: 1606237708314,
          deploymentResolveTime: 1606238072483,
          deploymentResolutionDuration: 364169,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221474797,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-west-2', 'us-east-2', 'us-east-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-6',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-6',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-west-2', 'us-east-2', 'us-east-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-6',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'nonadmin-2-sanity-05-14',
        version: 24,
        minorVersion: 1,
        majorVersion: 5,
        avgStackDeployTime: 26826,
        avgDeployResolveTime: 139173,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'nonadmin-2-sanity-05-14',
          rangeKey: null,
          eventName: '69cd6d1f-ed0d-46e7-af51-70c2fab1fcee',
          challengeVersion: 24,
          challengeMajorVersion: 5,
          challengeMinorVersion: 1,
          challengeCfnHash: '8e39545709e9878d17528601d972bd2a',
          labProvider: 'AWS_LABS',
          lastUpdated: 1683025426581,
          deploymentRequestTime: 1683025216859,
          deploymentResolveTime: 1683025358463,
          deploymentResolutionDuration: 141604,
          stackStartTime: 1683025220335,
          stackCompleteTime: 1683025246206,
          stackDuration: 25871,
          stackStatus: 'CREATE_COMPLETE',
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1656506622538,
        props: {
          category: '?',
          difficulty: 0,
          challengeAlwaysOn: true,
          challengeIcon: null,
          awsServices: ['Alexa for Business', 'Amazon Simple Email Service (SES)', 'AWS CloudFormation'],
          jamType: 'Analytics',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: 'AWS_LABS',
          regionAllowlist: ['us-west-2', 'us-east-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Dummy Challenge (Deploys Labs)',
          description: 'REDACTED',
          tasks: [
            {
              id: '7e50a0c1-9b88-440b-a02d-c7f9c626b149',
              taskNumber: 1,
              scorePercent: 50,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: 'REDACTED',
            },
            {
              id: '5dbce54a-863f-4f2c-be45-0078cf214743',
              taskNumber: 2,
              scorePercent: 50,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: 'REDACTED',
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: [],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: 'Challenge learning outcome',
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: 'Some next steps.',
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 1,
        approved: null,
        challengeAlwaysOn: true,
        globalStatistics: {
          challengeId: 'nonadmin-2-sanity-05-14',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 5,
          totalIncorrect: 1,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1654605966229,
          lastUsed: 1670598168364,
          lastSolved: null,
          solveTimes: {
            recentSolveTimes: [],
            trimmedAvgSeconds: null,
            lowestSolveTime: null,
            highestSolveTime: null,
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: '?',
        difficulty: 0,
        challengeIcon: null,
        awsServices: ['Alexa for Business', 'Amazon Simple Email Service (SES)', 'AWS CloudFormation'],
        jamType: 'Analytics',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: 'AWS_LABS',
        regionAllowlist: ['us-west-2', 'us-east-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Dummy Challenge (Deploys Labs)',
        description: 'REDACTED',
        tasks: [
          {
            id: '7e50a0c1-9b88-440b-a02d-c7f9c626b149',
            taskNumber: 1,
            scorePercent: 50,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: 'REDACTED',
          },
          {
            id: '5dbce54a-863f-4f2c-be45-0078cf214743',
            taskNumber: 2,
            scorePercent: 50,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: 'REDACTED',
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: [],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: 'Challenge learning outcome',
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: 'Some next steps.',
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 5,
        totalIncorrect: 1,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1654605966229,
        lastUsed: 1670598168364,
        lastSolved: null,
        solveTimes: {
          recentSolveTimes: [],
          trimmedAvgSeconds: null,
          lowestSolveTime: null,
          highestSolveTime: null,
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-5',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 206594,
        avgDeployResolveTime: 330403,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-5',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237948418,
          deploymentRequestTime: 1606237948418,
          deploymentResolveTime: 1606238174943,
          deploymentResolutionDuration: 226525,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221520310,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-5',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-5',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-5',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 219781,
        avgDeployResolveTime: 324445,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237708327,
          deploymentRequestTime: 1606237708327,
          deploymentResolveTime: 1606238072600,
          deploymentResolutionDuration: 364273,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221717618,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje',
          rating: 2.9382715,
          ratingCount: 81,
          difficultyRating: 2.8271606,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1558718075000,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 2.9382715,
        ratingCount: 81,
        difficultyRating: 2.8271606,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1558718075000,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
    ],
    images: [
      {
        name: 'cards-mobile.png',
        src: 'https://picsum.photos/id/555/200/300',
      },
    ],
  },
  {
    name: 'Intern Panic',
    duration: EventDurationType.MEDIUM,
    price: 49,
    id: 'a7c8ec67-c57e-4bb2-9c1a-b8a948bfa953',
    createDate: '2023-08-17T11:25:25.699Z',
    lastUpdatedDate: '2023-08-17T11:25:25.699Z',
    challenges: [
      {
        id: 're2017sje-clone-7',
        rationale:
          '1. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 'dummy-challenge',
        rationale:
          '2. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 'DEVOPS1',
        rationale:
          '3. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 'ecmp-vpn',
        rationale:
          '4. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 're2017sje-clone-3',
        rationale:
          '5. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 're2017sje-iso27001',
        rationale:
          '6. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 're2017sje-clone-6',
        rationale:
          '7. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 'nonadmin-2-sanity-05-14',
        rationale:
          '8. Jamcorp has been a trusted name in business for 20 years. Can you help Jamcorp migrate to the modern era and modernize their applications?',
      },
      {
        id: 're2017sje',
        rationale: '',
      },
      {
        id: 're2017sje-clone-5',
        rationale: '',
      },
      {
        id: 're2017sje-clone-2',
        rationale: '',
      },
      {
        id: 'SSM4',
        rationale: '',
      },
      {
        id: 're2017sje-clone-1',
        rationale: '',
      },
    ],
    status: 'private',
    summary:
      "Is this a bad dream? It's your first day on the job and no one else showed up to work. Get ready to get your feet wet with a variety of services!",
    topics: ['2', '3', '1'],
    recommendedTeamSize: 5,
    minParticipants: 4,
    maxParticipants: 10,
    rating: 0,
    tags: ['1'],
    challengeList: [
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-7',
        version: 15,
        minorVersion: 5,
        majorVersion: 3,
        avgStackDeployTime: 219134,
        avgDeployResolveTime: 320191,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-7',
          rangeKey: null,
          eventName: '394ff5f0-2ed4-491e-8581-1547431297ac',
          challengeVersion: 14,
          challengeMajorVersion: 3,
          challengeMinorVersion: 4,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1630610781206,
          deploymentRequestTime: 1630610781155,
          deploymentResolveTime: 1630611025378,
          deploymentResolutionDuration: 244223,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1633982348188,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: 'AWS_LABS',
          regionAllowlist: ['us-west-1', 'us-west-2', 'us-east-2', 'us-east-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-7',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-enabled'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.97,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-7',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 2,
          highestIssueSeverity: 'CRITICAL',
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: 'AWS_LABS',
        regionAllowlist: ['us-west-1', 'us-west-2', 'us-east-2', 'us-east-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-7',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-enabled'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 2,
        highestIssueSeverity: 'CRITICAL',
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'dummy-challenge',
        version: 78,
        minorVersion: 1,
        majorVersion: 12,
        avgStackDeployTime: 26207,
        avgDeployResolveTime: 139996,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'dummy-challenge',
          rangeKey: null,
          eventName: 'c5a6315b-04d4-4bf7-98c9-908ae9cac037',
          challengeVersion: 78,
          challengeMajorVersion: 12,
          challengeMinorVersion: 1,
          challengeCfnHash: '38c49d534d18e1a6e66648faa8e89fb8',
          labProvider: 'AWS_LABS',
          lastUpdated: 1690556626707,
          deploymentRequestTime: 1690556415171,
          deploymentResolveTime: 1690556560921,
          deploymentResolutionDuration: 145750,
          stackStartTime: 1690556451933,
          stackCompleteTime: 1690556469461,
          stackDuration: 17528,
          stackStatus: 'CREATE_COMPLETE',
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1680800313730,
        props: {
          category: null,
          difficulty: 1,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: null,
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: 'AWS_LABS',
          regionAllowlist: ['us-west-2', 'us-east-1', 'us-east-2', 'eu-central-1', 'ap-southeast-2'],
          regionDenylist: [],
          sshKeyPairRequired: true,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Dummy Challenge v3',
          description: 'REDACTED',
          tasks: [
            {
              id: '60f538f2-2480-4f69-803d-6ddb6f2c9016',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: 'Modified title 1',
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs14.x',
              validationFunction: 'REDACTED',
            },
            {
              id: '43cba63b-d568-4720-9a7a-8e80ab287f24',
              taskNumber: 2,
              scorePercent: 0,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'nodejs14.x',
              validationFunction: 'REDACTED',
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['testing-review'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: '### Markdown Test\n<h3>HTML Test</h3>\n<b>Bold.</b>',
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 'dummy-challenge',
          rating: 5,
          ratingCount: 3,
          difficultyRating: 5,
          jamsUsed: 5,
          totalIncorrect: 2,
          totalRequestedClues: 2,
          totalCompletedTasks: 0,
          passRate: 0.8,
          firstUsed: 1643812408712,
          lastUsed: 1674186352124,
          lastSolved: 1674186448199,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1643812453607,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1643814253846,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1670599292838,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1674186448199,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 49,
            lowestSolveTime: {
              timeSolved: 1643814253846,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1674186448199,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 1,
          highestIssueSeverity: 'CRITICAL',
        },
        category: null,
        difficulty: 1,
        challengeIcon: null,
        awsServices: [],
        jamType: null,
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: 'AWS_LABS',
        regionAllowlist: ['us-west-2', 'us-east-1', 'us-east-2', 'eu-central-1', 'ap-southeast-2'],
        regionDenylist: [],
        sshKeyPairRequired: true,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Dummy Challenge v3',
        description: 'REDACTED',
        tasks: [
          {
            id: '60f538f2-2480-4f69-803d-6ddb6f2c9016',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: 'Modified title 1',
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs14.x',
            validationFunction: 'REDACTED',
          },
          {
            id: '43cba63b-d568-4720-9a7a-8e80ab287f24',
            taskNumber: 2,
            scorePercent: 0,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'nodejs14.x',
            validationFunction: 'REDACTED',
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['testing-review'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: '### Markdown Test\n<h3>HTML Test</h3>\n<b>Bold.</b>',
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 5,
        ratingCount: 3,
        difficultyRating: 5,
        jamsUsed: 5,
        totalIncorrect: 2,
        totalRequestedClues: 2,
        totalCompletedTasks: 0,
        passRate: 0.8,
        firstUsed: 1643812408712,
        lastUsed: 1674186352124,
        lastSolved: 1674186448199,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1643812453607,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1643814253846,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1670599292838,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1674186448199,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 49,
          lowestSolveTime: {
            timeSolved: 1643814253846,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1674186448199,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 1,
        highestIssueSeverity: 'CRITICAL',
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'DEVOPS1',
        version: 31,
        minorVersion: 1,
        majorVersion: 4,
        avgStackDeployTime: 146809,
        avgDeployResolveTime: 250812,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'DEVOPS1',
          rangeKey: null,
          eventName: '869a94e8-321b-449c-8ff0-ebd8c31908be',
          challengeVersion: 31,
          challengeMajorVersion: 4,
          challengeMinorVersion: 1,
          challengeCfnHash: '61b296ef9dd28ef4f5feeb7034812744',
          labProvider: 'EVENT_ENGINE',
          lastUpdated: 1624550193458,
          deploymentRequestTime: 1624550193456,
          deploymentResolveTime: 1624550428950,
          deploymentResolutionDuration: 235494,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606222723541,
        props: {
          category: 'DevOps',
          difficulty: 1,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [
            'Amazon Elastic Container Service (ECS)',
            'Amazon Elastic Compute Cloud (EC2) Auto Scaling',
            'Amazon Elastic Transcoder',
            'Amazon Kinesis Data Firehose',
            'Amazon Simple Notification Service (SNS)',
            'Amazon WorkSpaces Application Manager',
            'AWS AppSync',
          ],
          jamType: 'All In',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-2', 'us-east-2', 'us-east-1', 'us-west-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 10,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Blue/Green Deployments of Serverless Applications',
          description: 'REDACTED',
          tasks: [
            {
              id: 'aa5db29e-eb8a-4148-8d1a-bf25de86fb54',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['6', '12345', 'aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: 'In this challenge you can learn about the details of blue/green deployments',
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.96,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 'DEVOPS1',
          rating: 2.7777777,
          ratingCount: 27,
          difficultyRating: 3.2222223,
          jamsUsed: 2,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 1,
          firstUsed: 1539984886000,
          lastUsed: 1604408973248,
          lastSolved: 1592494121953,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592494121953,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 9,
            lowestSolveTime: {
              timeSolved: 1592494121953,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592494121953,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'DevOps',
        difficulty: 1,
        challengeIcon: null,
        awsServices: [
          'Amazon Elastic Container Service (ECS)',
          'Amazon Elastic Compute Cloud (EC2) Auto Scaling',
          'Amazon Elastic Transcoder',
          'Amazon Kinesis Data Firehose',
          'Amazon Simple Notification Service (SNS)',
          'Amazon WorkSpaces Application Manager',
          'AWS AppSync',
        ],
        jamType: 'All In',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-2', 'us-east-2', 'us-east-1', 'us-west-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 10,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Blue/Green Deployments of Serverless Applications',
        description: 'REDACTED',
        tasks: [
          {
            id: 'aa5db29e-eb8a-4148-8d1a-bf25de86fb54',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['6', '12345', 'aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: 'In this challenge you can learn about the details of blue/green deployments',
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 2.7777777,
        ratingCount: 27,
        difficultyRating: 3.2222223,
        jamsUsed: 2,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 1,
        firstUsed: 1539984886000,
        lastUsed: 1604408973248,
        lastSolved: 1592494121953,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592494121953,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 9,
          lowestSolveTime: {
            timeSolved: 1592494121953,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592494121953,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'ecmp-vpn',
        version: 185,
        minorVersion: 0,
        majorVersion: 9,
        avgStackDeployTime: 512497,
        avgDeployResolveTime: 591560,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'ecmp-vpn',
          rangeKey: null,
          eventName: 'c5a6315b-04d4-4bf7-98c9-908ae9cac037',
          challengeVersion: 185,
          challengeMajorVersion: 9,
          challengeMinorVersion: 0,
          challengeCfnHash: 'a92019a4c4321677b6e8786647dbce14',
          labProvider: 'AWS_LABS',
          lastUpdated: 1690556987508,
          deploymentRequestTime: 1690556416451,
          deploymentResolveTime: 1690556900904,
          deploymentResolutionDuration: 484453,
          stackStartTime: 1690556467131,
          stackCompleteTime: 1690556486628,
          stackDuration: 19497,
          stackStatus: 'CREATE_COMPLETE',
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1670448298056,
        props: {
          category: 'Networking',
          difficulty: 1,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: ['Amazon Virtual Private Cloud (VPC)'],
          jamType: 'All In',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: 'AWS_LABS',
          regionAllowlist: [
            'eu-west-2',
            'eu-west-1',
            'ap-southeast-1',
            'ap-southeast-2',
            'ap-northeast-2',
            'eu-central-1',
            'ap-northeast-1',
            'us-east-1',
            'us-east-2',
            'us-west-1',
            'us-west-2',
          ],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Enlarging Capacity Must Proceed for Very Performant Networking',
          description: 'REDACTED',
          tasks: [
            {
              id: 'dbdf55e7-1bf9-40fa-959f-836feaecc90f',
              taskNumber: 1,
              scorePercent: 40,
              dependsOnTaskIds: [],
              title: 'Complete VPN configuration',
              content: 'REDACTED',
              description: null,
              background:
                'Your colleague had begun the process of adding a second VPN connection to your Transit Gateway, but seems to have broken connectivity via the existing VPN in the process.',
              gettingStarted:
                'To complete this task, you will need to establish connectivity between the AWS VPC and the on-premises network via both Site-to-Site VPNs.',
              inventory:
                'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
              awsServicesUsed: [],
              validationDescription:
                'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen.',
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'python3.8',
              validationFunction: 'REDACTED',
            },
            {
              id: '91086c33-b1a1-4ee1-b459-f12d7fd57145',
              taskNumber: 2,
              scorePercent: 30,
              dependsOnTaskIds: ['dbdf55e7-1bf9-40fa-959f-836feaecc90f'],
              title: 'Load-balance traffic from from data centre to AWS',
              content: 'REDACTED',
              description: null,
              background:
                'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from the on-premises network to AWS across the four IPsec tunnels. As IP routing is inherently asymmetric, it is the on-premises router that decides which tunnel(s) are used to send traffic to AWS based on the attributes of routes the on-premises router receives from AWS, as well as its own configuration.',
              gettingStarted:
                "As AWS treats all tunnels equally, the attributes used by the on-premises router to determine the best path are already identical. To complete this task, you will therefore need to ensure that the on-premises router is configured appropriately to use all **four** tunnels.\nYou may want to read the following articles to learn more about configuring BGP to use multiple paths. Don't forget you can access the Quagga shell by running `sudo vtysh` on `onprem-cgw`.\n* https://networklessons.com/cisco/ccie-routing-switching-written/bgp-multipath-load-sharing-ibgp-and-ebgp\n* https://www.noction.com/blog/equal-cost-multipath-ecmp\nThe default Linux kernel configuration only hashes IP flows using IP addresses. There is a `sysctl` (not to be confused with `systemctl`!) parameter to control this — `net.ipv4.fib_multipath_hash_policy`. You'll need to change this from Layer 3 to Layer 4.\n* https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/7/html/7.6_release_notes/new_features_networking\n* https://cumulusnetworks.com/blog/celebrating-ecmp-part-two/",
              inventory:
                'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
              awsServicesUsed: [],
              validationDescription:
                'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen. Note that this task uses CloudWatch metrics to ensure that traffic from the data centre to the VPC is correctly load balanced, and these metrics are only updated once per minute. Move on to task 3 while you are waiting for these metrics to update.',
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'python3.8',
              validationFunction: 'REDACTED',
            },
            {
              id: '6ee918be-8b81-4eb7-8ad6-bb99261278b0',
              taskNumber: 3,
              scorePercent: 30,
              dependsOnTaskIds: ['91086c33-b1a1-4ee1-b459-f12d7fd57145'],
              title: 'Load-balance traffic from AWS to data centre',
              content: 'REDACTED',
              description: null,
              background:
                'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from AWS to the on-premises network across the four IPsec tunnels. As IP routing is inherently asymmetric, it is AWS that decides which tunnel(s) are used to send traffic to the on-premises network based on the attributes of routes AWS receives from the on-premises network.',
              gettingStarted:
                'To complete this task, you will need to ensure that the routes being advertised by the on-premises router have identical attributes.',
              inventory:
                'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
              awsServicesUsed: [],
              validationDescription:
                'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the “Check my progress” button on the task details screen.',
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'python3.8',
              validationFunction: 'REDACTED',
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['vpn', 'networking', 'transit-gateway', 'ecmp', 'used-by-training-certification'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary:
              'This challenge will help you learn how to scale AWS Site-to-Site VPN using AWS Transit Gateway and Equal-Cost Multi-Path (ECMP) routing.',
            introduction:
              'This challenge demonstrates how to scale up AWS Site-to-Site VPN by deploying multiple connections in parallel using equal-cost multi-path (ECMP) routing.\nIn this challenge you will learn how to configure AWS Transit Gateway and an on-premises customer gateway device to load-balance traffic across a pair of Site-to-Site VPN connections.',
            topicsCovered:
              'By solving and walking through this challenge, you will be able to:\n- Complete configuration of an AWS Site-to-Site VPN connection\n- Load-balance multiple AWS Site-to-Site VPN connections using AWS Transit Gateway',
            technicalKnowledgePrerequisites:
              'To successfully complete the challenge, you should be familiar with AWS Site-to-Site VPN and AWS Transit Gateway basics. You will also need basic Linux command-line experience, including editing configuration files and restarting services.',
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.99,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 'ecmp-vpn',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 4,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1636565774472,
          lastUsed: 1645044717621,
          lastSolved: 1636395326138,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1615360435997,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633015393345,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633617124310,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633618619341,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633958585223,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1633964644029,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634056024588,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634395442719,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634399168751,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634400684697,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634402684635,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634799439146,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634800037768,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634815816855,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634818803757,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634819521369,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634822129015,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1634973061196,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635429895618,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635477782783,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635478081562,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635482409595,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635487201774,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635498085097,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635500225310,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635781789304,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635785999157,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635787431875,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635790799136,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635832562030,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635953039209,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635969306324,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635970201248,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635981573680,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1635994804761,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636036393171,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636043952739,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636049411494,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636082056982,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636092970189,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636094249101,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636116313768,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636132206478,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636219381360,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636277159647,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636360860256,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636392603180,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1636395326138,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 8697,
            lowestSolveTime: {
              timeSolved: 1615360435997,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1635969306324,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Networking',
        difficulty: 1,
        challengeIcon: null,
        awsServices: ['Amazon Virtual Private Cloud (VPC)'],
        jamType: 'All In',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: 'AWS_LABS',
        regionAllowlist: [
          'eu-west-2',
          'eu-west-1',
          'ap-southeast-1',
          'ap-southeast-2',
          'ap-northeast-2',
          'eu-central-1',
          'ap-northeast-1',
          'us-east-1',
          'us-east-2',
          'us-west-1',
          'us-west-2',
        ],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Enlarging Capacity Must Proceed for Very Performant Networking',
        description: 'REDACTED',
        tasks: [
          {
            id: 'dbdf55e7-1bf9-40fa-959f-836feaecc90f',
            taskNumber: 1,
            scorePercent: 40,
            dependsOnTaskIds: [],
            title: 'Complete VPN configuration',
            content: 'REDACTED',
            description: null,
            background:
              'Your colleague had begun the process of adding a second VPN connection to your Transit Gateway, but seems to have broken connectivity via the existing VPN in the process.',
            gettingStarted:
              'To complete this task, you will need to establish connectivity between the AWS VPC and the on-premises network via both Site-to-Site VPNs.',
            inventory:
              'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
            awsServicesUsed: [],
            validationDescription:
              'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen.',
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'python3.8',
            validationFunction: 'REDACTED',
          },
          {
            id: '91086c33-b1a1-4ee1-b459-f12d7fd57145',
            taskNumber: 2,
            scorePercent: 30,
            dependsOnTaskIds: ['dbdf55e7-1bf9-40fa-959f-836feaecc90f'],
            title: 'Load-balance traffic from from data centre to AWS',
            content: 'REDACTED',
            description: null,
            background:
              'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from the on-premises network to AWS across the four IPsec tunnels. As IP routing is inherently asymmetric, it is the on-premises router that decides which tunnel(s) are used to send traffic to AWS based on the attributes of routes the on-premises router receives from AWS, as well as its own configuration.',
            gettingStarted:
              "As AWS treats all tunnels equally, the attributes used by the on-premises router to determine the best path are already identical. To complete this task, you will therefore need to ensure that the on-premises router is configured appropriately to use all **four** tunnels.\nYou may want to read the following articles to learn more about configuring BGP to use multiple paths. Don't forget you can access the Quagga shell by running `sudo vtysh` on `onprem-cgw`.\n* https://networklessons.com/cisco/ccie-routing-switching-written/bgp-multipath-load-sharing-ibgp-and-ebgp\n* https://www.noction.com/blog/equal-cost-multipath-ecmp\nThe default Linux kernel configuration only hashes IP flows using IP addresses. There is a `sysctl` (not to be confused with `systemctl`!) parameter to control this — `net.ipv4.fib_multipath_hash_policy`. You'll need to change this from Layer 3 to Layer 4.\n* https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/7/html/7.6_release_notes/new_features_networking\n* https://cumulusnetworks.com/blog/celebrating-ecmp-part-two/",
            inventory:
              'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
            awsServicesUsed: [],
            validationDescription:
              'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the "Check my progress" button on the task details screen. Note that this task uses CloudWatch metrics to ensure that traffic from the data centre to the VPC is correctly load balanced, and these metrics are only updated once per minute. Move on to task 3 while you are waiting for these metrics to update.',
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'python3.8',
            validationFunction: 'REDACTED',
          },
          {
            id: '6ee918be-8b81-4eb7-8ad6-bb99261278b0',
            taskNumber: 3,
            scorePercent: 30,
            dependsOnTaskIds: ['91086c33-b1a1-4ee1-b459-f12d7fd57145'],
            title: 'Load-balance traffic from AWS to data centre',
            content: 'REDACTED',
            description: null,
            background:
              'Now that you have both tunnels of each Site-to-Site VPN connections up, you need to load-balance traffic from AWS to the on-premises network across the four IPsec tunnels. As IP routing is inherently asymmetric, it is AWS that decides which tunnel(s) are used to send traffic to the on-premises network based on the attributes of routes AWS receives from the on-premises network.',
            gettingStarted:
              'To complete this task, you will need to ensure that the routes being advertised by the on-premises router have identical attributes.',
            inventory:
              'A VPC has been created to simulate the on-premises environment (`onprem`). You do not need to make any changes to this VPC, but you should understand it contains an EC2 instance to represent the customer router (`onprem-cgw`) and an EC2 instance (`onprem-testhost`) configured to send test traffic towards a corresponding EC2 instance (`vpc-testhost`) in the AWS VPC (`vpc`).\nThe `onprem-cgw` instance is running strongSwan to terminate the IPsec tunnels from AWS, and Quagga to exchange routing information over the tunnels using BGP. Shell access to `onprem-cgw` is available via AWS Systems Manager Session Manager only — you cannot SSH to this instance. You cannot access either of the test hosts via Session Manager.',
            awsServicesUsed: [],
            validationDescription:
              'The task will be automatically marked complete once you apply the correct solution. You can also check your progress manually by pressing the “Check my progress” button on the task details screen.',
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'python3.8',
            validationFunction: 'REDACTED',
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['vpn', 'networking', 'transit-gateway', 'ecmp', 'used-by-training-certification'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary:
            'This challenge will help you learn how to scale AWS Site-to-Site VPN using AWS Transit Gateway and Equal-Cost Multi-Path (ECMP) routing.',
          introduction:
            'This challenge demonstrates how to scale up AWS Site-to-Site VPN by deploying multiple connections in parallel using equal-cost multi-path (ECMP) routing.\nIn this challenge you will learn how to configure AWS Transit Gateway and an on-premises customer gateway device to load-balance traffic across a pair of Site-to-Site VPN connections.',
          topicsCovered:
            'By solving and walking through this challenge, you will be able to:\n- Complete configuration of an AWS Site-to-Site VPN connection\n- Load-balance multiple AWS Site-to-Site VPN connections using AWS Transit Gateway',
          technicalKnowledgePrerequisites:
            'To successfully complete the challenge, you should be familiar with AWS Site-to-Site VPN and AWS Transit Gateway basics. You will also need basic Linux command-line experience, including editing configuration files and restarting services.',
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 4,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1636565774472,
        lastUsed: 1645044717621,
        lastSolved: 1636395326138,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1615360435997,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633015393345,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633617124310,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633618619341,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633958585223,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1633964644029,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634056024588,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634395442719,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634399168751,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634400684697,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634402684635,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634799439146,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634800037768,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634815816855,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634818803757,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634819521369,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634822129015,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1634973061196,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635429895618,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635477782783,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635478081562,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635482409595,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635487201774,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635498085097,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635500225310,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635781789304,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635785999157,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635787431875,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635790799136,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635832562030,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635953039209,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635969306324,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635970201248,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635981573680,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1635994804761,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636036393171,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636043952739,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636049411494,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636082056982,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636092970189,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636094249101,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636116313768,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636132206478,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636219381360,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636277159647,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636360860256,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636392603180,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1636395326138,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 8697,
          lowestSolveTime: {
            timeSolved: 1615360435997,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1635969306324,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-3',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 211389,
        avgDeployResolveTime: 319172,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-3',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237711712,
          deploymentRequestTime: 1606237711712,
          deploymentResolveTime: 1606238072758,
          deploymentResolutionDuration: 361046,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221632230,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-1', 'us-east-2', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-3',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-3',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-1', 'us-east-2', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-3',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-iso27001',
        version: 76,
        minorVersion: 1,
        majorVersion: 4,
        avgStackDeployTime: 208153,
        avgDeployResolveTime: 315597,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-iso27001',
          rangeKey: null,
          eventName: '8d86f489-e89b-4f87-9494-a14e615f075d',
          challengeVersion: 76,
          challengeMajorVersion: 4,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'EVENT_ENGINE',
          lastUpdated: 1606246591361,
          deploymentRequestTime: 1606246591360,
          deploymentResolveTime: 1606246828772,
          deploymentResolutionDuration: 237412,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1593528746964,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1'],
          regionDenylist: [],
          sshKeyPairRequired: true,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Achieving ISO 27001 Certification',
          description: 'REDACTED',
          tasks: [
            {
              id: '7d0bdbcd-3fa9-409a-ac80-c4c63ffb9a1b',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: null,
          tags: [],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: 'i want to save this later',
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-iso27001',
          rating: 2.3448277,
          ratingCount: 29,
          difficultyRating: 2.7931035,
          jamsUsed: 4,
          totalIncorrect: 0,
          totalRequestedClues: 3,
          totalCompletedTasks: 0,
          passRate: 1,
          firstUsed: 1552423780000,
          lastUsed: 1594225161789,
          lastSolved: 1594131362687,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1552423857000,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1552670472000,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1592493960510,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
              {
                timeSolved: 1594131362687,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 182,
            lowestSolveTime: {
              timeSolved: 1552423857000,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493960510,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1'],
        regionDenylist: [],
        sshKeyPairRequired: true,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Achieving ISO 27001 Certification',
        description: 'REDACTED',
        tasks: [
          {
            id: '7d0bdbcd-3fa9-409a-ac80-c4c63ffb9a1b',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: null,
        tags: [],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: 'i want to save this later',
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 2.3448277,
        ratingCount: 29,
        difficultyRating: 2.7931035,
        jamsUsed: 4,
        totalIncorrect: 0,
        totalRequestedClues: 3,
        totalCompletedTasks: 0,
        passRate: 1,
        firstUsed: 1552423780000,
        lastUsed: 1594225161789,
        lastSolved: 1594131362687,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1552423857000,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1552670472000,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1592493960510,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            {
              timeSolved: 1594131362687,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 182,
          lowestSolveTime: {
            timeSolved: 1552423857000,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493960510,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-6',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 207753,
        avgDeployResolveTime: 316268,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-6',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237708314,
          deploymentRequestTime: 1606237708314,
          deploymentResolveTime: 1606238072483,
          deploymentResolutionDuration: 364169,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221474797,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-west-2', 'us-east-2', 'us-east-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-6',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-6',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-west-2', 'us-east-2', 'us-east-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-6',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'nonadmin-2-sanity-05-14',
        version: 24,
        minorVersion: 1,
        majorVersion: 5,
        avgStackDeployTime: 26826,
        avgDeployResolveTime: 139173,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'nonadmin-2-sanity-05-14',
          rangeKey: null,
          eventName: '69cd6d1f-ed0d-46e7-af51-70c2fab1fcee',
          challengeVersion: 24,
          challengeMajorVersion: 5,
          challengeMinorVersion: 1,
          challengeCfnHash: '8e39545709e9878d17528601d972bd2a',
          labProvider: 'AWS_LABS',
          lastUpdated: 1683025426581,
          deploymentRequestTime: 1683025216859,
          deploymentResolveTime: 1683025358463,
          deploymentResolutionDuration: 141604,
          stackStartTime: 1683025220335,
          stackCompleteTime: 1683025246206,
          stackDuration: 25871,
          stackStatus: 'CREATE_COMPLETE',
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1656506622538,
        props: {
          category: '?',
          difficulty: 0,
          challengeAlwaysOn: true,
          challengeIcon: null,
          awsServices: ['Alexa for Business', 'Amazon Simple Email Service (SES)', 'AWS CloudFormation'],
          jamType: 'Analytics',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: 'AWS_LABS',
          regionAllowlist: ['us-west-2', 'us-east-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Dummy Challenge (Deploys Labs)',
          description: 'REDACTED',
          tasks: [
            {
              id: '7e50a0c1-9b88-440b-a02d-c7f9c626b149',
              taskNumber: 1,
              scorePercent: 50,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'LAMBDA_FUNCTION',
              answerOutputParamName: null,
              globalStaticAnswer: null,
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: 'REDACTED',
            },
            {
              id: '5dbce54a-863f-4f2c-be45-0078cf214743',
              taskNumber: 2,
              scorePercent: 50,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: 'REDACTED',
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: [],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: 'Challenge learning outcome',
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: 'Some next steps.',
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 1,
        approved: null,
        challengeAlwaysOn: true,
        globalStatistics: {
          challengeId: 'nonadmin-2-sanity-05-14',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 5,
          totalIncorrect: 1,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1654605966229,
          lastUsed: 1670598168364,
          lastSolved: null,
          solveTimes: {
            recentSolveTimes: [],
            trimmedAvgSeconds: null,
            lowestSolveTime: null,
            highestSolveTime: null,
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: '?',
        difficulty: 0,
        challengeIcon: null,
        awsServices: ['Alexa for Business', 'Amazon Simple Email Service (SES)', 'AWS CloudFormation'],
        jamType: 'Analytics',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: 'AWS_LABS',
        regionAllowlist: ['us-west-2', 'us-east-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Dummy Challenge (Deploys Labs)',
        description: 'REDACTED',
        tasks: [
          {
            id: '7e50a0c1-9b88-440b-a02d-c7f9c626b149',
            taskNumber: 1,
            scorePercent: 50,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'LAMBDA_FUNCTION',
            answerOutputParamName: null,
            globalStaticAnswer: null,
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: 'REDACTED',
          },
          {
            id: '5dbce54a-863f-4f2c-be45-0078cf214743',
            taskNumber: 2,
            scorePercent: 50,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: 'REDACTED',
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: [],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: 'Challenge learning outcome',
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: 'Some next steps.',
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 5,
        totalIncorrect: 1,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1654605966229,
        lastUsed: 1670598168364,
        lastSolved: null,
        solveTimes: {
          recentSolveTimes: [],
          trimmedAvgSeconds: null,
          lowestSolveTime: null,
          highestSolveTime: null,
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 219781,
        avgDeployResolveTime: 324445,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237708327,
          deploymentRequestTime: 1606237708327,
          deploymentResolveTime: 1606238072600,
          deploymentResolutionDuration: 364273,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221717618,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje',
          rating: 2.9382715,
          ratingCount: 81,
          difficultyRating: 2.8271606,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1558718075000,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 2.9382715,
        ratingCount: 81,
        difficultyRating: 2.8271606,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1558718075000,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-5',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 206594,
        avgDeployResolveTime: 330403,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-5',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237948418,
          deploymentRequestTime: 1606237948418,
          deploymentResolveTime: 1606238174943,
          deploymentResolutionDuration: 226525,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221520310,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-5',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-5',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-5',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-2',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 191805,
        avgDeployResolveTime: 315286,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-2',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237952130,
          deploymentRequestTime: 1606237952129,
          deploymentResolveTime: 1606238175375,
          deploymentResolutionDuration: 223246,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221661225,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-2',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-2',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-2',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 'SSM4',
        version: 12,
        minorVersion: 1,
        majorVersion: 4,
        avgStackDeployTime: 248168,
        avgDeployResolveTime: 357209,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 'SSM4',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 12,
          challengeMajorVersion: 4,
          challengeMinorVersion: 1,
          challengeCfnHash: '91f39ea822a42ff3fa7395b73c0414b5',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237709041,
          deploymentRequestTime: 1606237709041,
          deploymentResolveTime: 1606238072637,
          deploymentResolutionDuration: 363596,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606222814853,
        props: {
          category: 'Monitoring',
          difficulty: 1,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Lounge',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['ap-southeast-2', 'us-east-1', 'us-east-2', 'us-west-2', 'us-west-1'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Be an EC2 lumberjack!!',
          description: 'REDACTED',
          tasks: [
            {
              id: '849ec194-75a8-484b-aa1d-8f9298a2f078',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 'SSM4',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592493276144,
          lastUsed: 1592493276375,
          lastSolved: 1592493307952,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493307952,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 31,
            lowestSolveTime: {
              timeSolved: 1592493307952,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493307952,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Monitoring',
        difficulty: 1,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Lounge',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['ap-southeast-2', 'us-east-1', 'us-east-2', 'us-west-2', 'us-west-1'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Be an EC2 lumberjack!!',
        description: 'REDACTED',
        tasks: [
          {
            id: '849ec194-75a8-484b-aa1d-8f9298a2f078',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592493276144,
        lastUsed: 1592493276375,
        lastSolved: 1592493307952,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493307952,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 31,
          lowestSolveTime: {
            timeSolved: 1592493307952,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493307952,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
      {
        isArchived: false,
        isPublic: false,
        isDemo: false,
        isDefective: false,
        challengeId: 're2017sje-clone-1',
        version: 11,
        minorVersion: 1,
        majorVersion: 3,
        avgStackDeployTime: 208757,
        avgDeployResolveTime: 304520,
        avgDeployTimesLastUpdated: 1687478414602,
        lastSuccessfulDeployment: {
          challengeId: 're2017sje-clone-1',
          rangeKey: null,
          eventName: '8020c0c3-ea8c-49a3-80d0-b7ba52faec6c',
          challengeVersion: 11,
          challengeMajorVersion: 3,
          challengeMinorVersion: 1,
          challengeCfnHash: '068a3648de76ca66e74da31a02702a88',
          labProvider: 'AWS_LABS',
          lastUpdated: 1606237950093,
          deploymentRequestTime: 1606237950092,
          deploymentResolveTime: 1606238177097,
          deploymentResolutionDuration: 227005,
          stackStartTime: null,
          stackCompleteTime: null,
          stackDuration: null,
          stackStatus: null,
          stackStatusReason: null,
          deployedSuccessfully: true,
          metadata: {},
        },
        status: ChallengeStatus.APPROVED,
        barRaiserReviewStatus: null,
        createdDate: 1606221683516,
        props: {
          category: 'Remediation',
          difficulty: 2,
          challengeAlwaysOn: false,
          challengeIcon: null,
          awsServices: [],
          jamType: 'Security',
          codeWhispererDisabled: false,
          mode: 'TRADITIONAL',
          defaultLabProvider: LabProvider.EVENT_ENGINE,
          regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
          regionDenylist: [],
          sshKeyPairRequired: false,
          customKeyPairName: null,
          allowlistServicesRequired: [],
          idleMinsBeforeReady: 0,
          castleDefenseDetails: {
            provider: 'GAMEDAY',
            templateId: null,
          },
          sponsor: null,
          lastEditedBy: null,
          lastUpdatedBy: null,
          lastApprovedBy: null,
          reviewer: null,
          barRaiser: null,
          owner: null,
          maintainers: [],
          title: 'Meeting 800-53 - clone-1',
          description: 'REDACTED',
          tasks: [
            {
              id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
              taskNumber: 1,
              scorePercent: 100,
              dependsOnTaskIds: [],
              title: null,
              content: 'REDACTED',
              description: null,
              background: null,
              gettingStarted: null,
              inventory: null,
              awsServicesUsed: [],
              validationDescription: null,
              clues: [],
              validationType: 'GLOBAL_STATIC_ANSWER',
              answerOutputParamName: null,
              globalStaticAnswer: 'REDACTED',
              validationFunctionRuntime: 'nodejs12.x',
              validationFunction: null,
            },
          ],
          cfnTemplate: 'REDACTED',
          studentPolicy: 'REDACTED',
          tags: ['aws-labs-candidate'],
          wiki: null,
          facilitatorNotes: null,
          comments: [],
          learningOutcome: {
            summary: null,
            introduction: null,
            topicsCovered: null,
            technicalKnowledgePrerequisites: null,
          },
          nextSteps: null,
          difficultyDesc: null,
          searchableText: [],
        },
        pointsPossible: 0,
        taskScoring: [],
        stability: 0.98,
        approved: null,
        challengeAlwaysOn: false,
        globalStatistics: {
          challengeId: 're2017sje-clone-1',
          rating: 0,
          ratingCount: 0,
          difficultyRating: 0,
          jamsUsed: 0,
          totalIncorrect: 0,
          totalRequestedClues: 0,
          totalCompletedTasks: 0,
          passRate: 0,
          firstUsed: 1592492987485,
          lastUsed: 1592492987485,
          lastSolved: 1592493095417,
          solveTimes: {
            recentSolveTimes: [
              {
                timeSolved: 1592493095417,
                numSeconds: null,
                eventName: null,
                teamName: null,
                participantLogin: null,
              },
            ],
            trimmedAvgSeconds: 108,
            lowestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
            highestSolveTime: {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          },
          averageCostPerHour: null,
          unresolvedChallengeIssues: 0,
          highestIssueSeverity: null,
        },
        category: 'Remediation',
        difficulty: 2,
        challengeIcon: null,
        awsServices: [],
        jamType: 'Security',
        codeWhispererDisabled: false,
        mode: 'TRADITIONAL',
        defaultLabProvider: LabProvider.EVENT_ENGINE,
        regionAllowlist: ['us-west-1', 'us-east-2', 'us-east-1', 'us-west-2'],
        regionDenylist: [],
        sshKeyPairRequired: false,
        customKeyPairName: null,
        allowlistServicesRequired: [],
        idleMinsBeforeReady: 0,
        castleDefenseDetails: {
          provider: 'GAMEDAY',
          templateId: null,
        },
        sponsor: null,
        lastEditedBy: null,
        lastUpdatedBy: null,
        lastApprovedBy: null,
        reviewer: null,
        barRaiser: null,
        owner: null,
        maintainers: [],
        title: 'Meeting 800-53 - clone-1',
        description: 'REDACTED',
        tasks: [
          {
            id: '7ab60ec5-3c59-4e04-b8ce-6babeefdc8b7',
            taskNumber: 1,
            scorePercent: 100,
            dependsOnTaskIds: [],
            title: null,
            content: 'REDACTED',
            description: null,
            background: null,
            gettingStarted: null,
            inventory: null,
            awsServicesUsed: [],
            validationDescription: null,
            clues: [],
            validationType: 'GLOBAL_STATIC_ANSWER',
            answerOutputParamName: null,
            globalStaticAnswer: 'REDACTED',
            validationFunctionRuntime: 'nodejs12.x',
            validationFunction: null,
          },
        ],
        cfnTemplate: 'REDACTED',
        studentPolicy: 'REDACTED',
        tags: ['aws-labs-candidate'],
        wiki: null,
        facilitatorNotes: null,
        comments: [],
        learningOutcome: {
          summary: null,
          introduction: null,
          topicsCovered: null,
          technicalKnowledgePrerequisites: null,
        },
        nextSteps: null,
        difficultyDesc: null,
        searchableText: [],
        rating: 0,
        ratingCount: 0,
        difficultyRating: 0,
        jamsUsed: 0,
        totalIncorrect: 0,
        totalRequestedClues: 0,
        totalCompletedTasks: 0,
        passRate: 0,
        firstUsed: 1592492987485,
        lastUsed: 1592492987485,
        lastSolved: 1592493095417,
        solveTimes: {
          recentSolveTimes: [
            {
              timeSolved: 1592493095417,
              numSeconds: null,
              eventName: null,
              teamName: null,
              participantLogin: null,
            },
          ],
          trimmedAvgSeconds: 108,
          lowestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
          highestSolveTime: {
            timeSolved: 1592493095417,
            numSeconds: null,
            eventName: null,
            teamName: null,
            participantLogin: null,
          },
        },
        averageCostPerHour: null,
        unresolvedChallengeIssues: 0,
        highestIssueSeverity: null,
      },
    ],
    images: [
      {
        name: 'cards-mobile.png',
        src: 'https://picsum.photos/id/2/200/300',
      },
    ],
  },
  {
    name: 'Security Heroes.',
    duration: EventDurationType.MEDIUM,

    price: 6,
    id: '1da45d4a-0d5f-4229-b24d-8304dcb460a6',
    createDate: '2023-07-05T12:41:23.479Z',
    lastUpdatedDate: '2023-07-05T12:41:23.479Z',
    challenges: [
      {
        id: 're2017sje-iso27001',
        rationale: '',
      },
      {
        id: 'DEVOPS1',
        rationale: '',
      },
      {
        id: 'nonadmin-2-sanity-05-14',
        rationale: '',
      },
      {
        id: 're2017sje-clone-3',
        rationale: '',
      },
      {
        id: 'test-validation-functions',
        rationale: '',
      },
      {
        id: 're2017sje-clone-6',
        rationale: '',
      },
      {
        id: 're2017sje-clone-7',
        rationale: '',
      },
      {
        id: 'ecmp-vpn',
        rationale: '',
      },
    ],
    status: 'published',
    summary: 'Atque accusantium vel harum necessitatibus. Corrupti molestias exercitationem voluptatibus similique.',
    topics: ['10', '20', '10', '31'],
    recommendedTeamSize: 4,
    minParticipants: 2,
    maxParticipants: 200,
    rating: 0,
    images: [
      {
        name: 'cards-mobile.png',
        src: 'https://picsum.photos/id/3/200/300',
      },
    ],
    tags: [],
    challengeList: [],
  },
  {
    name: 'Journey to the Cloud',
    duration: EventDurationType.LONG,

    price: 8,
    id: 'd3325095-75b2-4584-98a4-0ab5461deb85',
    createDate: '2023-07-05T12:41:23.479Z',
    lastUpdatedDate: '2023-07-05T12:41:23.479Z',
    challenges: [
      {
        id: 're2017sje-iso27001',
        rationale: '',
      },
      {
        id: 're2017sje-clone-7',
        rationale: '',
      },
      {
        id: 're2017sje-clone-6',
        rationale: '',
      },
      {
        id: 'nonadmin-2-sanity-05-14',
        rationale: '',
      },
      {
        id: 'test-validation-functions',
        rationale: '',
      },
      {
        id: 'DEVOPS1',
        rationale: '',
      },
      {
        id: 're2017sje-clone-3',
        rationale: '',
      },
      {
        id: 'ecmp-vpn',
        rationale: '',
      },
    ],
    status: 'published',
    summary: 'Molestiae tempore ex distinctio facilis. Totam a nisi.',
    topics: ['19', '6', '29'],
    recommendedTeamSize: 4,
    minParticipants: 2,
    maxParticipants: 200,
    rating: 5,
    images: [
      {
        name: 'cards-mobile.png',
        src: 'https://picsum.photos/id/4/200/300',
      },
    ],
    tags: [],
    challengeList: [],
  },
  {
    name: 'Defend the Cloud',
    duration: EventDurationType.LONG,

    price: 96,
    id: '2d0ddff0-3ac4-4f00-af1c-a56b94b911a1',
    createDate: '2023-07-05T12:41:23.479Z',
    lastUpdatedDate: '2023-07-05T12:41:23.479Z',
    challenges: [
      {
        id: 'DEVOPS1',
        rationale: '',
      },
      {
        id: 're2017sje-iso27001',
        rationale: '',
      },
      {
        id: 'ecmp-vpn',
        rationale: '',
      },
      {
        id: 're2017sje-clone-7',
        rationale: '',
      },
      {
        id: 'nonadmin-2-sanity-05-14',
        rationale: '',
      },
      {
        id: 'test-validation-functions',
        rationale: '',
      },
      {
        id: 're2017sje-clone-3',
        rationale: '',
      },
      {
        id: 're2017sje-clone-6',
        rationale: '',
      },
    ],
    status: 'published',
    summary:
      'Ipsa qui illo sint assumenda non cupiditate eius optio. Nulla illum aut ipsa saepe libero commodi reiciendis.',
    topics: ['13'],
    recommendedTeamSize: 4,
    minParticipants: 2,
    maxParticipants: 200,
    rating: 0,
    images: [
      {
        name: 'cards-mobile.png',
        src: 'https://picsum.photos/id/5/200/300',
      },
    ],
    tags: [],
    challengeList: [],
  },
  {
    name: 'Networking Black Hole',
    duration: EventDurationType.SHORT,

    price: 60,
    id: '4c7e5481-b074-4845-8aac-054c36cf9e39',
    createDate: '2023-07-05T12:41:23.479Z',
    lastUpdatedDate: '2023-07-05T12:41:23.479Z',
    challenges: [
      {
        id: 'test-validation-functions',
        rationale: '',
      },
      {
        id: 're2017sje-clone-3',
        rationale: '',
      },
      {
        id: 're2017sje-clone-7',
        rationale: '',
      },
      {
        id: 're2017sje-clone-6',
        rationale: '',
      },
      {
        id: 'nonadmin-2-sanity-05-14',
        rationale: '',
      },
      {
        id: 're2017sje-iso27001',
        rationale: '',
      },
      {
        id: 'DEVOPS1',
        rationale: '',
      },
      {
        id: 'ecmp-vpn',
        rationale: '',
      },
    ],
    status: 'published',
    summary:
      'Quas minima veritatis laboriosam alias praesentium vero. Delectus maiores natus natus laboriosam nesciunt maxime fugit.',
    topics: ['31'],
    recommendedTeamSize: 4,
    minParticipants: 2,
    maxParticipants: 200,
    rating: 0,
    images: [
      {
        name: 'cards-mobile.png',
        src: 'https://picsum.photos/id/6/200/300',
      },
    ],
    tags: [],
    challengeList: [],
  },
  {
    name: 'Analytics Event',
    duration: EventDurationType.MEDIUM,

    price: 68,
    id: '3fdca8c0-0ad3-4b22-8a55-3af45d29c389',
    createDate: '2023-07-05T12:41:23.479Z',
    lastUpdatedDate: '2023-07-05T12:41:23.479Z',
    challenges: [
      {
        id: 'test-validation-functions',
        rationale: '',
      },
      {
        id: 'DEVOPS1',
        rationale: '',
      },
      {
        id: 're2017sje-iso27001',
        rationale: '',
      },
      {
        id: 'nonadmin-2-sanity-05-14',
        rationale: '',
      },
      {
        id: 're2017sje-clone-6',
        rationale: '',
      },
      {
        id: 're2017sje-clone-3',
        rationale: '',
      },
      {
        id: 're2017sje-clone-7',
        rationale: '',
      },
      {
        id: 'ecmp-vpn',
        rationale: '',
      },
    ],
    status: 'published',
    summary: 'Vero ipsum enim numquam. Sunt occaecati harum aperiam voluptas laborum illo ipsa dolorum.',
    topics: ['25'],
    recommendedTeamSize: 4,
    minParticipants: 2,
    maxParticipants: 200,
    rating: 2,
    images: [
      {
        name: 'cards-mobile.png',
        src: 'https://picsum.photos/id/77/200/300',
      },
    ],
    tags: [],
    challengeList: [],
  },
];

export const getMockEventTemplates = () => {
  return JSON.parse(JSON.stringify(mockEventTemplates)) as IEventTemplate[];
};
