import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, SpaceBetween } from '@amzn/awsui-components-react';
import Wizard from '@amzn/awsui-components-react/polaris/wizard';
import { LoadingBar } from '../common/LoadingBar';
import { i18nKeys } from '../../utils/i18n.utils';
import ChallengeOverviewSection from './createChallengeSections/ChallengeOverviewSection';
import ChallengeSettings from './createChallengeSections/ChallengeSettingsSection';
import ChallengeLearningOutcomeSection from './createChallengeSections/ChallengeLearningOutcomeSection';
import { useToolPanel } from '../../store/tool-panel.context';
import { ChallengeSections, ChallengeTutorialTabs, useCreateChallenge } from '../../store/create-challenge.context';
import { preProdLogger } from '../../utils/log.utils';
import { useHistory } from 'react-router-dom';
import { customEventTrigger } from '../analytics/createEventTrigger';

const CreateChallenge: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { initializeNewChallenge, handleCreateChallenge, makeHelpPanelHandler, setToolsTab } = useCreateChallenge();
  const { toggleShowToolPanel } = useToolPanel();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  // TODO: pull from react context
  const [loading, setLoading] = useState(false);

  /*
  Each Challenge*Section will return a reference to their validation method (if any)
  to this component which will be used to evaluate if current section is valid.
   */
  let isCurrentSectionValid: () => Promise<boolean>;
  const validationHandler = (validateSection: () => Promise<boolean>) => {
    isCurrentSectionValid = validateSection;
  };

  useEffect(() => {
    if (loading) {
      return () => {
        <LoadingBar />;
      };
    }
  }, [loading]);

  useEffect(() => {
    toggleShowToolPanel(true);
    initializeNewChallenge();
  }, []);

  const steps = [
    {
      section: ChallengeSections.OVERVIEW,
      title: t(i18nKeys.challenges.subSections.overview.title),
      info: (
        <Link variant="info" onFollow={makeHelpPanelHandler(ChallengeSections.OVERVIEW)}>
          {t(i18nKeys.general.info)}
        </Link>
      ),
      content: <ChallengeOverviewSection validationHandler={validationHandler} />,
    },
    {
      section: ChallengeSections.LEARNING_OUTCOME,
      title: t(i18nKeys.challenges.subSections.learningPlan.title),
      info: (
        <Link variant="info" onFollow={makeHelpPanelHandler(ChallengeSections.LEARNING_OUTCOME)}>
          {t(i18nKeys.general.info)}
        </Link>
      ),
      content: <ChallengeLearningOutcomeSection validationHandler={validationHandler} />,
    },
    {
      section: ChallengeSections.SETTINGS,
      title: t(i18nKeys.challenges.subSections.settings.title),
      info: (
        <Link variant="info" onFollow={makeHelpPanelHandler(ChallengeSections.SETTINGS)}>
          {t(i18nKeys.general.info)}
        </Link>
      ),
      content: <ChallengeSettings validationHandler={validationHandler} />,
    },
  ];

  const handleActiveStepIndex = (index: number) => {
    // Allow user to go back to previous steps in-case they wish to change something
    if (index < activeStepIndex) {
      setActiveStepIndex(index);
      return;
    }
    isCurrentSectionValid()
      .then((isValid) => {
        if (isValid) {
          setActiveStepIndex(index);
        }
      })
      .catch((error) => {
        preProdLogger('Could not complete validation.', error);
      });
  };

  const handleOnSubmit = () => {
    isCurrentSectionValid()
      .then((isValid) => {
        if (isValid) {
          customEventTrigger('submit', 'Create Event submit', window.location.href, 'Create Event submit', {});
          setLoading(true);
          handleCreateChallenge()
            .then((c) => {
              if (c) {
                history.push(`${c.challengeId}`);
              }
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              preProdLogger(err);
            });
        }
      })
      .catch((error) => {
        preProdLogger('Could not complete validation.', error);
      });
  };

  return (
    <SpaceBetween size={'m'} direction={'vertical'}>
      <Wizard
        allowSkipTo
        activeStepIndex={activeStepIndex}
        onNavigate={({ detail }) => {
          customEventTrigger('click', 'Next Page', window.location.href, 'Actions - Next Page', {});
          handleActiveStepIndex(detail.requestedStepIndex);
          setToolsTab(ChallengeTutorialTabs.TUTORIAL); // change to tutorial tab when user navigates to next page
        }}
        isLoadingNextStep={loading}
        onSubmit={() => void handleOnSubmit()}
        onCancel={() => {
          customEventTrigger(
            'click',
            'Cancel Create Challenges',
            window.location.href,
            'Actions - Cancel Create Challenges',
            {}
          );
          initializeNewChallenge();
          history.push('/challenges');
        }}
        steps={steps.map((step) => {
          return {
            title: step.title,
            info: step.info,
            content: step.content,
          };
        })}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => t(i18nKeys.general.stepCounter, { count: stepNumber }),
          collapsedStepsLabel: (stepNumber, stepsCount) =>
            t(i18nKeys.general.stepOf, { stepNumber, totalSteps: stepsCount }),
          skipToButtonLabel: (step) => t(i18nKeys.general.skipTo, { stepName: step.title }),
          cancelButton: t(i18nKeys.general.cancel),
          previousButton: t(i18nKeys.general.previous),
          nextButton: t(i18nKeys.general.next),
          submitButton: t(i18nKeys.challenges.createChallenge),
          optional: t(i18nKeys.general.optional),
        }}
      />
    </SpaceBetween>
  );
};

export default CreateChallenge;
