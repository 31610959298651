import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ContentLayout,
  Grid,
  Header,
  Icon,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  Toggle,
} from '@amzn/awsui-components-react';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import { useToolPanel } from '@/src/store/tool-panel.context';
import TeamStatsInfo from '../../ui/molecules/MyJams/Leaderboard/TeamStatsInfo/TeamStatsInfo';
import { COLUMN_DEFINITIONS, COLUMN_DEFINITIONS_CHALLNGES } from './leaderboard.config';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getErrorMessage } from '@/src/utils/errors.utils';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import JamContainer from '../../ui/molecules/JamContainer/JamContainer';
import Confetti from '../../ui/molecules/Confetti/Confetti';
import CountDown from '../../ui/molecules/CountDown/CountDown';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Leaderboard.scss';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useJamLeaderboard } from '@/src/store/jam-leaderboard.context';
import { useMouseActivity } from '@/src/hooks/useMouseActivity';
import { Redirect, useHistory } from 'react-router-dom';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { useJamMyTeam } from '@/src/store/jam-myteam.context';
import { useJamReport } from '@/src/store/jam-report.context';

const pageSize = 10;

const Leaderboard = () => {
  const { t } = useTranslation();
  const {
    toggleShowToolPanel,
    toggleToolPanel,
    setToolPanelWidth,
    setHideAfterClose,
    showToolPanel,
    renderToolPanelContent,
  } = useToolPanel();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const { event, eventName, loadEventDetails } = useJamEventDetails();
  const [myTeamView, setMyTeamView] = useState(!event?.facilitator);
  const { myTeam: jamMyTeam } = useJamMyTeam();
  const {
    leaderboard,
    leaderboardTeamview,
    loadLeaderboardTeamview,
    loadLeaderboard,
    isPresenterMode,
    totalTeams,
    topTeams,
  } = useJamLeaderboard();
  const { addErrorFlashbar, clearFlashbars } = useFlashbars();
  useMouseActivity();
  const history = useHistory();
  const { loadJamReport, reportApiData } = useJamReport();
  const [isLoading, setIsLoading] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const isEnded = !!event?.ended;
  const showWinners = isEnded;
  const endDate = event?.eventEndDate;
  const myTeamId = jamMyTeam?.team.teamId || event?.teamId;

  const teams = useMemo(() => leaderboard || [], [leaderboard]);
  const sortedTeams = useMemo(() => sortBy(topTeams || [], ({ rank }) => rank), [topTeams]);

  /* start of showing/hiding team details panel logic */
  const hideToolPanel = () => {
    toggleShowToolPanel(false);
    toggleToolPanel(false);
    setHideAfterClose(false);
  };

  const setupToolPanel = () => {
    toggleShowToolPanel(true);
    toggleToolPanel(true);
    setToolPanelWidth(400);
    setHideAfterClose(true);
  };

  const showTeamDetails = (selectedTeamName: string | null) => {
    let team;
    if (
      !selectedTeamName ||
      !(team = [...leaderboardTeamview, ...teams, ...topTeams].find(({ teamName }) => teamName === selectedTeamName))
    ) {
      addErrorFlashbar(t(i18nKeys.general.error), t(i18nKeys.jamDashboard.leaderboard.error));
      return;
    }
    if (!showToolPanel) {
      setupToolPanel();
    }
    renderToolPanelContent(
      selectedTeamName,
      <TeamStatsInfo leaderboardInfo={team} teamId={team.teamName} onClose={hideToolPanel} eventName={eventName} />
    );
  };
  /* end of showing/hiding team details panel logic */

  const getTopTeamList = () => {
    if (myTeamView) {
      return leaderboardTeamview;
    }
    return sortedTeams.slice(0, 3);
  };

  const navigateToReport = () => {
    history.push(JAM_EVENT_DETAILS_ROUTES.Report.resolve(eventName));
  };

  const loadLeaderboardList = async (pageIndex: number, showLoader = true) => {
    clearFlashbars();
    setIsLoading(showLoader);
    try {
      await loadLeaderboard(pageIndex + 1, pageSize);
    } catch (e: any) {
      addErrorFlashbar(getErrorMessage(e));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    void loadLeaderboardList(currentPageIndex);
  }, [currentPageIndex]);

  useEffect(() => {
    if (myTeamView && myTeamId) {
      void loadLeaderboardTeamview(myTeamId).catch((e: any) => addErrorFlashbar(getErrorMessage(e)));
    }
  }, [myTeamView, myTeamId]);

  useEffect(() => {
    if (!endDate || isEnded) {
      return;
    }
    const ms = moment(endDate).diff(moment(), 'milliseconds') - 1;
    // refresh event data once event is over so we can show winners
    setTimeout(() => {
      void loadEventDetails(eventName, false);
    }, ms);
  }, [endDate, isEnded, eventName]);

  useEffect(() => {
    setCurrentPageIndex(0);
    if (isPresenterMode) {
      void loadJamReport(eventName);
    }
  }, [isPresenterMode, eventName]);

  useEffect(() => {
    if (isPresenterMode) {
      return;
    }
    const interval = setInterval(() => {
      void loadLeaderboardList(currentPageIndex, false);
    }, 30000);

    return () => clearInterval(interval);
  }, [isPresenterMode, currentPageIndex]);

  useEffect(() => {
    if (!isPresenterMode) {
      return;
    }
    if (carouselIndex === 0) {
      void loadLeaderboardList(0, false);
    } else if (carouselIndex === 1) {
      void loadJamReport(eventName);
    }
  }, [isPresenterMode, carouselIndex]);

  const renderPresenterModeTitle = () => {
    return (
      <SpaceBetween size="m">
        <Box variant="h5" color="text-body-secondary">
          {event?.title}
        </Box>
        <Box variant="h2">
          {carouselIndex === 1
            ? t(i18nKeys.jamDashboard.leaderboard.chalengeStatistics)
            : t(i18nKeys.jamDashboard.leaderboard.title)}
        </Box>
      </SpaceBetween>
    );
  };

  const renderWinnerTeamInfo = (index: number) => {
    const team = sortedTeams[index];
    if (!team) {
      return <div>&nbsp;</div>;
    }

    const cls = ['first', 'second', 'third'][index];

    return (
      <div>
        <div className={`winner-position ${cls}`}>{team.rank}</div>
        <Box fontWeight="bold" color="text-status-info">
          {team.teamDisplayName}
        </Box>
        <Box fontWeight="bold">{team.score} pts</Box>
      </div>
    );
  };

  const renderWinners = () => {
    return (
      <div className="winner-container">
        <JamContainer>
          <div className="greetings">{t(i18nKeys.jamDashboard.leaderboard.greetings.title)}</div>
        </JamContainer>
        <div className="winners-trophy">
          <div className="winners-trophy-inner">
            <img src="/assets/Silver.png" className="silver" alt="silver-trophy-winner" />
            <img src="/assets/Gold.png" className="gold" alt="gold-trophy-winner" />
            <img src="/assets/Bronze.png" className="bronze" alt="bronze-trophy-winner" />
          </div>
        </div>
        <img src="/assets/Podium.png" className="podium-img" alt="podium" />
        <div className="winners-info">
          {renderWinnerTeamInfo(1)}
          {renderWinnerTeamInfo(0)}
          {renderWinnerTeamInfo(2)}
        </div>
      </div>
    );
  };

  const renderBriefTeamList = () => {
    return (
      <Box>
        {getTopTeamList().map(({ teamName, teamDisplayName, rank, score }) => {
          const myTeam = teamName === event?.teamId;
          return (
            <Grid key={teamName} gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
              <div className="my-team">
                {!!myTeam && (
                  <>
                    <Box variant="h4" margin={{ right: 'xs' }}>
                      {t(i18nKeys.jamDashboard.leaderboard.myTeam)}
                    </Box>
                    <Icon name="caret-right-filled" size="small" />
                  </>
                )}
              </div>
              <div className={`row ${myTeam ? 'selected' : ''}`}>
                <div className="box rank">
                  <Box variant="h2">{rank}</Box>
                </div>
                <div className="box team-name flexed">
                  <Link onFollow={() => showTeamDetails(teamName)}>
                    <Box variant="h2" color="text-status-info">
                      {teamDisplayName}
                    </Box>
                  </Link>
                </div>
                <div className="box points">
                  <Box variant="h2">{score > 0 ? score : 0} pts</Box>
                </div>
              </div>
            </Grid>
          );
        })}
      </Box>
    );
  };

  const renderTeam = (index: number) => {
    return (
      <Table
        key={`leaderboad-list-${index}`}
        variant="embedded"
        columnDefinitions={COLUMN_DEFINITIONS(showTeamDetails)}
        items={leaderboard}
        sortingDisabled
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>{t(i18nKeys.jamDashboard.leaderboard.table.emptyMessage)}</b>
            </SpaceBetween>
          </Box>
        }
        loading={isLoading}
      />
    );
  };

  const renderChallengeMetrics = () => {
    return (
      <Table
        key={`challenge-metrics`}
        variant="embedded"
        columnDefinitions={COLUMN_DEFINITIONS_CHALLNGES(reportApiData?.challengeTitles || {})}
        items={reportApiData?.challengeMetrics || []}
        sortingDisabled
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>{t(i18nKeys.jamDashboard.leaderboard.table.emptyMessage)}</b>
            </SpaceBetween>
          </Box>
        }
        loading={isLoading}
      />
    );
  };

  if (!event?.gamified) { // if not gamified redirect the user to challenges page
    return <Redirect to={JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(event?.eventName || '')} />
  }

  return (
    <div className="leaderboard">
      <ContentLayout
        disableOverlap={showWinners || isPresenterMode}
        header={
          <div style={{ position: 'relative' }}>
            <Box margin={{ top: 'l' }}>
              <Header
                variant="h1"
                actions={
                  !isPresenterMode && (
                    <SpaceBetween direction="horizontal" size="s" alignItems="center">
                      {!event?.facilitator && (
                        <Toggle onChange={({ detail }) => setMyTeamView(detail.checked)} checked={myTeamView}>
                          {t(i18nKeys.jamDashboard.leaderboard.toggleButton)}
                        </Toggle>
                      )}
                      {!isEnded && (
                        <div className="report-button">
                          <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => void loadLeaderboardList(currentPageIndex)}>
                            {t(i18nKeys.facilitator.participants.buttons.refresh)}
                          </Button>
                        </div>
                      )}
                      {isEnded && (
                        <div className="report-button">
                          <Button variant="primary" onClick={navigateToReport}>
                            {t(i18nKeys.jamDashboard.leaderboard.viewTeamReport)}
                          </Button>
                        </div>
                      )}
                    </SpaceBetween>
                  )
                }>
                {isPresenterMode ? renderPresenterModeTitle() : t(i18nKeys.jamDashboard.leaderboard.title)}
              </Header>
              {showWinners && renderWinners()}
            </Box>
            {showWinners && <Confetti />}
          </div>
        }>
        <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 12 }]}>
          {!showWinners && !isPresenterMode && (
            <>
              <div className="row">
                <div className="box flexed center timer">
                  <Box variant="h1">{t(i18nKeys.jamDashboard.leaderboard.timeLeft)}: &nbsp;</Box>
                  <CountDown targetDate={endDate ? moment(endDate).valueOf() : 0} />
                </div>
              </div>
              {renderBriefTeamList()}
            </>
          )}
          <Box>
            {isPresenterMode ? (
              <Carousel
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                interval={5000}
                autoPlay
                selectedItem={carouselIndex}
                onChange={setCarouselIndex}
                infiniteLoop>
                {[renderTeam(0), renderChallengeMetrics()]}
              </Carousel>
            ) : (
              renderTeam(0)
            )}
            <div className="row center">
              <div className="box pagination">
                {isPresenterMode ? (
                  <Pagination
                    currentPageIndex={carouselIndex + 1}
                    onChange={({ detail }) => setCarouselIndex(detail.currentPageIndex - 1)}
                    pagesCount={2}
                    ariaLabels={{
                      nextPageLabel: t(i18nKeys.challenges.nextPageLabel),
                      paginationLabel: t(i18nKeys.challenges.paginationLabel),
                      previousPageLabel: t(i18nKeys.challenges.previousPageLabel),
                      pageLabel: (pageNumber) => `page ${pageNumber}`,
                    }}
                  />
                ) : (
                  <Pagination
                    currentPageIndex={currentPageIndex + 1}
                    onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex - 1)}
                    pagesCount={totalTeams}
                    ariaLabels={{
                      nextPageLabel: t(i18nKeys.challenges.nextPageLabel),
                      paginationLabel: t(i18nKeys.challenges.paginationLabel),
                      previousPageLabel: t(i18nKeys.challenges.previousPageLabel),
                      pageLabel: (pageNumber) => `page ${pageNumber}`,
                    }}
                  />
                )}
              </div>
            </div>
          </Box>
        </Grid>
      </ContentLayout>
    </div>
  );
};

export default Leaderboard;
