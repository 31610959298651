/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

export interface DisplayHTMLModalProps {
  title?: string;
  message?: string;
  closeBtnLabel?: string;
  cancelBtnLabel?: string;
  visible: boolean;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  onCancel: () => any;
  onClose: () => any;
}

export const DisplayHTMLModal: React.FC<DisplayHTMLModalProps> = ({
  title,
  message,
  closeBtnLabel,
  cancelBtnLabel,
  visible,
  size,
  onCancel,
  onClose,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      size={size || 'large'}
      header={title || t(i18nKeys.general.confirm)}
      onDismiss={() => onCancel()}
      closeAriaLabel={t(i18nKeys.general.close)}
      footer={
        <Box float="right" className="awsui-util-f-r">
          <Button variant="link" onClick={() => onCancel()}>
            {cancelBtnLabel || t(i18nKeys.general.cancel)}
          </Button>
          <Button disabled={disabled} variant="primary" onClick={() => onClose()}>
            {closeBtnLabel || t(i18nKeys.general.close)}
          </Button>
        </Box>
      }>
      <div style={{ width: '500px', overflow: 'auto' }}>
        <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{message || ''}</ReactMarkdown>
      </div>
    </Modal>
  );
};
