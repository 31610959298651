import React, { useState, useEffect } from 'react';
import {
  ContentLayout,
  SpaceBetween,
  Header,
  Container,
  ColumnLayout,
  Box,
  Button,
  Modal,
} from '@amzn/awsui-components-react';
import { useApi } from '@/src/store/api.context';

// import { Table, Link, Checkbox } from '@amzn/awsui-components-react';

import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import './index.scss';
import JamSpinner from "@/src/components/common/JamSpinner";
interface fileFormateInterface {
  accountId: string;
  displayName: string;
  registeredEventIds: string[];
  profile: object;
}

const DataAndPrivacy: React.FC = () => {
  const { t } = useTranslation();
  const { jamProfileAPI } = useApi();
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [fileFormate, setFileFormate] = useState<fileFormateInterface>({
    accountId: '',
    displayName: '',
    registeredEventIds: [],
    profile: {},
  });

  const getUser = async () => {
    setLoading(true);
    const profileRes = await jamProfileAPI.getProfile();
    const res = await jamProfileAPI.getPricipantDashboard();
    setLoading(false);

    const currEvent = res.events?.map((item) => item.name);
    const pastEvent = res.pastEvents?.map((item) => item.name);
    const registeredEventIds = [...currEvent, ...pastEvent];
    setFileFormate({
      ...fileFormate,
      profile: profileRes,
      accountId: res.participant.login,
      displayName: res.participant.nickname,
      registeredEventIds,
    });
  };
  useEffect(() => {
    void getUser();
  }, []);

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(fileFormate))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `aws-jam-my-personal-data-${Date.now()}.json`;
    link.click();
  };

  const deleteUser = async () => {
    setShowModal(false);
    try {
      setLoading(true);
      await jamProfileAPI.deleteParicipant();
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  if (loading) {
    return <JamSpinner />;
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">{t(i18nKeys.JamProfile.DataAndPrivacy.title)}</Header>
        </SpaceBetween>
      }>
      <SpaceBetween size="xl">
        {/*
        TODO : Will be updated in the future when api will be updated
        <Table
          header={
            <Header variant="h2" description={t(i18nKeys.JamProfile.DataAndPrivacy.page.dataSharingD)}>
              {t(i18nKeys.JamProfile.DataAndPrivacy.page.dataSharing)}
            </Header>
          }
          items={[
            {
              sponsor: 'Fortinet',
              event: 'Event Title',
              eventDate: '11/29/2023',
              optIn: true,
              details: 'Details',
            },
            {
              sponsor: 'Varizon',
              event: 'Event Title',
              eventDate: '11/29/2023',
              optIn: false,
              details: 'Details',
            },
          ]}
          columnDefinitions={[
            {
              id: 'sponsor',
              header: 'Sponsor',
              cell: (item) => item.sponsor,
            },
            {
              id: 'event',
              header: 'Event',
              cell: (item) => <Link href="#">{item.event}</Link>,
            },
            {
              id: 'eventDate',
              header: 'Event date',
              cell: (item) => <Link href="#">{item.eventDate}</Link>,
            },
            {
              id: 'optIn',
              header: 'Opt In',
              cell: (item) => <Checkbox checked={item.optIn}>{''}</Checkbox>,
            },
            {
              id: 'details',
              header: '',
              cell: (item) => <Link href="#">{item.details}</Link>,
            },
          ]}
          loading={false}
          loadingText={t(i18nKeys.challenges.loadingChallenges)}
        /> */}
        <Container header={<Header variant="h3">{t(i18nKeys.JamProfile.DataAndPrivacy.page.downloadMyData)}</Header>}>
          <ColumnLayout columns={2}>
            <div> {t(i18nKeys.JamProfile.DataAndPrivacy.page.downloadMyDataD)}</div>
            <div className="middle-btn">
              <Box float="right" display="inline">
                <Button
                  iconName="download"
                  ariaLabel={t(i18nKeys.JamProfile.DataAndPrivacy.btn.downloadMyData)}
                  variant="primary"
                  onClick={exportData}>
                  {t(i18nKeys.JamProfile.DataAndPrivacy.btn.downloadMyData)}
                </Button>
              </Box>
            </div>
          </ColumnLayout>
        </Container>
        <Container
          header={<Header variant="h3">{t(i18nKeys.JamProfile.DataAndPrivacy.page.deleteMyAWSJamAccount)}</Header>}>
          <ColumnLayout columns={2}>
            <div>{t(i18nKeys.JamProfile.DataAndPrivacy.page.deleteMyAWSJamAccountD)}</div>
            <div className="middle-btn">
              <Box float="right">
                <Button
                  ariaLabel={t(i18nKeys.JamProfile.DataAndPrivacy.btn.permanentlyDelete)}
                  onClick={() => setShowModal(true)}>
                  {t(i18nKeys.JamProfile.DataAndPrivacy.btn.permanentlyDelete)}
                </Button>
              </Box>
            </div>
          </ColumnLayout>
        </Container>
        <Container
          header={<Header variant="h3">{t(i18nKeys.JamProfile.DataAndPrivacy.page.termsAndConditions)}</Header>}>
          <p>
            {t(i18nKeys.JamProfile.DataAndPrivacy.page.termsAndConditionsD)}{' '}
            <a
              className="link-color"
              target="_blank"
              rel="noreferrer"
              href="https://aws.amazon.com/legal/learner-terms-conditions/"
              title="Terms & Conditions">
              {t(i18nKeys.JamProfile.DataAndPrivacy.link.termAndCondition)}
            </a>{' '}
            <br />
            {t(i18nKeys.JamProfile.DataAndPrivacy.page.termsAndConditionsD)}{' '}
            <a
              className="link-color"
              target="_blank"
              rel="noreferrer"
              href="https://aws.amazon.com/privacy/?src=aws_training"
              title="Terms & Conditions">
              {t(i18nKeys.JamProfile.DataAndPrivacy.link.privacyNotic)}
            </a>
          </p>
        </Container>
      </SpaceBetween>
      <Modal
        header={t(i18nKeys.JamProfile.jamProfileDetails.modal.header)}
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel={t(i18nKeys.JamProfile.jamProfileDetails.modal.canceleBtn)}
                onClick={() => setShowModal(false)}>
                {t(i18nKeys.JamProfile.jamProfileDetails.modal.canceleBtn)}
              </Button>
              <Button
                ariaLabel={t(i18nKeys.JamProfile.jamProfileDetails.modal.deleteBtn)}
                variant="primary"
                onClick={() => void deleteUser()}>
                {t(i18nKeys.JamProfile.jamProfileDetails.modal.deleteBtn)}
              </Button>
            </SpaceBetween>
          </Box>
        }>
        <p>{t(i18nKeys.JamProfile.jamProfileDetails.modal.msg)}</p>
      </Modal>
    </ContentLayout>
  );
};

export default DataAndPrivacy;
