import * as React from 'react';
import { useToolPanel } from '../../store/tool-panel.context';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { RoutePath } from '../../RoutePath';
import { customEventTrigger } from '../analytics/createEventTrigger';
import BrowseChallengesOpenSearch from '../common/Challenges/BrowseChallengesOpenSearch';

const ChallengeList: React.FC = () => {
  const { t } = useTranslation();
  const { toggleChallengeInfo } = useToolPanel();

  const challengeSets = () => {
    customEventTrigger('click', 'Challenge Sets', window.location.href, 'Challenge Sets', {});
  };

  const createChallenge = () => {
    customEventTrigger('click', 'Create Challenge', window.location.href, 'Create Challenge', {});
  };

  return (
    <BrowseChallengesOpenSearch
      currentChallengeDescriptors={[]}
      toggleChallengeInfo={toggleChallengeInfo}
      editMode={false}
      showHideUnstableToggle
      actionButtons={
        <>
          <SpaceBetween direction="horizontal" size="xs">
            <ReactRouterLink to={RoutePath.CHALLENGE_SETS}>
              <Button variant="normal" onClick={challengeSets}>
                {t(i18nKeys.challenges.challengeSets)}
              </Button>
            </ReactRouterLink>
            <ReactRouterLink to={RoutePath.NEW_CHALLENGE}>
              <Button variant="primary" onClick={createChallenge}>
                {t(i18nKeys.challenges.createChallenge)}
              </Button>
            </ReactRouterLink>
          </SpaceBetween>
        </>
      }
    />
  );
};

export default ChallengeList;
