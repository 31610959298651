import React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface JamChallengeFeedbackModalProps {
  showFeedbackModal: boolean;
  modalTitle: string;
  cancelText: string;
  eventURL: string;
  submitText: string;
  subTitle1: string;
  subTitle2: string;
  onCancel: () => void;
}

const JamChallengeFeedbackModal: React.FC<JamChallengeFeedbackModalProps> = ({
  showFeedbackModal,
  modalTitle,
  cancelText,
  eventURL,
  submitText,
  subTitle1,
  subTitle2,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={showFeedbackModal}
      header={modalTitle}
      onDismiss={onCancel}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button
              variant="primary"
              href={eventURL}>
              {submitText}
            </Button>
          </SpaceBetween>
        </Box>
      }>
      <SpaceBetween direction="vertical" size="m">
        {subTitle1}
        {subTitle2}
        <SpaceBetween direction="horizontal" size="xs">
          <b>{t(i18nKeys.myJams.feedback.modal.note)}</b> {t(i18nKeys.myJams.feedback.modal.noteText)}
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
};

export default JamChallengeFeedbackModal;
