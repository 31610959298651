import React, { useState } from 'react';
import { Box, Button, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';

const JamChallengeOverview: React.FC = () => {
  const { t } = useTranslation();
  const { selectedJamChallenge } = useJamChallengeDetails();
  const [fullOverview, setFullOverview] = useState(false);
  const content = selectedJamChallenge?.learningOutcome?.content ?? '';
  const { readFullScenario, readLess } = i18nKeys.myJams.challenges.details.overview;
  const toggleOverview = () => setFullOverview(!fullOverview);

  return (
    <Container className="dark-bg">
      <Box variant="h4" padding={{ bottom: 's' }}>
        {selectedJamChallenge?.learningOutcome?.summary}
      </Box>
      <Box variant="div">
        {selectedJamChallenge?.learningOutcome?.content && (
          <SpaceBetween size="s">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {fullOverview ? content : content.substring(0, 500)}
            </ReactMarkdown>
            <Box padding={{ bottom: 'l' }}>
              <Button href="#" variant="inline-link" onClick={toggleOverview}>
                {fullOverview ? t(readLess) : t(readFullScenario)}
              </Button>
            </Box>
          </SpaceBetween>
        )}
      </Box>
    </Container>
  );
};

export default JamChallengeOverview;
