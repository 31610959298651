import { Auth } from 'aws-amplify';
import { User } from '../types/User';
import { AddFlashbar } from '../store/flashbar.context';
import { apiErrorFlashbar } from '../utils/notification.utils';
import { History, Location } from 'history';
import sessionStorage from '../utils/session-storage';
import { IdentityProvider, IdpToProviderName, isValidIdp } from '../types/IdentityProvider';
import { Nullable } from '../types/common';
import { IDP_QUERY_PARAM } from '../constants/query-params';
import { preProdLogger } from '../utils/log.utils';
import { RoutePath } from '../RoutePath';
import amplifyConfig, { configureAmplify } from '../config/amplifyConfig';
import { JamConstants } from '../constants/shared/jam-constants';
import localStorageTTL from '../utils/localStorageTTL.utils';

/**
 * Class for constructing a client used for authorization calls
 */
export class AuthClient {
  /**
   * Sessionstorage key used to store where the user should be redirected after sign-in.
   */
  public static readonly REDIRECT_AFTER_SIGN_IN = 'redirectAfterSignIn';

  /**
   * Prevent multiple redirects
   */
  private redirectInProgress = false;

  constructor(private addFlashBar: AddFlashbar, private history: History) {
    // do nothing
  }

  /**
   * Get the location to redirect the user after sign in for oauth sign-in.
   */
  private get redirectAfterSignIn(): Location | undefined | null {
    return sessionStorage.get(AuthClient.REDIRECT_AFTER_SIGN_IN);
  }

  /**
   * Set the location to redirect the user after sign in for oauth sign-in.
   */
  private set redirectAfterSignIn(redirectTo: Nullable<Location> | undefined) {
    if (redirectTo) {
      sessionStorage.set(AuthClient.REDIRECT_AFTER_SIGN_IN, redirectTo);
    } else {
      sessionStorage.remove(AuthClient.REDIRECT_AFTER_SIGN_IN);
    }
  }

  /**
   * Redirect the user to the path + query parameters saved in sessionStorage
   * during an oauth login flow.
   *
   * This is only used for federated users, since these users
   * navigate away from the web application during sign-in and need to have the path to redirect to
   * upon return saved in sessionStorage.
   *
   * Calling this when there is no saved route in sessionStorage redirects the user to the home page.
   */
  public handleOauthSignInRedirect(): void {
    const redirectTo = this.redirectAfterSignIn ?? RoutePath.ROOT;

    if (redirectTo) {
      this.history.push(redirectTo);

      // clear storage
      this.redirectAfterSignIn = null;
      this.redirectInProgress = false;
    }
  }

  /**
   * If the current location has an IDP query parameter, do one of the following:
   *
   * 1. Sign in the user with the provided IDP if it is valid and different from the user's current idp.
   * 2. Keep the user signed in and remove the IDP query parameter if it is invalid or the same as the user's current IDP.
   *
   * @param user - Possibly null current user.
   */
  public async handleIdpQueryParam(user: Nullable<User>): Promise<void> {
    const queryParams = new URLSearchParams(this.history.location.search);
    const idp = queryParams.get(IDP_QUERY_PARAM);

    if (idp != null) {
      const isUsingIdp = user?.provider === idp;

      if (isValidIdp(idp) && !isUsingIdp) {
        // sign in will automatically use the IDP in the query parameters
        await this.signIn(this.history.location);
      } else {
        queryParams.delete(IDP_QUERY_PARAM);
        this.history.replace({ search: queryParams.toString() });
      }
    }
  }

  /**
   * Sign in a user, optionally setting a redirect location and the IDP to use for federation.
   *
   * @param redirectAfterSignIn - Location to redirect the user after sign-in.
   *If this includes the `IDP_QUERY_PARAM` query parameter and the `idpOverride` argument
   *is not provided, the user will be signed in with the idp specified by
   *the query parameter.
   * @param idpOverride - IDP to use for sign in. If specified, this IDP is used for sign-in in favor of any idp in
   *the location's query parameters.
   * @public
   */
  public async signIn(redirectAfterSignIn?: Location, idpOverride?: Nullable<IdentityProvider>): Promise<void> {
    let idpForSignIn: Nullable<IdentityProvider> = idpOverride ?? null;

    /* Set the route to redirect to after sign in and the IDP to use. */
    if (redirectAfterSignIn) {
      const queryParams = new URLSearchParams(redirectAfterSignIn.search);
      const queryParamIdp = queryParams.get(IDP_QUERY_PARAM);

      queryParams.delete(IDP_QUERY_PARAM);
      queryParams.delete(JamConstants.SKILL_BUILDER_QUERY_PARAM);

      redirectAfterSignIn = { ...redirectAfterSignIn, search: queryParams.toString() };

      if (isValidIdp(queryParamIdp) && !idpForSignIn) {
        idpForSignIn = queryParamIdp;
      }
    }

    /* Sign in */
    try {
      localStorage.setItem('loginWarnMsg', 'true');
      this.redirectAfterSignIn = redirectAfterSignIn;
      await Auth.federatedSignIn({ customProvider: idpForSignIn ? IdpToProviderName[idpForSignIn] : '' });
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.addFlashBar(apiErrorFlashbar(e, e.message));
      preProdLogger('error signing in', e);
    }
  }

  public async verifyEmail(redirectAfterSignIn: Location): Promise<void> {
    /* Initiate Gandalf OTP */
    try {
      if (this.redirectInProgress) {
        preProdLogger("Returning early to prevent loop.");
        return;
      }
      this.redirectInProgress = true;
      const queryParams = new URLSearchParams(redirectAfterSignIn.search);
      redirectAfterSignIn = { ...redirectAfterSignIn, search: queryParams.toString() };
  
      let identity_provider = '';
      const token = await this.getIdToken();
      if (token) {
        identity_provider = User.parseToken(token).public_provider_name || '';
      }
      configureAmplify({
        require_email_verification: "true",
        ...(identity_provider && { identity_provider })
      });
      this.redirectAfterSignIn = redirectAfterSignIn;
      preProdLogger("AuthClient doing OTP redirect.")
      await Auth.federatedSignIn({ customProvider: IdpToProviderName[IdentityProvider.TC] });
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.addFlashBar(apiErrorFlashbar(e, e.message));
      preProdLogger('error verifying email', e);
      this.redirectInProgress = false;
    } finally {
      configureAmplify(); // revert to default sign-in configuration
    }
  }

  /**
   * Sign out currently logged in user
   *
   * @public
   */
  public async signOut(): Promise<void> {
    try {
      // We need to manually revoke the refresh token on logout, as the Amplify SDK does not do this for us.
      // Revoking the refresh token will also invalidate any tokens generate using the refresh token, and will prevent
      // new tokens from being created.
      // However, since API gateway doesn’t block based on status, the current access token can still be used to access
      // our backend for 1 hour, after which it expires. This is an acceptable validity time.
      const session = await Auth.currentSession();
      const token = session.getRefreshToken().getToken();
      const clientId = amplifyConfig.Auth.userPoolWebClientId;
      await fetch(`https://${amplifyConfig.Auth.oauth.domain}/oauth2/revoke`, {
        method: 'POST',
        body: `token=${encodeURIComponent(token)}&client_id=${encodeURIComponent(clientId)}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      });

      await Auth.signOut();
      localStorageTTL.clearStorage();
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.addFlashBar(apiErrorFlashbar(e, e.message));
      // tslint:disable-next-line:no-console
      preProdLogger('error signing out:', e);
    }
  }

  /**
   * Get the currently signed in user, if there is a user signed in,
   * or null otherwise.
   */
  public async getUser(): Promise<User | null> {
    const idToken = await this.getIdToken();

    if (!idToken) {
      return null;
    }

    return User.fromIdToken(idToken);
  }

  /**
   * Returns current user id token if there is a current session, null otherwise.
   *
   * @public
   */
  public async getIdToken(): Promise<string | null> {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      let error = e;
      // it is possible that a string is caught here instead of an Error
      if (typeof e === 'string') {
        error = new Error(e);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.addFlashBar(apiErrorFlashbar(error, error.message));

      return null;
    }
  }

  public async isSignedIn(): Promise<boolean> {
    return (await this.getUser()) != null;
  }
}
