import { Box, Grid } from '@amzn/awsui-components-react';
import React from 'react';
import './index.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';


interface translatedString {
  answer: string;
}
export const TranslatedString: React.FC<translatedString> = ({ answer }): JSX.Element => {
  const { t } = useTranslation();
  return <Box>{t(answer)}</Box>;
};

// ---------------------------------------------------------------- General start here
export const AwsJam = () => {
  const { t } = useTranslation();
  return (
    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
      <div>
        <Box>{t(i18nKeys.faq.generalFaq.awsJam)}</Box>
      </div>
      {/* <div>
        <Box>
          <div className="video-player">
            <ReactPlayer url="https://youtu.be/V9dXxofCmUw" controls alt="AWS Jam" width={350} height={180} />
          </div>
        </Box>
      </div> */}
    </Grid>
  );
};

export const AwsJamHelpMe = () => {
  const { t } = useTranslation();
  return (
    <ul>
      <li>
        <strong>{t(i18nKeys.faq.generalFaq.AwsJamHelpMe.one)} </strong>
        {t(i18nKeys.faq.generalFaq.AwsJamHelpMe.two)}
      </li>
      <li>
        <strong>{t(i18nKeys.faq.generalFaq.AwsJamHelpMe.three)} </strong>
        {t(i18nKeys.faq.generalFaq.AwsJamHelpMe.four)}
      </li>
      <li>
        <strong>{t(i18nKeys.faq.generalFaq.AwsJamHelpMe.five)} </strong>
        {t(i18nKeys.faq.generalFaq.AwsJamHelpMe.six)}
      </li>
    </ul>
  );
};

export const CanIPlay = () => {
  const { t } = useTranslation();
  return (
    <Box>
      {t(i18nKeys.faq.generalFaq.CanIPlay.one)}{' '}
      <a className="link-color" href="https://skillbuilder.aws/subscriptions" rel="noreferrer" target="_blank">
        Individual
      </a>{' '}
      or{' '}
      <a
        href="https://aws.amazon.com/training/digital/team-subscription/"
        className="link-color"
        rel="noreferrer"
        target="_blank">
        Team
      </a>{' '}
      {t(i18nKeys.faq.generalFaq.CanIPlay.two)}{' '}
    </Box>
  );
};

export const HowDoPurchase = () => {
  const { t } = useTranslation();
  return (
    <Box>
      {t(i18nKeys.faq.generalFaq.HowDoPurchase.one)}{' '}
      <a href="https://jam.aws.com/events" className="link-color" rel="noreferrer" target="_blank">
        jam.aws.com/events
      </a>{' '}
      {t(i18nKeys.faq.generalFaq.HowDoPurchase.two)}{' '}
      <a
        href="https://aws.amazon.com/training/digital/team-subscription/"
        className="link-color"
        rel="noreferrer"
        target="_blank">
        AWS Skill Builder Team Subscription.
      </a>
    </Box>
  );
};
export const DoIGetBenefit = () => {
  const { t } = useTranslation();
  return (
    <Box>
      {t(i18nKeys.faq.generalFaq.DoIGetBenefit.one)}{' '}
      <a
        href="https://aws.amazon.com/training/digital/team-subscription/"
        className="link-color"
        rel="noreferrer"
        target="_blank">
        AWS Skill Builder Team subscription.
      </a>
      {' '}{t(i18nKeys.faq.generalFaq.DoIGetBenefit.two)}{' '}
    </Box>
  );
};
export const AwsProfessional = () => {
  const { t } = useTranslation();
  return (
    <Box>
      {t(i18nKeys.faq.generalFaq.AwsProfessional.one)}{' '}
      <a href="https://aws.amazon.com/training/classroom/" className="link-color" rel="noreferrer" target="_blank">
        classroom training with AWS Jam.
      </a>
    </Box>
  );
};

// ---------------------------------------------------------------- play start here

export const CreateAwsJamEvent = () => {
  const { t } = useTranslation();
  return (
    <Box>
      {t(i18nKeys.faq.playFaq.CreateAwsJamEvent.one)}{' '}
      <a href="http://jam.aws.com/event" className="link-color" rel="noreferrer" target="_blank">
        jam.aws.com/events
      </a>{' '}
      {t(i18nKeys.faq.playFaq.CreateAwsJamEvent.two)}{' '}
      <a
        href="https://aws.amazon.com/training/digital/team-subscription/?cta=jamFAQ"
        className="link-color"
        rel="noreferrer"
        target="_blank">
        AWS Skill Builder Team subscription
      </a>{' '}
      {t(i18nKeys.faq.playFaq.CreateAwsJamEvent.three)}
    </Box>
  );
};
export const PurchaseOutsideUs = () => {
  const { t } = useTranslation();
  return (
    <Box>
      {t(i18nKeys.faq.playFaq.PurchaseOutsideUs.one)}{' '}
      <a href="https://aws.amazon.com/training/faqs/?tspricing" className="link-color" rel="noreferrer" target="_blank">
        Skill Builder Team subscription.
      </a>
    </Box>
  );
};
export const CancelPurchaseEvent = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.playFaq.CancelPurchaseEvent.one)}</Box>
      <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
        <Box>
          <ol>
            <li>{t(i18nKeys.faq.playFaq.CancelPurchaseEvent.one)}</li>
            <li>{t(i18nKeys.faq.playFaq.CancelPurchaseEvent.two)}</li>
            <li>{t(i18nKeys.faq.playFaq.CancelPurchaseEvent.three)}</li>
          </ol>
        </Box>
        <Box>
          <img src="/assets/faq-actions.png" alt="canel event button image" />
        </Box>
      </Grid>
    </Box>
  );
};

export const PurchasePaymentError = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.playFaq.PurchasePaymentError.one)}</Box>
      <Box>
        <ol>
          <li>{t(i18nKeys.faq.playFaq.PurchasePaymentError.two)}</li>
          <li>{t(i18nKeys.faq.playFaq.PurchasePaymentError.three)}</li>
          <li>
            <img src="/assets/faq-flashbar.png" alt="flasbar" />
          </li>
          <li>{t(i18nKeys.faq.playFaq.PurchasePaymentError.four)}</li>
          <li>{t(i18nKeys.faq.playFaq.PurchasePaymentError.five)}</li>
        </ol>
      </Box>
      <Box> {t(i18nKeys.faq.playFaq.PurchasePaymentError.six)}{' '}<a href="https://support.console.aws.amazon.com/support/home#/case/create?issueType=customer-service&serviceCode=billing&categoryCode=other-billing-questions" className="link-color" target="_blank" rel="noreferrer">{t(i18nKeys.faq.playFaq.PurchasePaymentError.seven)}</a></Box>
    </Box>
  );
};

export const SwapChallenges = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.playFaq.SwapChallenges.one)}</Box>
      <Box>
        <ol>
          <li>{t(i18nKeys.faq.playFaq.SwapChallenges.two)}</li>
          <li>{t(i18nKeys.faq.playFaq.SwapChallenges.three)}</li>
          <li>{t(i18nKeys.faq.playFaq.SwapChallenges.four)}</li>
          <li>{t(i18nKeys.faq.playFaq.SwapChallenges.five)}</li>
        </ol>
      </Box>
      <Box>
        <img src="/assets/faq-swap-challenges.png" alt="swap challenge" />
      </Box>
    </Box>
  );
};
export const InviteParticipant = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.playFaq.InviteParticipant.one)}</Box>
      <Box>
        <ol>
          <li>{t(i18nKeys.faq.playFaq.InviteParticipant.two)}</li>
          <li>{t(i18nKeys.faq.playFaq.InviteParticipant.three)}</li>
          <li>{t(i18nKeys.faq.playFaq.InviteParticipant.four)}</li>
          <li>{t(i18nKeys.faq.playFaq.InviteParticipant.five)}</li>
        </ol>
      </Box>
    </Box>
  );
};
export const InviteFacilitator = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.playFaq.InviteFacilitator.one)}</Box>
      <Box>
        <ol>
          <li>{t(i18nKeys.faq.playFaq.InviteFacilitator.two)}</li>
          <li>{t(i18nKeys.faq.playFaq.InviteFacilitator.three)}</li>
        </ol>
      </Box>
    </Box>
  );
};

// ---------------------------------------------------------------- facilitator start here

export const BecomeFacilitator = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.facilitatorFaq.BecomeFacilitator.three)}</Box>
      <Box>
        <ol>
          <li>{t(i18nKeys.faq.facilitatorFaq.BecomeFacilitator.three)}</li>
          <li>{t(i18nKeys.faq.facilitatorFaq.BecomeFacilitator.three)}</li>
        </ol>
      </Box>
    </Box>
  );
};

export const RoleAsFacilitator = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.facilitatorFaq.RoleAsFacilitator.one)} </Box>
      <Box>
        <img src="/assets/faq-role-as-facilitator.png" alt="role as facilitator" />
      </Box>
    </Box>
  );
};

export const ExpertFacilitator = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.facilitatorFaq.ExpertFacilitator.one)}</Box>
      <Box>
        <img src="/assets/faq-expert-facilitator.png" alt="expert facilitator" />
      </Box>
    </Box>
  );
};

export const RestartALab = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>
        {t(i18nKeys.faq.facilitatorFaq.RestartALab.one)}{' '}
        <a href="http://jam.aws.com/" className="link-color" rel="noreferrer" target="_blank">
          jam.aws.com
        </a>{' '}
        {t(i18nKeys.faq.facilitatorFaq.RestartALab.two)}{' '}
      </Box>
      <Box>
        <ol>
          <li>{t(i18nKeys.faq.facilitatorFaq.RestartALab.three)} </li>
          <li> {t(i18nKeys.faq.facilitatorFaq.RestartALab.four)}</li>
          <li>{t(i18nKeys.faq.facilitatorFaq.RestartALab.five)}</li>
        </ol>
      </Box>
      <Box>
        <img src="/assets/faq-restart-lab.png" alt="restart a lab" />
      </Box>
    </Box>
  );
};

// ---------------------------------------------------------------- host start here

export const JoinAJamEvent = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.hostFaq.JoinAJamEvent.one)}</Box>
      <Box>
        {t(i18nKeys.faq.hostFaq.JoinAJamEvent.two)}{' '}
        <a href="http://jam.aws.com/" className="link-color" rel="noreferrer" target="_blank">
          jam.aws.com
        </a>{' '}
        {t(i18nKeys.faq.hostFaq.JoinAJamEvent.three)}
      </Box>
      <Box>
        <img src="/assets/faq-join-an-event-one.png" alt="got to join event" />
      </Box>
      <Box>
        <strong>{t(i18nKeys.faq.hostFaq.JoinAJamEvent.four)}</strong> {t(i18nKeys.faq.hostFaq.JoinAJamEvent.five)}
      </Box>
      <Box>
        <img src="/assets/faq-join-an-event-two.png" alt="sign in using preferred method" />
      </Box>
      <Box>
        <strong>{t(i18nKeys.faq.hostFaq.JoinAJamEvent.six)}</strong> {t(i18nKeys.faq.hostFaq.JoinAJamEvent.seven)}
      </Box>
      <Box>
        <img src="/assets/faq-join-an-event-three.png" alt="confirm your aws jam profile" />
      </Box>
      <Box>
        <strong>{t(i18nKeys.faq.hostFaq.JoinAJamEvent.eight)}</strong> {t(i18nKeys.faq.hostFaq.JoinAJamEvent.nine)}
      </Box>
      <Box>
        <img src="/assets/faq-join-an-event-four.png" alt="find your event" />
      </Box>
      <Box>{t(i18nKeys.faq.hostFaq.JoinAJamEvent.ten)}</Box>
      <Box>
        <img src="/assets/faq-join-an-event-five.png" alt="enter secret key to join event" />
      </Box>
    </Box>
  );
};

export const CreateJoinTeam = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.hostFaq.CreateJoinTeam.one)}</Box>
      <Box>
        <ul>
          <li>
            <strong>{t(i18nKeys.faq.hostFaq.CreateJoinTeam.two)}</strong> {t(i18nKeys.faq.hostFaq.CreateJoinTeam.three)}
          </li>
          <li>
            <strong>{t(i18nKeys.faq.hostFaq.CreateJoinTeam.four)}</strong> {t(i18nKeys.faq.hostFaq.CreateJoinTeam.five)}
          </li>
          <li>
            <strong>{t(i18nKeys.faq.hostFaq.CreateJoinTeam.six)}</strong> {t(i18nKeys.faq.hostFaq.CreateJoinTeam.seven)}
          </li>
        </ul>
      </Box>
      <Box>
        <img src="/assets/faq-create-event.png" alt="create join event" />
      </Box>
    </Box>
  );
};
export const CommunicateWithTeam = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
        <Box>{t(i18nKeys.faq.hostFaq.CommunicateWithTeam.one)}</Box>
        <Box>
          <img src="/assets/faq-communicate-with-team.png" alt="communicate with team" />
        </Box>
      </Grid>
    </Box>
  );
};

export const WhoWins = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.hostFaq.WhoWins.one)}</Box>
      <Box>
        <img src="/assets/faq-who-wins.png" alt="who wins" />
      </Box>
    </Box>
  );
};
export const EarnPointSolve = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.hostFaq.EarnPointSolve.one)}</Box>
      <Box>
        <img src="/assets/faq-earn-points-one.png" alt=" To earn points your team must solve challenges" />
      </Box>
      <Box>{t(i18nKeys.faq.hostFaq.EarnPointSolve.two)}</Box>
      <Box>
        <img src="/assets/faq-earn-points-two.png" alt="using Amazon CodeWhisperer" />
      </Box>
    </Box>
  );
};
export const HowToResolve = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{t(i18nKeys.faq.hostFaq.HowToResolve.one)}</Box>
      <Box>
        <ul>
          <li>{t(i18nKeys.faq.hostFaq.HowToResolve.two)}</li>
          <li>{t(i18nKeys.faq.hostFaq.HowToResolve.three)}</li>
        </ul>
      </Box>
    </Box>
  );
};
