import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { SideNavigation } from '@amzn/awsui-components-react';
import { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation/interfaces';
import { useActiveRouteDetails } from '@/src/hooks/useActiveRouteDetails';
import { useSplitPanel } from '@/src/store/split-panel.context';
import { useToolPanel } from '@/src/store/tool-panel.context';
import { useEditEvent } from '@/src/store/edit-event.context';
import { useEditCampaign } from '@/src/store/edit-campaign.context';
import { RoutePath } from '@/src/RoutePath';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  JAM_CHALLENGE_DETAILS_ROUTES,
  JAM_CHALLENGE_ROUTES,
  JAM_CHALLENGE_TASK_ROUTES,
  JAM_EVENT_DETAILS_ROUTES,
  JAM_FACILITATOR_DETAILS_ROUTES
} from '@/src/routes';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import TaskStatus from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamTask/TaskStatus';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { JamChallengeDetails, JamEventStatusForSidebar, JamProgress, JamTask } from '@/src/types/JamChallengeDetails';
import { useJamChat } from '@/src/store/jam-chat.context';
import './MyJamSidebar.scss';
import { useApi } from '@/src/store/api.context';

/**
 * Types that allow attaching custom "onClick" behavior to side navigation items.
 */
type FollowDetailWithClick = SideNavigationProps.FollowDetail & { onClick?: () => void };

export const MyJamSidebar: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { activeRoute } = useActiveRouteDetails();
  const [activeNav, setActiveNav] = useState('');
  const { toggleShowSplitPanel } = useSplitPanel();
  const { toggleShowToolPanel } = useToolPanel();
  const { newEventMode } = useEditEvent();
  const { newCampaignMode } = useEditCampaign();
  const { eventSlug, event, eventName, unreadMessageCounts } = useJamEventDetails();
  const { jamChallengeData } = useJamChallenge();
  const { selectedJamChallenge, jamChallengeId, challengeProgress, outputProperties, challengeSupportDetails } =
    useJamChallengeDetails();
  const { allSupportChats } = useJamChat();
  const allChallenges = jamChallengeData?.challenges || [];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [ jamTasks , setJamTasks] = useState<{[key: string]: SideNavigationProps.Item[]}>({});
  const [ jamChallengeProgress, setjamChallengeProgress] = useState<{[key: string] : JamProgress}>({});
  const { jamChallengeDetailsApi } = useApi();


  const isAnyChallengeUnlocked = React.useMemo(() => {
    return allChallenges.some(({ locked }) => !locked);
  }, [allChallenges]);

  // If the provided link is empty, do not redirect pages
  const onFollow = (e: CustomEvent<FollowDetailWithClick>) => {
    toggleShowSplitPanel(false);
    toggleShowToolPanel(false);
    e.preventDefault();

    if (e.detail.onClick) {
      e.detail.onClick();
    } else if (e.detail.href) {
      history.push(e.detail.href);
    }
  };

  useEffect(() => {
    if (location.hash) {
      if (newEventMode) setActiveNav(location.hash);
      if (newCampaignMode) setActiveNav(location.hash);
    } else {
      setActiveNav(activeRoute);
    }
  }, [activeRoute, location.pathname]);

  useEffect(() => {
    jamChallengeData?.challenges?.map(async (challenges) => {
      const responseDetail = await jamChallengeDetailsApi.fetchJamChallengeDetailsData(eventName, challenges.id);
      const responseProgress = await jamChallengeDetailsApi.fetchJamChallengeProgress(eventName, challenges.id);
      setJamTasks(prevState => ({...prevState, [challenges.id] : (JamTasks(challenges.id, responseDetail))}));
      setjamChallengeProgress(prevState => ({...prevState, [challenges.id] : responseProgress}));
    })
  }, [jamChallengeData, challengeProgress]);

  const validateTaskLink = (challengeId : string, jamChallenge : JamChallengeDetails, task: JamTask) => {
    if (
      task.locked ||
      // let facilitator navigate without challenge start
      (jamChallenge?.locked && !event?.facilitator)
    ) {
      return '';
    }
    return JAM_CHALLENGE_TASK_ROUTES.resolve([eventSlug, challengeId, task.id]);
  };

  const JamTasks = (challengeId : string, jamChallenge : JamChallengeDetails) : SideNavigationProps.Item[] => {
    return jamChallenge?.tasks?.map((task, i) => ({
      type: 'link',
      text: `${t(i18nKeys.myJams.challenges.details.task.noTitle)} ${i + 1}`,
      href: validateTaskLink(challengeId, jamChallenge, task),
      info: <TaskStatus task={task} status={event?.eventStatus} challengeStatus={(jamChallengeProgress[challengeId])?.started} />
    })) ?? []
  };

  const getJamChallengesDetailRoutes = (challengeId: string): SideNavigationProps.Item[] => {
    return [
      {
        type: 'link',
        text: t(i18nKeys.myJams.challenges.details.overview.title),
        href: JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventSlug, challengeId]),
      },
      ...(!selectedJamChallenge?.locked && outputProperties && outputProperties.length > 0
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.outputProperties.title),
            href: JAM_CHALLENGE_DETAILS_ROUTES.OutputProperties.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(jamTasks[challengeId] || []),
      ...(event?.facilitator
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.recentFeedback),
            href: JAM_CHALLENGE_DETAILS_ROUTES.RecentFeedback.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(event?.facilitator && event.testEvent
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.issues),
            href: JAM_CHALLENGE_DETAILS_ROUTES.Issue.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(event?.facilitator && challengeSupportDetails?.getChallengeSupportDetails(challengeId)?.wiki?.length
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.wiki),
            href: JAM_CHALLENGE_DETAILS_ROUTES.Wiki.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(event?.facilitator
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.faciliatorNotes),
            href: JAM_CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
    ];
  };

  const AllJamChallenges: SideNavigationProps.Item[] =
    jamChallengeData?.challenges?.map((challenge) => ({
      type: 'expandable-link-group',
      text: challenge.title ?? challenge.id,
      defaultExpanded: jamChallengeId === challenge.id,
      href: !challenge.locked ? JAM_CHALLENGE_ROUTES.resolve([eventSlug, challenge.id]) : '',
      info: challenge.locked ? (
        <TaskStatus name={JamEventStatusForSidebar.LOCKED} />
      ) : challenge.solved ? (
        <TaskStatus name={JamEventStatusForSidebar.SOLVED} />
      ) : (
        ''
      ),
      items: getJamChallengesDetailRoutes(challenge.id),
    })) ?? [];

  // it is possible that below items will be filtered based on
  // isSupportUser access. right now if user is even support user
  // he will be able to see the facilitator menu
  const FacilitatorMenu: SideNavigationProps.Section = {
    type: 'section',
    text: t(i18nKeys.facilitator.title),
    items: [
      {
        type: 'link',
        text: t(i18nKeys.facilitator.participants.title),
        href: JAM_FACILITATOR_DETAILS_ROUTES.Participants.resolve(eventSlug),
      },
      {
        type: 'link',
        text: t(i18nKeys.facilitator.jamSettings.title),
        href: JAM_FACILITATOR_DETAILS_ROUTES.JamSettings.resolve(eventSlug),
      },
      {
        type: 'link',
        text: t(i18nKeys.facilitator.messaging.title),
        href: JAM_FACILITATOR_DETAILS_ROUTES.Messaging.resolve(eventSlug),
      },
      ...(event?.supportChatEnabled
        ? [
          {
            type: 'link',
            text:
              t(i18nKeys.facilitator.supportChats.title) +
              (allSupportChats.length > 0 ? ` (${allSupportChats.length})` : ''),
            href: JAM_FACILITATOR_DETAILS_ROUTES.SupportChats.resolve(eventSlug),
          } as SideNavigationProps.Item,
        ]
        : []),
      {
        type: 'link',
        text: t(i18nKeys.facilitator.notifications.notification),
        href: JAM_FACILITATOR_DETAILS_ROUTES.Notifications.resolve(eventSlug),
      },
    ],
  };

  return (
    <div className="jam-sidebar">
      <SideNavigation
        activeHref={activeNav}
        header={{
          href: RoutePath.MY_JAMS,
          text: event ? (event.title.length > 40 ? event.title.slice(0, 40) + '...' : event.title) : 'AWS Jam',
        }}
        items={[
          ...(event?.gamified
            ? [
              {
                type: 'link',
                text: t(i18nKeys.jamDashboard.leaderboard.title),
                href: JAM_EVENT_DETAILS_ROUTES.LeaderBoard.resolve(eventSlug),
              } as SideNavigationProps.Item,
            ]
            : []),
          {
            type: isAnyChallengeUnlocked ? 'expandable-link-group' : 'link-group',
            text: t(i18nKeys.challenges.title),
            items: isAnyChallengeUnlocked ? AllJamChallenges : [],
            href: JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventSlug),
            defaultExpanded: true,
          },
          ...(!event?.isSingleParticipantEvent && !event?.isSplEvent
            ? [
              {
                type: 'link',
                text: t(i18nKeys.myJams.team.title),
                href: JAM_EVENT_DETAILS_ROUTES.Team.resolve(eventSlug),
              } as SideNavigationProps.Item,
            ]
            : []),
          ...(!event?.facilitator
            ? [
              {
                type: 'link',
                text: t(i18nKeys.myJams.feedback.title),
                href: JAM_EVENT_DETAILS_ROUTES.Feedback.resolve(eventSlug),
              } as SideNavigationProps.Item,
            ]
            : []),
          {
            type: 'link',
            text: `${t(i18nKeys.myJams.messages.title)} ${unreadMessageCounts ? `(${unreadMessageCounts})` : ''}`,
            href: JAM_EVENT_DETAILS_ROUTES.Messages.resolve(eventSlug),
          },
          ...(event?.supportUser || event?.facilitator ? [FacilitatorMenu] : []),
        ]}
        onFollow={onFollow}
      />
      <div className="spacer" />
    </div>
  );
};
