/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { GAME_API_PREFIX } from '../constants/api';
import { UserDashboardData, UserDashboardEventResponse } from '../types/JamDashboard';
import { ApiClient } from './ApiClient';

export class JamDashboardAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * get user dashboard data
   *
   */
  public async fetchUserDashboardData(): Promise<UserDashboardData> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/dashboard`,
      responseMapper: UserDashboardData.fromPlainObject,
    })) as Promise<UserDashboardData>;
  }

  public async fetchActiveEvents(): Promise<UserDashboardEventResponse> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/events/ongoing`,
    })) as Promise<UserDashboardEventResponse>;
  }
  public async fetchUpcomingEvents(): Promise<UserDashboardEventResponse> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/events/upcoming`,
    })) as Promise<UserDashboardEventResponse>;
  }
  public async fetchPastEvents(): Promise<UserDashboardEventResponse> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/events/past`,
    })) as Promise<UserDashboardEventResponse>;
  }
}
