import React, { useEffect } from 'react';
import { Badge, Box, Button, ColumnLayout, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import ClueDetailsModal from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamTask/Modals/ClueDetailsModal';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';

interface ClueContainerProps {
  title: string;
  description?: string;
  penalty: number;
  btnText: string;
  order: number;
  used?: boolean;
  isFacilitator: boolean;
}

const ClueContainer: React.FC<ClueContainerProps> = ({
  title,
  penalty,
  btnText,
  order,
  description,
  isFacilitator,
  used = false,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const [showClue, setShowClue] = React.useState(used);
  const [showClueContent, setShowClueContent] = React.useState(false);

  useEffect(() => {
    setShowClue(used);
  }, [used]);

  return (
    <React.Fragment>
      <ColumnLayout columns={2}>
        <Box variant="div">
          <Box variant="h3" margin={{ bottom: 'xs' }}>
            {title}
          </Box>
          <Badge color="blue">
            {t(i18nKeys.myJams.challenges.details.task.clue.penaltyPoints, { penaltyPoints: penalty })}
          </Badge>
        </Box>
        {showClue ? (
          <Box variant="div" textAlign="center" float="right">
            {showClueContent ? (
              <Button onClick={() => setShowClueContent(!showClueContent)}>
                <Box variant="span" color="text-status-info">
                  {t(i18nKeys.myJams.challenges.details.task.clue.hideClue)}
                </Box>
              </Button>
            ) : (
              <Button onClick={() => setShowClueContent(!showClueContent)}>
                <Box variant="span" color="text-status-info">
                  {t(i18nKeys.myJams.challenges.details.task.clue.showClue)}
                </Box>
              </Button>
            )}
          </Box>
        ) : (
          <Box variant="div" float="right">
            <SpaceBetween direction="horizontal" size="s">
              <Button onClick={() => setVisible(!visible)}>
                <Icon name={'lock-private'} />
                <Box variant="span" color="text-status-info" padding={{ left: 'xs' }}>
                  {btnText}
                </Box>
              </Button>
              {isFacilitator && (
                <Button onClick={() => setShowClueContent((prev) => !prev)}>{showClueContent ? 'Hide' : 'Show'}</Button>
              )}
            </SpaceBetween>
          </Box>
        )}
        <ClueDetailsModal
          value={visible}
          setValue={setVisible}
          setShowClue={setShowClue}
          order={order}
          penalty={penalty}
        />
      </ColumnLayout>
      {showClueContent && <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description ?? ''}</ReactMarkdown>}
    </React.Fragment>
  );
};

export default ClueContainer;
