export class Region {
  group: string;
  localeName: string;

  constructor(
    public id: string,
    public name: string
  ) {
    this.group = name.split(' (')[0];
    this.localeName = name.split(' (')[1].replace(')', '');
  }
}

const US_EAST_1      : Region = new Region('us-east-1', 'US East (N. Virginia)');
const US_EAST_2      : Region = new Region('us-east-2', 'US East (Ohio)');
const US_WEST_1      : Region = new Region('us-west-1', 'US West (N. California)');
const US_WEST_2      : Region = new Region('us-west-2', 'US West (Oregon)');
const AP_SOUTH_1     : Region = new Region('ap-south-1', 'Asia Pacific (Mumbai)');
const AP_NORTHEAST_1 : Region = new Region('ap-northeast-1', 'Asia Pacific (Tokyo)');
const AP_NORTHEAST_2 : Region = new Region('ap-northeast-2', 'Asia Pacific (Seoul)');
const AP_NORTHEAST_3 : Region = new Region('ap-northeast-3', 'Asia Pacific (Osaka-Local)');
const AP_SOUTHEAST_1 : Region = new Region('ap-southeast-1', 'Asia Pacific (Singapore)');
const AP_SOUTHEAST_2 : Region = new Region('ap-southeast-2', 'Asia Pacific (Sydney)');
const CA_CENTRAL_1   : Region = new Region('ca-central-1', 'Canada (Central)');
const EU_CENTRAL_1   : Region = new Region('eu-central-1', 'EU (Frankfurt)');
const EU_WEST_1      : Region = new Region('eu-west-1', 'EU (Ireland)');
const EU_WEST_2      : Region = new Region('eu-west-2', 'EU (London)');
const EU_WEST_3      : Region = new Region('eu-west-3', 'EU (Paris)');
const SA_EAST_1      : Region = new Region('sa-east-1', 'South America (São Paulo)');

const ALL_AWS_REGIONS: Region[] = [
  US_EAST_1,
  US_EAST_2,
  US_WEST_1,
  US_WEST_2,
  AP_SOUTH_1,
  AP_NORTHEAST_1,
  AP_NORTHEAST_2,
  AP_NORTHEAST_3,
  AP_SOUTHEAST_1,
  AP_SOUTHEAST_2,
  CA_CENTRAL_1,
  EU_CENTRAL_1,
  EU_WEST_1,
  EU_WEST_2,
  EU_WEST_3,
  SA_EAST_1,
];

const ALL_AWS_REGION_IDS: string[] = ALL_AWS_REGIONS.map(region => region.id);
const ALL_AWS_REGION_NAMES: string[] = ALL_AWS_REGIONS.map(region => region.name);

const ALL_AWS_REGIONS_ITEMS: { label: string, value: string }[] = ALL_AWS_REGIONS.map(region => ({
  value: region.id,
  label: `${region.name} - ${region.id}`
}));

export class RegionMap {
 [id: string]: Region
}

const ALL_AWS_REGIONS_BY_ID: RegionMap = ALL_AWS_REGIONS.reduce((map, region) => {
  return Object.assign(map, { [region.id]: region })
}, {});

// noinspection JSUnusedGlobalSymbols
export const AwsRegions = {
  US_EAST_1,
  US_EAST_2,
  US_WEST_1,
  US_WEST_2,
  AP_SOUTH_1,
  AP_NORTHEAST_1,
  AP_NORTHEAST_2,
  AP_NORTHEAST_3,
  AP_SOUTHEAST_1,
  AP_SOUTHEAST_2,
  CA_CENTRAL_1,
  EU_CENTRAL_1,
  EU_WEST_1,
  EU_WEST_2,
  EU_WEST_3,
  SA_EAST_1,
  ALL_AWS_REGIONS,
  ALL_AWS_REGION_IDS,
  ALL_AWS_REGION_NAMES,
  ALL_AWS_REGIONS_ITEMS,
  ALL_AWS_REGIONS_BY_ID,
};
