import { Container, Grid, Header, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import './Wiki.scss';
import { useState } from 'react';

interface ChallengeWikiDetailProps {
  challenge: Challenge;
}

const ChallengeWikiDetail: React.FC<ChallengeWikiDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, handleUpdateChallengeProp } = useCreateChallenge();
  const [activeTab, setActiveTab] = useState(1);
  const iconImg = (): JSX.Element => (
    <svg
      style={{ display: 'flex' }}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41421 8.00008L5.20711 5.20718L3.79289 3.79297L0.292893 7.29297C-0.0976311 7.68349 -0.0976311 8.31666 0.292893 8.70718L3.79289 12.2072L5.20711 10.793L2.41421 8.00008ZM5.54611 11.3179L8.60611 3.91787L10.4543 4.68213L7.39432 12.0821L5.54611 11.3179ZM15.7071 7.29297L12.2071 3.79297L10.7929 5.20718L13.5858 8.00008L10.7929 10.793L12.2071 12.2072L15.7071 8.70718C16.0976 8.31666 16.0976 7.68349 15.7071 7.29297Z"
        fill="#0972D3"
      />
    </svg>
  );

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.WIKI} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.wiki)}
        </Header>
      }>
      <TextContent>
        {!editMode && challenge.props.wiki && (
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.props.wiki}</ReactMarkdown>
        )}
        {!editMode && !challenge.props.wiki && t(i18nKeys.challenges.challengeDetails.field.wiki.emptyState)}
        {editMode && (
          <div className="challenge-wiki">
            <SpaceBetween size="s">
              <TextContent>
                Participants will never see this Wiki. It is only visible to event facilitators for the purpose of
                supporting the challenge. Please see the Wiki Example for an example of what to include in your Wiki.
                You may include anything that will help to support this challenge.
              </TextContent>
              <h3>Preferred format is markdown. Basic HTML is allowed.</h3>
              <div
                style={{
                  border: '1px solid var(--grey-200, #E9EBED)',
                  background: '#FFF',
                  borderTopLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                }}>
                <div
                  style={{
                    backgroundColor: '#E9EBED',
                    padding: '20px',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                  }}>
                  <SpaceBetween direction="horizontal" size="xs">
                    <button
                      id="challenge-wiki-markdown"
                      data-testid="challenge-wiki-markdown"
                      className={`custom-btn ${activeTab === 1 ? 'active' : ''}`}
                      role="button"
                      onClick={() => setActiveTab(1)}>
                      <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                        {iconImg()} Markdown
                      </SpaceBetween>
                    </button>
                    <button
                      id="challenge-wiki-markdown-preview"
                      data-testid="challenge-wiki-markdown-preview"
                      className={`custom-btn ${activeTab === 2 ? 'active' : ''}`}
                      role="button"
                      onClick={() => setActiveTab(2)}>
                      <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                        {iconImg()}
                        <img src="/assets/eye-blue-icon.png" alt="" />
                        Markdown / Preview
                      </SpaceBetween>
                    </button>
                    <button
                      id="challenge-wiki-preview"
                      data-testid="challenge-wiki-preview"
                      className={`custom-btn ${activeTab === 3 ? 'active' : ''}`}
                      role="button"
                      onClick={() => setActiveTab(3)}>
                      <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                        <img src="/assets/eye-blue-icon.png" alt="" />
                        Preview
                      </SpaceBetween>
                    </button>
                  </SpaceBetween>
                </div>
                <div style={{ padding: '2rem' }}>
                  {activeTab === 1 && (
                    <Grid gridDefinition={[{ colspan: 12 }]}>
                      <JamCodeEditor
                        language="markdown"
                        value={challenge.props.wiki || ''}
                        setValue={(e) => {
                          handleUpdateChallengeProp(ChallengePropAction.WIKI, e);
                        }}
                      />
                    </Grid>
                  )}
                  {activeTab === 2 && (
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <JamCodeEditor
                        language="markdown"
                        value={challenge.props.wiki || ''}
                        setValue={(e) => handleUpdateChallengeProp(ChallengePropAction.WIKI, e)}
                      />
                      <TextContent>
                        {challenge.props.wiki && (
                          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.props.wiki}</ReactMarkdown>
                        )}
                        {!challenge.props.wiki && t(i18nKeys.challenges.challengeDetails.field.wiki.emptyState)}
                      </TextContent>
                    </Grid>
                  )}
                  {activeTab === 3 && (
                    <Grid gridDefinition={[{ colspan: 12 }]}>
                      <TextContent>
                        {challenge.props.wiki && (
                          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.props.wiki}</ReactMarkdown>
                        )}
                        {!challenge.props.wiki && t(i18nKeys.challenges.challengeDetails.field.wiki.emptyState)}
                      </TextContent>
                    </Grid>
                  )}
                </div>
              </div>
            </SpaceBetween>
          </div>
        )}
      </TextContent>
    </Container>
  );
};

export default ChallengeWikiDetail;
