import React, { useEffect, useMemo } from 'react';
import {
  Box, Button,
  Container,
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  Icon,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './JamMessages.scss';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useApi } from '@/src/store/api.context';

const JamMessages = () => {
  const { t } = useTranslation();
  const { event, eventName, loadEventMessages } = useJamEventDetails();
  const { jamMessageApi } = useApi();
  const messages = useMemo(() => {
    if (!event?.messages) {
      return [];
    }
    return event.messages.slice().reverse();
  }, [event?.messages]);

  useEffect(() => {
    void loadEventMessages();
  }, []);

  const markAllAsRead = async (silent = false) : Promise<void> => {
    await jamMessageApi.markAllMessagesAsReadForParticipant({ eventName, silent });
  }

  return (
    <div className="jam-messages">
      <ContentLayout
        header={<Header actions = {<Button disabled={messages.length === 0} variant="primary" onClick={() => void markAllAsRead()}
        > {t(i18nKeys.jamMessages.actions.markAllRead)} </Button>}>{t(i18nKeys.jamMessages.title)}</Header>}>
        {messages.length === 0 && <Container>{t(i18nKeys.jamMessages.errors.empty)}</Container>}
        <Grid gridDefinition={Array(messages.length).fill({ colspan: 12 })}>
          {messages.map(({ notificationMessage, id, received, read }, index) => (
            <ExpandableSection
              key={`${id}-${index}`}
              defaultExpanded={index === 0}
              variant="container"
              headerText={notificationMessage.title}
              headerActions={
                <SpaceBetween direction="horizontal" size="xxl">
                  {['warning', 'warning_with_email'].includes(notificationMessage.type) && (
                    <div className="warning-label">
                      <Icon name="status-warning" /> {t(i18nKeys.jamMessages.labels.warning)}
                    </div>
                  )}
                  {!read &&
                    <div className="unread-label">
                      <Icon name="envelope" /> unread
                    </div>}
                  <Box>{moment(received).format('M/DD/YYYY, h:mm A')}</Box>
                </SpaceBetween>
              }>
              <Grid gridDefinition={[{ colspan: 10 }]}>{notificationMessage.message}</Grid>
            </ExpandableSection>
          ))}
        </Grid>
      </ContentLayout>
    </div>
  );
};

export default JamMessages;
