import React from 'react';
import {
  Box,
  ColumnLayout,
  ExpandableSection,
  Header,
  Link as ReactRouterLink,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { DifficultyIndicatorText } from '@/src/components/challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { LoadingBar } from '@/src/components/common/LoadingBar';
import { IEventTemplateChallenge } from '@/src/types/EventTemplate';
import { Markdown } from '@/src/components/common/Markdown';
import { useUser } from '@/src/store/user.context';

export interface IIncludedChallengesProps {
  selectedChallenges: IEventTemplateChallenge[];
}

const IncludedChallenges: React.FC<IIncludedChallengesProps> = ({ selectedChallenges }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const renderContent = (item: IEventTemplateChallenge) => (
    <>
      <ColumnLayout columns={2}>
        <div>
          <span>
            <StatusIndicator />
            {t(i18nKeys.eventTemplates.catalogDetails.includedChallenges.difficulty)}:
          </span>
          <div>
            <DifficultyIndicatorText difficulty={item.data?.difficulty ?? 0} hideBlock={false} />
          </div>
        </div>
        <div>
          <Box>{t(i18nKeys.eventTemplates.catalogDetails.includedChallenges.topic)}:</Box>
          <Box>{item?.data?.category}</Box>
        </div>
      </ColumnLayout>
      <Box margin={{ vertical: 'l' }}>
        <Markdown content={item?.data?.description as string} />
      </Box>
      {user && !user?.isOnlyBasicUser && (
        <ReactRouterLink target="_blank" href={CHALLENGE_DETAILS_ROUTES.Summary.resolve(item.id ?? '')}>
          {t(i18nKeys.eventTemplates.catalogDetails.includedChallenges.fullChallengeDetails)}
        </ReactRouterLink>
      )}
    </>
  );

  return (
    <>
      <Box variant="h1" margin={{ bottom: 'xs' }}>
        {t(i18nKeys.eventTemplates.catalogDetails.includedChallenges.title)}
      </Box>
      <SpaceBetween size="l" direction="vertical">
        {selectedChallenges.length > 0 ? (
          selectedChallenges.map((item, i) => (
            <ExpandableSection key={i} variant="container" header={<Header variant="h3">{item?.data?.title}</Header>}>
              {renderContent(item)}
            </ExpandableSection>
          ))
        ) : (
          <LoadingBar />
        )}
      </SpaceBetween>
    </>
  );
};

export default IncludedChallenges;
